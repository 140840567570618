// 
// authentication.scss
//
#userOnboardingModal.fade.in {
    left: 0;
}
#userOnboardingModal.fade {
    left: -58%;
}
#userOnboardingModal {
    min-width: 800px !important;
    width: 58% !important;
    margin-right: 375px !important;
    transition: all 0.5s !important;
    border: none;
}
@media (min-width: 45em){

.modal.fade.in {
    top: 45%;
}

.modal.fade.in {
    top: 45%;
}
.side-modal.in {
    right: 0;
    left: inherit;
}
.side-modal.in {
    right: 0;
    left: inherit;
}
.modal.fade {
    -moz-transition: opacity 0.1s ease-out, top 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out, top 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out, top 0.1s ease-out;
    transition: opacity 0.1s ease-out, top 0.1s ease-out;
    top: 41%;
}
}
.fade.in {
    opacity: 100;
    filter: alpha(opacity=100);
}
.fade.in {
    opacity: 1;
}
.side-modal {
    position: fixed;
    top: 0 !important;
    right: -600px;
    left: inherit;
    background: #fff;
    height: 100%;
    width: 600px !important;
    z-index: 99999;
    // box-shadow: -4px 0 16px 0 rgb(0 0 0 / 9%);
    border-top: solid 2px #141C30;
    transition: all 0.3s !important;
    margin: 0 !important;
    border-radius: 0;
}
.modal {
    background-image: none !important;
}
.side-modal {
    position: fixed;
    top: 0 !important;
    right: -600px;
    left: inherit;
    background: #fff;
    height: 100%;
    width: 600px !important;
    z-index: 99999;
    // box-shadow: -4px 0 16px 0 rgb(0 0 0 / 9%);
    border-top: solid 2px #193346;
    transition: all 0.3s !important;
    margin: 0 !important;
    border-radius: 0;
}
@media (min-width: 45em){
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 710px;
    margin: -250px 0 0 -355px;
}
}
.modal {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 15px !important;
    width: auto;
    margin: 0;
    z-index: 1050;
    background-color: #fff;
    background-repeat: repeat-x;
    border-radius: 4px;
    -moz-box-shadow: 0 2px 18px 0 rgba(18,52,77,0.2);
    // -webkit-box-shadow: 0 2px 18px 0 rgb(18 52 77 / 20%);
    // box-shadow: 0 2px 18px 0 rgb(18 52 77 / 20%);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    margin-top: 0px !important;
    border: none;
}
.fade {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.fade {
    -moz-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    opacity: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}
//user agent stylesheet
div {
    display: block;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    margin: 0 auto;
    padding: 0;
    font-weight: normal;
    line-height: 20px;
}
body {
    min-width: 1140px;
    -webkit-font-smoothing: antialiased;
}
body {
    background-color: #ebeff3;
    color: #12344D;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #12344D;
}
body {
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    background-color: #F9F9F9;
}
body {
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    background-color: #F9F9F9;
}
body {
    line-height: 1;
    color: black;
    background: white;
}