.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-auto {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%
  }

  .col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    width: auto
  }

  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-3 {
    -ms-flex-order: 3;
    order: 3
  }

  @media (min-width:576px) {
    .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }

    .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }

    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  }

  @media (min-width:768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      -ms-flex-positive: 1;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }

    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      max-width: 100%;
      width: auto
    }

    .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }

    .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }

    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }

    .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }

    .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }

    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }

    .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }

    .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }

    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }

    .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }

    .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }

    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }

    .order-md-0 {
      -ms-flex-order: 0;
      order: 0
    }

    .order-md-1 {
      -ms-flex-order: 1;
      order: 1
    }

    .order-md-2 {
      -ms-flex-order: 2;
      order: 2
    }

    .order-md-3 {
      -ms-flex-order: 3;
      order: 3
    }

    .offset-md-1 {
      margin-left: 8.333333%
    }
  }

  @media (min-width:1024px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      -ms-flex-positive: 1;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }

    .col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }

    .col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }

    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }

    .col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }

    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }

    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }

    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }

    .col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }

    .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }

    .col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }

    .col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }

    .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }

    .order-lg-1 {
      -ms-flex-order: 1;
      order: 1
    }

    .order-lg-2 {
      -ms-flex-order: 2;
      order: 2
    }

    .offset-lg-0 {
      margin-left: 0
    }

    .offset-lg-1 {
      margin-left: 8.333333%
    }
  }

  @media (min-width:1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      -ms-flex-positive: 1;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }

    .col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }

    .col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }

    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }

    .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }

    .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }

    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }

    .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }

    .col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }

    .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }

    .col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }

    .col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }

    .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  }

  .d-none {
    display: none !important
  }

  .d-inline {
    display: inline !important
  }

  .d-inline-block {
    display: inline-block !important
  }

  .d-block {
    display: block !important
  }

  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  @media (min-width:576px) {
    .d-sm-flex {
      display: -ms-flexbox !important;
      display: flex !important
    }
  }

  @media (min-width:768px) {
    .d-md-none {
      display: none !important
    }

    .d-md-block {
      display: block !important
    }

    .d-md-flex {
      display: -ms-flexbox !important;
      display: flex !important
    }
  }

  @media (min-width:1024px) {
    .d-lg-none {
      display: none !important
    }

    .d-lg-inline-block {
      display: inline-block !important
    }

    .d-lg-flex {
      display: -ms-flexbox !important;
      display: flex !important
    }
  }

  @media (min-width:1200px) {
    .d-xl-none {
      display: none !important
    }
  }

  .flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  @media (min-width:768px) {
    .flex-md-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
    }

    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
    }

    .justify-content-md-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
    }
  }

  @media (min-width:1024px) {
    .flex-lg-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
    }

    .justify-content-lg-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important
    }
  }

  @media (min-width:1200px) {
    .flex-xl-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important
    }
  }

  .m-0 {
    margin: 0 !important
  }

  .mb-0 {
    margin-bottom: 0 !important
  }

  .mt-0 {
    margin-top: 0 !important
  }

  .mr-0 {
    margin-right: 0 !important
  }

  .ml-0 {
    margin-left: 0 !important
  }

  .mt-1 {
    margin-top: .25rem !important
  }

  .mb-1 {
    margin-bottom: .25rem !important
  }

  .mr-1 {
    margin-right: .25rem !important
  }

  .ml-1 {
    margin-left: .25rem !important
  }

  .mt-2 {
    margin-top: .5rem !important
  }

  .mb-2 {
    margin-bottom: .5rem !important
  }

  .mr-2 {
    margin-right: .5rem !important
  }

  .ml-2 {
    margin-left: .5rem !important
  }

  .mt-3 {
    margin-top: 1rem !important
  }

  .mr-3 {
    margin-right: 1rem !important
  }

  .mb-3 {
    margin-bottom: 1rem !important
  }

  .ml-3 {
    margin-left: 1rem !important
  }

  .mt-4 {
    margin-top: 1.5rem !important
  }

  .mr-4 {
    margin-right: 1.5rem !important
  }

  .ml-4 {
    margin-left: 1.5rem !important
  }

  .mx-4 {
    margin-right: 1.5rem !important
  }

  .mb-4 {
    margin-bottom: 1.5rem !important
  }

  .mt-5 {
    margin-top: 3rem !important
  }

  .mb-5 {
    margin-bottom: 3rem !important
  }

  .mr-5 {
    margin-right: 3rem !important
  }

  .ml-5 {
    margin-left: 3rem !important
  }

  .p-0 {
    padding: 0 !important
  }

  .mt-auto {
    margin-top: auto !important
  }

  @media (min-width:768px) {
    .pr-md-0 {
      padding-right: 0 !important
    }
  }

  .img-fluid {
    height: auto;
    max-width: 100%
  }

  .text-left {
    text-align: left !important
  }

  .text-right {
    text-align: right !important
  }

  .text-center {
    text-align: center !important
  }

  .h-100 {
    height: 100% !important
  }

  .h-auto {
    height: auto !important
  }

  .img-thumbnail {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    height: auto;
    max-width: 100%;
    padding: .25rem
  }

  .m-auto {
    margin: auto !important
  }

  .mt-auto,
  .my-auto {
    margin-top: auto !important
  }

  .mr-auto,
  .mx-auto {
    margin-right: auto !important
  }

  .mb-auto,
  .my-auto {
    margin-bottom: auto !important
  }

  .ml-auto,
  .mx-auto {
    margin-left: auto !important
  }

  .sr-only {
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }

  .form-group {
    margin-bottom: 1rem
  }

  label {
    display: inline-block;
    margin-bottom: .5rem
  }

  .form-control {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
  }

  @media (min-width:576px) {
    .text-sm-left {
      text-align: left !important
    }

    .text-sm-right {
      text-align: right !important
    }

    .text-sm-center {
      text-align: center !important
    }
  }

  @media (min-width:768px) {
    .text-md-left {
      text-align: left !important
    }

    .text-md-right {
      text-align: right !important
    }

    .text-md-center {
      text-align: center !important
    }
  }

  @media (min-width:992px) {
    .text-lg-left {
      text-align: left !important
    }

    .text-lg-right {
      text-align: right !important
    }

    .text-lg-center {
      text-align: center !important
    }
  }

  @media (min-width:1200px) {
    .text-xl-left {
      text-align: left !important
    }

    .text-xl-right {
      text-align: right !important
    }

    .text-xl-center {
      text-align: center !important
    }
  }

  @media (max-width:767px) {
    .hidden-xs {
      display: none !important
    }
  }

  @media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
      display: none !important
    }
  }

  @media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
      display: none !important
    }
  }

  @media (min-width:1200px) {
    .hidden-lg {
      display: none !important
    }
  }

  .container {
    margin: 0 auto;
    padding: 0 8px;
    width: auto
  }

  @media only screen and (min-width:768px) {
    .container {
      max-width: 1001px;
      padding: 0 16px;
      width: auto
    }
  }

  @media only screen and (min-width:1024px) {
    .container {
      max-width: 1224px;
      padding: 0 27px
    }
  }

  @media only screen and (min-width:1280px) {
    .container {
      max-width: 1280px;
      padding: 0 55px
    }
  }

  @media only screen and (min-width:1440px) {
    .container {
      max-width: 1638px;
      padding: 0 135px
    }
  }

  @media only screen and (min-width:1600px) {
    .container {
      max-width: 1600px;
      padding: 0 116px
    }
  }

  @media only screen and (min-width:1920px) {
    .container {
      max-width: 1368px;
      padding: 0
    }
  }

  @media only screen and (max-width:767px) {
    .row {
      margin-left: -8px;
      margin-right: -8px
    }

    .col,
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-auto {
      padding-left: 8px;
      padding-right: 8px
    }
  }

  *,
  :after,
  :before {
    box-sizing: border-box
  }

  body {
    text-rendering: optimizeSpeed;
    margin: 0;
    min-height: 100vh;
    padding: 0
  }

  strong {
    font-weight: 700
  }

  a {
    text-decoration-skip-ink: auto
  }

  button,
  input,
  select,
  textarea {
    font: inherit
  }

  body {
    color: #333;
    display: flex;
    flex-direction: column;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    position: relative
  }

  body.cyrillic-font {
    font-family: Open Sans Cyrillic, sans-serif
  }

  body.bg-img {
    background-repeat: no-repeat;
    background-size: 100% auto
  }

  @media (max-width:767px) {
    body.bg-img {
      background-image: none !important
    }
  }

  body.content-only .page-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0 !important
  }

  body.content-only .page-share {
    display: none !important
  }

  .page-main {
    color: #3b3c3d;
    flex: 1;
    padding-top: 92px
  }

  .two-levels-menu .page-main {
    padding-top: 122px
  }

  .microsite .page-main {
    padding-top: 92px
  }

  .microsite.two-levels-menu .page-main {
    padding-top: 122px
  }

  @media (max-width:1599px) {

    .microsite .page-main,
    .microsite.two-levels-menu .page-main,
    .page-main,
    .two-levels-menu .page-main {
      padding-top: 84px
    }
  }

  @media (max-width:1279px) {

    .page-main,
    .two-levels-menu .page-main {
      padding-top: 60px
    }

    .microsite .page-main,
    .microsite.two-levels-menu .page-main {
      padding-top: 84px
    }
  }

  @media (max-width:767px) {

    .page-main,
    .two-levels-menu .page-main {
      padding-top: 56px
    }

    .microsite .page-main,
    .microsite.two-levels-menu .page-main {
      padding-top: 80px
    }

    .page-main {
      overflow-x: hidden
    }
  }

  .page-main .container .container {
    padding: 0
  }

  @media (max-width:767px) {
    .container {
      padding: 0 16px
    }
  }

  textarea {
    resize: none
  }

  img[data-object-fit=contain] {
    object-fit: contain
  }

  img[data-object-fit=cover] {
    object-fit: cover
  }

  picture {
    display: inline-block;
    font-size: 0;
    letter-spacing: 1px
  }

  p {
    font-size: 18px;
    line-height: 1.78;
    margin-bottom: 18px;
    margin-top: 0
  }

  @media (max-width:767px) {
    p {
      font-size: 16px;
      margin-bottom: 12px
    }
  }

  ul:not([class]) {
    list-style-type: none !important;
    margin: 12px 0;
    padding: 0
  }

  ul:not([class]) li {
    font-size: 18px;
    line-height: 1.78;
    margin-bottom: 10px;
    padding-left: 18px;
    position: relative
  }

  @media (max-width:767px) {
    ul:not([class]) li {
      font-size: 16px
    }
  }

  ul:not([class]) li:before {
    background-color: #ED2600;
    content: "";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 13px;
    width: 8px
  }

  @media (max-width:767px) {
    ul:not([class]) li:before {
      top: 10px
    }
  }

  ol:not([class]) {
    margin: 12px 0 12px 18px;
    padding: 0
  }

  ol:not([class]) li {
    font-size: 18px;
    line-height: 1.78;
    margin-bottom: 10px;
    margin-left: 18px
  }

  @media (max-width:767px) {
    ol:not([class]) li {
      font-size: 16px
    }
  }

  a {
    color: inherit;
    text-decoration: none
  }

  a:not([class]) {
    text-decoration: underline;
    text-decoration-color: #005eb8;
    text-underline-offset: 2px;
    transition: color .12s ease-in-out
  }

  a:not([class]):hover {
    color: #005eb8
  }

  a:not([class])[data-fancybox],
  a:not([class])[rel=data-fancybox],
  a[name] {
    text-decoration: none;
    text-decoration-color: currentColor;
    text-underline-offset: auto
  }

  a:not([class])[data-fancybox]:hover,
  a:not([class])[rel=data-fancybox]:hover,
  a[name]:hover {
    color: inherit
  }

  h1 {
    color: #004485;
    font-family: Sumana, serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.13;
    margin: 20px 0 30px
  }

  @media (max-width:1279px) {
    h1 {
      font-size: 40px
    }
  }

  @media (max-width:767px) {
    h1 {
      font-size: 32px;
      line-height: 1.25
    }
  }

  h1 strong {
    font-weight: inherit !important
  }

  h2 {
    color: #004485;
    font-family: Sumana, serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.28;
    margin: 128px 0 32px;
    position: relative;
    z-index: 1
  }

  h2:before {
    background-color: #000;
    content: "";
    display: block;
    height: 90px;
    left: -24px;
    position: absolute;
    top: -32px;
    width: 90px;
    z-index: -1
  }

  @media (max-width:1023px) {
    h2:before {
      left: -16px;
      width: 82px
    }
  }

  @media (max-width:767px) {
    h2:before {
      height: 72px;
      width: 64px
    }

    h2 {
      font-size: 24px
    }
  }

  h2 strong {
    font-weight: inherit !important
  }

  h2 :first-child:first-letter,
  h2:first-letter {
    text-transform: capitalize
  }

  h3 {
    color: #222;
    font-size: 28px;
    line-height: normal;
    margin: 30px 0 20px
  }

  @media (max-width:1023px) {
    h3 {
      font-size: 24px
    }
  }

  @media (max-width:767px) {
    h3 {
      font-size: 22px;
      margin: 20px 0
    }
  }

  h4 {
    color: #222;
    font-size: 24px;
    margin: 20px 0
  }

  @media (max-width:1023px) {
    h4 {
      font-size: 22px
    }
  }

  @media (max-width:767px) {
    h4 {
      font-size: 20px
    }
  }

  h5 {
    font-size: 20px;
    margin: 20px 0
  }

  @media (max-width:767px) {
    h5 {
      font-size: 18px
    }
  }

  h6 {
    font-size: 18px;
    margin: 20px 0
  }

  mark {
    background: transparent
  }

  summary::marker {
    content: none
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: normal;
    font-weight: 300;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap')
  }
  

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: normal;
    font-weight: 600;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: normal;
    font-weight: 800;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: italic;
    font-weight: 300;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: italic;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: italic;
    font-weight: 600;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,600&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: italic;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,700&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans Cyrillic;
    font-style: italic;
    font-weight: 800;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: IBM Plex Sans Arabic;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400')
  }

  @font-face {
    font-display: swap;
    font-family: IBM Plex Sans Arabic;
    font-style: normal;
    font-weight: 600;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@600')
  }

  @font-face {
    font-display: swap;
    font-family: IBM Plex Sans Arabic;
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@700')
  }

  @font-face {
    font-display: swap;
    font-family: Sumana;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;600;700&family=Sumana:wght@400')
  }

  @font-face {
    font-display: swap;
    font-family: Sumana;
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;600;700&family=Sumana:wght@700')
  }

  @font-face {
    font-display: swap;
    font-family: Readex Pro;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400')
  }

  @font-face {
    font-display: swap;
    font-family: Readex Pro;
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@700')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: italic;
    font-weight: 300;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: italic;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: italic;
    font-weight: 600;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,600&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: italic;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,700&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: Open Sans;
    font-style: italic;
    font-weight: 800;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap')
  }

  @font-face {
    font-display: swap;
    font-family: s-icons;
    font-style: normal;
    font-weight: 400;
    src: url('../../../fonts/app/s-icons.eot');
    src: url('../../../fonts/app/s-icons.eot') format("embedded-opentype"), url('../../../fonts/app/s-icons.woff') format("woff"), url('../../../fonts/app/s-icons.ttf') format("truetype"), url('../../../images/app/s-icons.svg') format("svg")
  }

  [class*=" icon-s-"],
  [class^=icon-s-] {
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: s-icons !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
  }

  .icon-s-info:before {
    content: "\e900"
  }

  .icon-s-arrow:before {
    content: "\e901"
  }

  .icon-s-arrow-left:before {
    content: "\e922"
  }

  .icon-s-close:before {
    content: "\e902"
  }

  .icon-s-envelope:before {
    content: "\e903"
  }

  .icon-s-facebook:before {
    content: "\e904"
  }

  .icon-s-left-quote:before {
    content: "\e905"
  }

  .icon-s-left-quote-rtl:before {
    content: "\e923"
  }

  .icon-s-arrow-down:before {
    content: "\e906"
  }

  .icon-s-linkedin:before {
    content: "\e907"
  }

  .icon-s-map-marker:before {
    content: "\e908"
  }

  .icon-s-phone:before {
    content: "\e909"
  }

  .icon-s-right-quote:before {
    content: "\e90a"
  }

  .icon-s-right-quote-rtl:before {
    content: "\e924"
  }

  .icon-s-scroll-icon:before {
    content: "\e90b"
  }

  .icon-s-search:before {
    content: "\e90c"
  }

  .icon-s-slider-arrow:before {
    content: "\e90d"
  }

  .icon-s-slider-arrow-left:before {
    content: "\e921"
  }

  .icon-s-twitter:before {
    content: "\e90e"
  }

  .icon-s-pinterest:before {
    content: "\e90f"
  }

  .icon-s-reddit:before {
    content: "\e910"
  }

  .icon-s-clocks:before {
    content: "\e911"
  }

  .icon-s-link:before {
    content: "\e912"
  }

  .icon-s-file-download:before {
    content: "\e913"
  }

  .icon-s-upload:before {
    content: "\e914"
  }

  .icon-s-error:before {
    content: "\e915"
  }

  .icon-s-attachement:before {
    content: "\e916"
  }

  .icon-s-expand-icon:before {
    content: "\e917"
  }

  .icon-s-arrow-diagonal:before {
    content: "\e918"
  }

  .icon-s-dribble:before {
    content: "\e919"
  }

  .icon-s-bookmark:before {
    content: "\e91a"
  }

  .icon-s-double-chevron:before {
    content: "\e9d"
  }

  .icon-s-expand-more:before {
    content: "\e91b"
  }

  .icon-s-mail:before {
    content: "\e91d"
  }

  .icon-s-chat:before {
    content: "\e91c"
  }

  .icon-s-view-hide:before {
    content: "\e91e"
  }

  .icon-s-star:before {
    content: "\e91f"
  }

  .icon-s-ic-expand:before {
    content: "\e920"
  }

  .icon-s-phone-bordered:before {
    content: "\e925"
  }

  .icon-s-arrow-right-long:before {
    content: "\e926"
  }

  .icon-s-checkmark:before {
    content: "\ea10"
  }

  .blur {
    opacity: .3;
    pointer-events: none
  }

  .text-highlighted {
    color: #004688 !important
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu {
    margin: 0 0 40px
  }

  @media only screen and (max-width:767px) {
    .p-simple__content .spectrecreation_area_page-navigation-menu {
      margin-top: 20px
    }
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table {
    border-spacing: 2px
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table td {
    background-color: #f2f9fe;
    border-bottom: 4px solid #f2f9fe;
    color: #005eb8;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    width: 20%
  }

  @media only screen and (max-width:767px) {
    .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table td {
      display: block;
      margin-bottom: 9px;
      width: 100%
    }
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table td:hover {
    border-bottom: 4px solid #025eb8
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table td a {
    text-decoration: none
  }

  .p-simple__content .spectrecreation_area_page-navigation-menu .c-table-overflow table td a:hover {
    color: #005eb8
  }

  .c-table-overflow {
    overflow-x: auto
  }

  .hp-field {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -1
  }

  img.responsive,
  object.responsive {
    height: auto;
    width: 100%
  }

  .a-slider__image [data-group],
  [data-fancybox],
  [rel=data-fancybox] {
    cursor: zoom-in
  }

  .o-none {
    opacity: 0
  }

  .__waiting:after {
    background: #fff;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0s
  }

  .__waiting.__waiting--load:after {
    opacity: .6;
    transition: opacity .2s
  }

  .tooltip {
    display: none
  }

  .dt-h1 {
    color: #222;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
    text-transform: uppercase
  }

  @media only screen and (max-width:768px) {
    .dt-h1 {
      font-size: 28px;
      line-height: 1.43
    }
  }

  .dt-h2 {
    color: #222;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.41;
    text-transform: capitalize
  }

  .dt-h3 {
    font-size: 28px;
    margin-bottom: 17px;
    margin-top: 0
  }

  @media only screen and (max-width:767px) {
    .dt-h3 {
      font-size: 24px
    }
  }

  .dt-h4 {
    color: #222;
    font-size: 22px;
    font-weight: 700
  }

  @media only screen and (min-width:1023px) {
    .dt-h4 {
      line-height: 1.27
    }
  }

  @media only screen and (min-width:1281px) {
    .dt-h4 {
      font-size: 24px;
      line-height: 1.17
    }
  }

  @media only screen and (max-width:768px) {
    .dt-h4 {
      line-height: normal
    }
  }

  .dt-h5 {
    color: #222;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize
  }

  @media only screen and (min-width:1024px) {
    .dt-h5 {
      font-size: 18px
    }
  }

  @media only screen and (max-width:767px) {
    .dt-h5 {
      font-size: 18px
    }
  }

  .dt-h6 {
    color: #222;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.78;
    text-transform: uppercase
  }

  .dt-h6:after {
    background-color: #ed8b00;
    content: "";
    display: block;
    height: 2px;
    margin: 6px 0 0;
    width: 40px
  }

  .dt-h7 {
    color: #222;
    font-size: 14px;
    font-weight: 600;
    line-height: 2.29;
    text-transform: uppercase
  }

  .dt-quote {
    color: #222;
    font-size: 18px;
    font-style: italic;
    line-height: 1.94
  }

  @media only screen and (max-width:1023px) {
    .dt-quote {
      font-size: 16px
    }
  }

  @media only screen and (max-width:767px) {
    .dt-quote {
      font-size: 14px;
      line-height: 1.75
    }
  }

  .dt-link {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase
  }

  .dt-link,
  .dt-link:active,
  .dt-link:visited {
    color: #005eb8
  }

  .dt-link i {
    font-size: 12px;
    margin-left: 10px
  }

  .dt-bullet {
    flex: 1 100%;
    margin: 0 15px 20px 23px
  }

  .dt-bullet:before {
    background-color: #005eb8;
    content: " ";
    display: block;
    height: 8px;
    margin-left: -23px;
    transform: translateY(14px);
    width: 8px
  }

  @media only screen and (min-width:1024px) {
    .dt-bullet {
      font-size: 18px;
      font-weight: 600;
      max-width: calc(50% - 38px)
    }
  }

  @media only screen and (max-width:1023px) {
    .dt-bullet {
      font-size: 16px;
      font-weight: 400
    }
  }

  @media only screen and (max-width:767px) {
    .dt-bullet {
      font-size: 14px;
      font-weight: 600
    }
  }

  .page-main .btn-white p,
  .page-main .btn p {
    font-size: inherit;
    margin-bottom: inherit
  }

  .page-main .a-wysiwyg blockquote {
    margin: 45px 0 12px;
    padding: 95px 0 0;
    position: relative
  }

  .page-main .a-wysiwyg blockquote p {
    font-size: 18px;
    line-height: 1.78
  }

  @media only screen and (min-width:768px) {
    .page-main .a-wysiwyg blockquote {
      padding: 27px 0 0 100px
    }

    .page-main .a-wysiwyg blockquote p {
      font-size: 22px;
      line-height: 1.46
    }
  }

  @media only screen and (min-width:1024px) {
    .page-main .a-wysiwyg blockquote {
      padding: 30px 5px 0 100px
    }
  }

  @media only screen and (min-width:1600px) {
    .page-main .a-wysiwyg blockquote {
      padding: 27px 5px 0 115px
    }
  }

  .page-main .a-wysiwyg blockquote:after,
  .page-main .a-wysiwyg blockquote:before {
    color: #dadfe3;
    content: "\e905";
    display: block;
    font-family: s-icons;
    font-size: 55px;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0
  }

  [dir=rtl] .page-main .a-wysiwyg blockquote:after,
  [dir=rtl] .page-main .a-wysiwyg blockquote:before {
    content: "\e923"
  }

  .page-main .a-wysiwyg blockquote:after {
    left: 39px
  }

  .btn,
  .btn-link {
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font: inherit;
    padding: 0
  }

  .btn.disabled,
  .btn:disabled {
    cursor: default;
    opacity: .5;
    pointer-events: none
  }

  .btn:focus {
    outline: none
  }

  .btn-link {
    color: #005eb8;
    font-size: 12px;
    transition: color .3s ease
  }

  .btn-default {
    background: #005eb8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.5px;
    line-height: 10px;
    max-width: 100%;
    min-width: 200px;
    outline: none;
    padding: 18px 0;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, color .2s ease-in-out .1s, border-color .2s ease-in-out;
    white-space: normal;
    width: auto
  }

  .btn-default span {
    display: inline-flex;
    position: relative
  }

  .btn-default span:after {
    color: #005eb8;
    content: "\e901";
    display: none;
    font-family: s-icons;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: -27px
  }

  .btn-default p {
    display: inline-flex;
    line-height: inherit;
    position: relative
  }

  .btn-default p:after {
    color: #005eb8;
    content: "\e901";
    display: none;
    font-family: s-icons;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: -27px
  }

  .btn-default:hover {
    background-color: #004688
  }

  .btn-default:hover:before {
    display: none
  }

  .btn-default:focus {
    border-color: #005eb8
  }

  .btn-default.blue {
    background: #0269c0
  }

  .btn-default.blue:hover {
    background: #004688
  }

  .btn-default.green {
    background: #47bb3b
  }

  .btn-default.green:hover {
    background: #48a23e
  }

  .btn-default.orange {
    background: #ff9600
  }

  .btn-default.orange:hover {
    background: #ed8b00
  }

  .btn-white {
    background: #005eb8;
    background-color: transparent;
    border: 2px solid #005eb8;
    color: #fff;
    color: #005eb8;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.5px;
    line-height: 10px;
    max-width: 100%;
    min-width: 200px;
    outline: none;
    padding: 18px 0;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, color .2s ease-in-out .1s, border-color .2s ease-in-out;
    white-space: normal;
    width: auto
  }

  .btn-white span {
    display: inline-flex;
    position: relative
  }

  .btn-white span:after {
    color: #005eb8;
    content: "\e901";
    display: none;
    font-family: s-icons;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: -27px
  }

  .btn-white p {
    display: inline-flex;
    line-height: inherit;
    position: relative
  }

  .btn-white p:after {
    color: #005eb8;
    content: "\e901";
    display: none;
    font-family: s-icons;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: -27px
  }

  .btn-white:hover {
    background-color: #004688
  }

  .btn-white:hover:before {
    display: none
  }

  .btn-white.blue {
    background: #0269c0
  }

  .btn-white.blue:hover {
    background: #004688
  }

  .btn-white.green {
    background: #47bb3b
  }

  .btn-white.green:hover {
    background: #48a23e
  }

  .btn-white.orange {
    background: #ff9600
  }

  .btn-white.orange:hover {
    background: #ed8b00
  }

  .btn-white:hover {
    background-color: #fff
  }

  .btn-white:hover p:after,
  .btn-white:hover span:after {
    animation: slide-left-btn 1s ease-in-out infinite;
    display: inline-block
  }

  .btn-white:focus {
    border-color: #005eb8
  }

  .btn-transparent {
    border: 1px solid #005eb8;
    color: #005eb8;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    outline: none;
    padding: 12px 32px;
    transition: all .16s ease-in-out;
    width: fit-content
  }

  .btn-transparent:hover {
    background-color: #005eb8;
    color: #fff
  }

  .btn-link-arrow {
    align-items: center;
    color: #005eb8;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase
  }

  .btn-link-arrow:after {
    color: #005eb8;
    content: "\e901";
    display: inline-block;
    font-family: s-icons;
    font-size: 11px;
    font-weight: 400;
    height: 12px;
    position: absolute;
    top: 5px;
    width: 12px
  }

  .btn-link-arrow--right {
    padding-right: 27px
  }

  .btn-link-arrow--right:after {
    right: 0
  }

  .btn-link-arrow--left {
    padding-left: 27px
  }

  .btn-link-arrow--left:after {
    left: 0;
    transform: rotate(180deg)
  }

  .btn-link-arrow--diagonal:after {
    font-weight: 600;
    transform: translateX(12px) translateY(8px) translateZ(0) scale(1)
  }

  .btn-link-arrow--right:hover:after {
    animation: slide-left 1s ease-in-out infinite
  }

  .btn-link-arrow--left:hover:after {
    animation: slide-right 1s ease-in-out infinite
  }

  .btn-link-arrow--diagonal:hover:after {
    animation: slide-diagonal 1s ease-in-out infinite
  }

  .btn-link-arrow--hide-label:hover:after {
    animation: slide-left 1s ease-in-out .5s infinite
  }

  .btn-link-arrow--hide-label .btn__label-wrapper {
    overflow: hidden
  }

  .btn-link-arrow--hide-label .btn__label {
    color: #005eb8;
    display: block;
    line-height: normal
  }

  @media only screen and (min-width:1025px) {
    .btn-link-arrow--hide-label .btn__label {
      transform: translateX(100%);
      transition: transform .4s ease-in-out
    }
  }

  .btn-link-arrow--hide-label:hover .btn__label {
    transform: translateX(0)
  }

  .btn-burger {
    height: 24px;
    width: 24px
  }

  .btn-burger .burger {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    position: relative;
    width: 100%
  }

  .btn-burger .burger:after,
  .btn-burger .burger:before,
  .btn-burger .burger span {
    content: "";
    display: block;
    height: 2px
  }

  .btn-arrow-down {
    border: none;
    box-shadow: none;
    color: #005eb8;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    padding: 0 10px;
    text-transform: uppercase
  }

  .btn-arrow-down:hover {
    outline: none;
    text-decoration: none
  }

  .btn-arrow-down:hover:after {
    animation: slide-top-bottom 1s ease-in-out infinite
  }

  .btn-arrow-down:after {
    color: #005eb8;
    content: "\e906";
    display: block;
    font-family: s-icons;
    font-size: 10px;
    font-weight: 400;
    margin-top: 2px;
    vertical-align: middle
  }

  @keyframes slide-left-btn {

    0%,
    to {
      opacity: 1;
      transform: translateX(0)
    }

    50% {
      opacity: 1;
      transform: translateX(-5px)
    }
  }

  @keyframes slide-left {

    0%,
    to {
      transform: translateX(0)
    }

    50% {
      transform: translateX(-5px)
    }
  }

  @keyframes slide-right {

    0%,
    to {
      transform: rotate(180deg) translateX(0)
    }

    50% {
      transform: rotate(180deg) translateX(-5px)
    }
  }

  @keyframes slide-diagonal {

    0%,
    to {
      transform: translateX(12px) translateY(8px) translateZ(0) scale(1);
      z-index: 10
    }

    50% {
      transform: translateX(14px) translateY(10px);
      z-index: 10
    }
  }

  @keyframes slide-top-bottom {

    0%,
    to {
      transform: translateY(0)
    }

    50% {
      transform: translateY(3px)
    }
  }

  input.hasError,
  input[type=checkbox].hasError~.checkmark,
  select.hasError,
  textarea.hasError {
    border: 1px solid #e4002b !important
  }

  input.hasError~.form-group__tooltip {
    opacity: 1;
    transform: scale(1);
    visibility: visible
  }

  .form-group__tooltip {
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
    display: flex;
    font-size: 12px;
    height: 52px;
    justify-content: center;
    line-height: 18px;
    list-style: none;
    margin: 0 0 60px;
    opacity: 0;
    padding: 0 8px;
    position: absolute;
    right: 0;
    text-align: center;
    transform: scale(0);
    transform-origin: bottom center;
    transition: transform .3s ease;
    visibility: hidden;
    width: 216px;
    z-index: 15
  }

  .form-group__tooltip:before {
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 6px;
    bottom: -1px;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: rotate(-45deg) translateX(-50%);
    width: 0
  }

  .error .form-group__tooltip {
    opacity: 1;
    transform: scale(1);
    visibility: visible
  }

  .checkcontainer {
    cursor: pointer;
    display: block;
    min-height: 30px;
    padding-left: 48px;
    position: relative;
    user-select: none
  }

  .checkcontainer:not(:last-of-type) {
    margin-bottom: 25px
  }

  .checkcontainer p {
    margin: 0;
    padding: 0
  }

  .checkcontainer input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0
  }

  .checkcontainer:hover input~.checkmark:after {
    border-color: #dadfe3;
    display: block
  }

  .checkcontainer.reset-checkbox .checkmark:after {
    display: none !important
  }

  .checkcontainer .checkmark {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px
  }

  .checkcontainer .checkmark:after {
    border: solid #005eb8;
    border-width: 0 4px 4px 0;
    content: "";
    height: 20px;
    left: 9px;
    position: absolute;
    top: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px
  }

  .checkcontainer input:checked~.checkmark:after {
    display: block
  }

  .checkcontainer:hover input:checked~.checkmark:after {
    border-color: #005eb8
  }

  .page-main #contact-popup-upload .upload-field-area__message-text p {
    color: #6c6e70;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.67;
    margin: 0
  }

  @media (min-width:768px) {
    .page-main #contact-popup-upload .upload-field-area__message-text p {
      font-size: 14px;
      line-height: 1.43
    }
  }

  .page-main #contact-popup-upload .upload-field-area__message-btn p.d-lg-inline-block {
    color: #005eb8;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43
  }

  .page-main #contact-popup-upload .upload-field-area__message-btn p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43
  }

  .upload-field {
    align-items: center;
    border: 1px dashed #bfc3c7;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: border .3s ease;
    width: 100%
  }

  .upload-field input[type=file] {
    display: none
  }

  .dragover .upload-field {
    border: 2px solid #005eb8
  }

  .error-required .upload-field {
    border-color: #e4002b !important
  }

  .upload-field-area {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 114px;
    justify-content: center;
    min-height: 100%;
    padding: 20px 12px;
    position: relative;
    transition: opacity .3s ease;
    width: 100%;
    z-index: 23
  }

  .upload-field-area .icon-s-upload {
    color: #afafaf;
    display: flex;
    font-size: 22px;
    justify-content: flex-end
  }

  .upload-field-area__message {
    line-height: 1.4em;
    padding: 0 0 0 20px
  }

  .upload-field-area__message-text {
    color: #6c6e70;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    margin: 8px 0
  }

  @media (min-width:768px) {
    .upload-field-area__message-text {
      font-size: 14px;
      line-height: 1.43
    }
  }

  .upload-field-area__message-limit {
    color: #bfc3c7;
    font-size: 10px;
    line-height: 1.6
  }

  @media (min-width:768px) {
    .upload-field-area__message-limit {
      font-size: 12px;
      line-height: 1.33
    }
  }

  .upload-field-area__message-btn {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    color: #005eb8;
    cursor: pointer;
    font: inherit;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    transition: color .3s ease
  }

  .upload-field-area__message-btn:hover {
    text-decoration: underline
  }

  .upload-field-area__message-filename {
    color: #005eb8
  }

  .upload-field-fileinput {
    height: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 0
  }

  .upload-field__error {
    align-items: center;
    background-color: #fff;
    color: #e4002b;
    // display: none;
    flex-direction: column;
    font-size: 13px;
    height: 100%;
    justify-content: center;
    left: 0;
    letter-spacing: normal;
    padding: 12px;
    top: 0;
    width: 100%;
    z-index: 22
  }

  .reachLimit .upload-field__error {
    display: flex
  }

  .upload-field-item {
    display: none
  }

  .upload-field-item.active {
    display: block
  }

  .upload-field__limit {
    align-items: center;
    background-color: #fff;
    color: #666;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    left: 0;
    letter-spacing: normal;
    opacity: 0;
    padding: 12px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
  }

  .hasFiles .upload-field-list {
    margin-top: 8px
  }

  .upload-field-list__item {
    border: 1px solid #c7c7c7;
    border-bottom: none;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: 5px 12px;
    width: 100%
  }

  .upload-field-list__total {
    border: 1px solid #c7c7c7;
    color: #222;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 12px
  }

  .upload-field .tooltip-wrapper.available-extensions-tooltip i {
    color: #005eb8;
    font-size: 16px;
    vertical-align: middle
  }

  .upload-field .tooltip-wrapper.available-extensions-tooltip i:hover+.tooltip-message {
    opacity: 1;
    transform: scale(1);
    visibility: visible
  }

  .upload-field .tooltip-wrapper .tooltip-message {
    right: -30px
  }

  .upload-field .tooltip-wrapper .tooltip-message:before {
    left: 77.5%
  }

  @media only screen and (max-width:768px) {
    .upload-field .tooltip-wrapper .tooltip-message {
      right: 0
    }

    .upload-field .tooltip-wrapper .tooltip-message:before {
      left: 91%
    }
  }

  @media (min-width:768px) {
    input[type=file]#requirements {
      height: 25px
    }
  }

  .form-group.select select {
    clip: rect(0 0 0 0) !important;
    border: 0 !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important
  }

  .form-group.select .select2-container .select2-selection--single {
    height: 52px
  }

  .form-group.select .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 10px
  }

  .form-group.select .select2-container--default .select2-selection--single {
    border: 1px solid #c7c7c7;
    border-radius: 0
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #666;
    font-size: 16px;
    line-height: 52px;
    padding-right: 22px
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__rendered[title=Land],
  .form-group.select .select2-container--default .select2-selection--single .select2-selection__rendered[title^=Country] {
    color: #999;
    font-size: 13px
  }

  @media only screen and (max-width:1024px) {

    .form-group.select .select2-container--default .select2-selection--single .select2-selection__rendered[title=Land],
    .form-group.select .select2-container--default .select2-selection--single .select2-selection__rendered[title^=Country] {
      font-size: 12px
    }
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-size: 16px;
    line-height: 42px
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__clear {
    display: none
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
    top: 3px;
    width: 22px
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    border: 8px solid transparent;
    border-top-color: #b9b9b9;
    content: "";
    display: block;
    height: 0;
    left: 0;
    margin-top: -4px;
    position: absolute;
    top: 50%;
    width: 0
  }

  .form-group.select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none
  }

  .form-group.select .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: 0
  }

  .md-field.textarea {
    background: #fff;
    border-color: #cdd3d8;
    margin-bottom: 0 !important
  }

  .md-field.textarea textarea {
    margin-bottom: -1px;
    padding-bottom: 9px
  }

  .md-field.textarea textarea,
  .md-field.textarea textarea.valid {
    border-color: #cdd3d8
  }

  .focused .md-field.textarea textarea,
  .md-field.textarea textarea {
    border-bottom: none
  }

  .md-field.textarea textarea~.md-label {
    color: #939eab
  }

  .md-field.textarea textarea~.md-error {
    background: #fff;
    border: 1px solid #e4002b;
    border-bottom: none;
    border-top: none;
    display: block;
    min-height: 30px;
    padding: 0 0 14px 40px;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    width: 100%
  }

  .md-field.textarea textarea.hasError {
    border-bottom: none !important
  }

  .md-field.textarea textarea.hasError~.md-error {
    bottom: 0;
    position: absolute
  }

  .md-field.textarea textarea.hasError~.md-error~i {
    display: block;
    left: 0;
    margin: 0 10px 10px 14px;
    position: absolute;
    top: calc(100% - 28px)
  }

  .md-field.textarea textarea.noError~.md-error,
  .md-field.textarea textarea.noError~.md-error~i {
    display: none
  }

  .md-field.textarea .txta {
    border: 1px solid #cdd3d8;
    font: inherit;
    font-size: 18px;
    line-height: normal;
    min-height: 96px;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 14px 10px 8px;
    white-space: pre-wrap
  }

  .md-field.textarea.focused {
    border-color: #cdd3d8
  }

  .md-field.textarea.focused textarea.md-input {
    border-color: #005eb8
  }

  .md-field.textarea.focused textarea.md-input~.md-label {
    color: #005eb8
  }

  .md-field.textarea.focused textarea.md-input~.md-error {
    border-color: #005eb8
  }

  .md-field.textarea.focused~#contact-popup-upload .upload-field,
  .md-field.textarea.focused~#contact-popup-upload .upload-field-list {
    border-color: #cdd3d8 #005eb8 #005eb8
  }

  .radiocontainer {
    cursor: pointer;
    display: block;
    min-height: 30px;
    padding-left: 48px;
    position: relative;
    user-select: none
  }

  .radiocontainer:not(:last-of-type) {
    margin-bottom: 25px
  }

  .radiocontainer p {
    margin: 0;
    padding: 0
  }

  .radiocontainer input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0
  }

  .radiocontainer:hover input~.checkmark:after {
    border-color: #dadfe3;
    display: block
  }

  .radiocontainer.reset-checkbox .checkmark:after {
    display: none !important
  }

  .radiocontainer .checkmark {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 100%;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px
  }

  .radiocontainer .checkmark:after {
    border: 5px solid #005eb8;
    border-radius: 100%;
    content: "";
    height: 10px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 10px
  }

  .radiocontainer input:checked~.checkmark:after {
    display: block
  }

  .radiocontainer:hover input:checked~.checkmark:after {
    border-color: #005eb8
  }

  .checkmark:after {
    display: none
  }

  .editmode .radiocontainer {
    margin: 0
  }

  label {
    color: #333;
    font-size: 14px;
    margin-bottom: 5px
  }

  @media only screen and (min-width:768px) {
    label {
      font-size: 18px
    }
  }

  textarea {
    overflow: auto
  }

  input,
  select,
  textarea {
    background: #fff;
    border: 1px solid #c4c4c4;
    color: #222;
    font: inherit;
    font-size: 14px;
    line-height: 2;
    outline: none;
    padding: 10px;
    width: 100%
  }

  @media only screen and (min-width:768px) {

    input,
    select,
    textarea {
      font-size: 18px;
      padding: 7px 10px
    }
  }

  input:focus,
  textarea:focus {
    border-color: #333
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color .25s ease-out
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: transparent;
    -moz-transition: color .25s ease-out
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: transparent
  }

  button::-moz-focus-inner,
  input[type=button]::-moz-focus-inner,
  input[type=file]>input[type=button]::-moz-focus-inner,
  input[type=reset]::-moz-focus-inner,
  input[type=submit]::-moz-focus-inner {
    border: none
  }

  button,
  input[type=submit],
  input[type=tel],
  input[type=text],
  textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0
  }

  .form-group {
    margin-bottom: 30px;
    position: relative;
    width: 100%
  }

  @keyframes validAutofill {
    to {
      background: #fff url('../../../images/app/check.svg') no-repeat right 10px top 16px;
      background-color: #fff !important
    }
  }

  @keyframes invalidAutofill {
    to {
      background: #fff;
      background-color: #fff !important
    }
  }

  .icon-s-error {
    display: none
  }

  .md-field {
    margin-bottom: 20px;
    position: relative;
    width: 100%
  }

  @media only screen and (min-width:768px) {
    .md-field {
      margin-bottom: 30px
    }
  }

  .md-input {
    border: 1px solid #cdd3d8;
    box-shadow: none;
    font-size: 18px;
    line-height: normal;
    outline: none;
    padding: 14px 10px 8px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  .md-input::-webkit-input-placeholder {
    color: #939eab;
    font-size: 18px;
    font-weight: 400;
    text-transform: none
  }

  .md-input:-moz-placeholder,
  .md-input::-moz-placeholder {
    color: #939eab;
    font-size: 18px;
    font-weight: 400;
    text-transform: none
  }

  .md-input:-ms-input-placeholder {
    color: #939eab;
    font-size: 18px;
    font-weight: 400;
    text-transform: none
  }

  .md-input:focus {
    border-color: #cdd3d8
  }

  .md-input.valid:not([required]),
  .md-input:valid[required] {
    border-color: #cdd3d8
  }

  .md-input.valid:not([required])~.md-label,
  .md-input:valid[required]~.md-label {
    opacity: 1;
    transform: translateY(-7px)
  }

  .md-input.valid:not(.hasError) {
    background: #fff url('../../../images/app/check.svg') no-repeat right 10px top 16px;
    border-color: #cdd3d8;
    color: #222
  }

  .md-input.valid:not(.hasError):focus {
    border-color: #005eb8
  }

  .md-input.valid:not(.hasError):focus~.md-label {
    color: #005eb8
  }

  .focused .md-input {
    border-color: #005eb8
  }

  .focused .md-input.noError {
    border-color: #005eb8 !important
  }

  textarea.valid.md-input {
    background-image: none !important
  }

  .md-input.valid.noError {
    background: #fff
  }

  .focused .md-input[customemail] {
    border-color: #005eb8
  }

  .focused .md-input[customemail]~.md-label {
    color: #005eb8
  }

  .md-input:-webkit-autofill,
  .md-input:-webkit-autofill:active,
  .md-input:-webkit-autofill:focus,
  .md-input:-webkit-autofill:hover {
    animation: validAutofill forwards;
    background-clip: content-box !important
  }

  .md-input.hasError {
    border-color: #e4002b !important;
    position: relative
  }

  .md-input.hasError:-webkit-autofill,
  .md-input.hasError:-webkit-autofill:active,
  .md-input.hasError:-webkit-autofill:focus,
  .md-input.hasError:-webkit-autofill:hover {
    animation: invalidAutofill forwards;
    background-clip: content-box !important
  }

  .md-input.hasError:focus {
    border-color: #005eb8 !important;
    outline: none
  }

  .md-input.hasError:focus~.md-label {
    color: #005eb8
  }

  .md-input.hasError~.md-error {
    display: block
  }

  input.md-input.hasError~.md-error~i {
    display: block;
    position: absolute
  }

  .md-input.noError {
    border-color: #cdd3d8 !important
  }

  .md-input.noError~.md-error {
    display: none
  }

  input.md-input.noError~.md-error~i {
    display: none;
    position: absolute
  }

  .md-label {
    background-image: linear-gradient(180deg, transparent 7px, #fff 0);
    color: #939eab;
    cursor: text;
    display: inline-block;
    left: 6px;
    line-height: 1.17;
    margin-bottom: 0;
    opacity: 0;
    padding: 0 4px;
    top: 0;
    transform-origin: 0 100%;
    transition: all .2s ease-out
  }

  .md-error,
  .md-label {
    font-size: 12px;
    position: absolute
  }

  .md-error {
    color: #e4002b;
    display: none;
    list-style: none
  }

  @media only screen and (min-width:768px) {
    .md-error {
      position: absolute
    } 
  }

  .icon-s-error {
    color: #e4002b;
    font-size: 14px;
    right: 10px;
    top: 17px
  }

  .header-global-menu {
    display: flex;
    margin: auto 0;
    padding: 20px 0
  }

  @media (max-width:767px) {
    .header-global-menu {
      margin: 0 0 24px
    }
  }

  .header-global-menu-wrapper {
    height: 100%;
    max-height: calc(100vh - 116px);
    overflow-y: auto
  }

  @media (max-width:767px) {
    .header-global-menu-wrapper {
      max-height: calc(100vh - 80px)
    }
  }

  .header-global-menu-wrapper-background {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 100%
  }

  .header-global-menu-wrapper-background .header-global-container {
    min-height: 100%
  }

  .header-global-menu-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 116px)
  }

  @media (max-width:767px) {
    .header-global-menu-container {
      min-height: calc(100vh - 80px)
    }
  }

  @media (min-width:768px) and (min-height:901px) {
    .header-global-menu-container {
      min-height: 800px
    }
  }

  .header-global-menu-sidebar {
    flex: 0 0 auto;
    margin-right: 32px
  }

  @media (max-width:1279px) {
    .header-global-menu-sidebar {
      margin-right: 30px
    }
  }

  @media (max-width:1023px) {
    .header-global-menu-sidebar {
      margin-right: 20px
    }
  }

  @media (max-width:767px) {
    .header-global-menu-sidebar {
      display: none
    }
  }

  .header-global-menu-sidebar-item {
    color: #a3aab1;
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    margin-bottom: 30px;
    text-transform: uppercase;
    transition: color .16s ease;
    width: fit-content
  }

  @media (max-width:1279px) {
    .header-global-menu-sidebar-item {
      margin-bottom: 26px
    }
  }

  @media (max-width:1023px) {
    .header-global-menu-sidebar-item {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 20px
    }
  }

  .header-global-menu-sidebar-item.active {
    color: #222
  }

  .header-global-menu-sidebar-item.has-sub-menu {
    padding-right: 18px;
    position: relative
  }

  .header-global-menu-sidebar-item.has-sub-menu:after,
  .header-global-menu-sidebar-item.has-sub-menu:before {
    background-color: #a3aab1;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: 2px;
    transition: background-color .16s ease;
    width: 8px
  }

  .header-global-menu-sidebar-item.has-sub-menu:before {
    top: calc(50% - 3px);
    transform: rotate(45deg)
  }

  .header-global-menu-sidebar-item.has-sub-menu:after {
    top: calc(50% + 2px);
    transform: rotate(-45deg)
  }

  .header-global-menu-sidebar-item.active.has-sub-menu:after,
  .header-global-menu-sidebar-item.active.has-sub-menu:before {
    background-color: #222
  }

  .header-global-menu-content {
    flex: 1 1 100%
  }

  .header-global-menu-content-item {
    color: #a3aab1;
    display: none;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    margin-bottom: 24px;
    text-transform: uppercase;
    transition: color .4s ease;
    width: fit-content
  }

  @media (max-width:767px) {
    .header-global-menu-content-item {
      display: block
    }
  }

  .header-global-menu-content-item.active {
    color: #222;
    margin-bottom: 16px
  }

  .header-global-menu-content-item.has-sub-menu {
    padding-right: 18px;
    position: relative
  }

  .header-global-menu-content-item.has-sub-menu:after,
  .header-global-menu-content-item.has-sub-menu:before {
    background-color: #a3aab1;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: calc(50% - .5px);
    transition: all .4s ease;
    width: 8px
  }

  .header-global-menu-content-item.has-sub-menu:before {
    right: 6px;
    transform: rotate(45deg)
  }

  .header-global-menu-content-item.has-sub-menu:after {
    right: 1px;
    transform: rotate(-45deg)
  }

  .header-global-menu-content-item.active.has-sub-menu:before {
    background-color: #222;
    transform: rotate(-45deg)
  }

  .header-global-menu-content-item.active.has-sub-menu:after {
    background-color: #222;
    transform: rotate(45deg)
  }

  .header-global-menu .header-sub-menu-search {
    margin-bottom: 48px;
    margin-top: 0
  }

  @media (max-width:1023px) {
    .header-global-menu .header-sub-menu-search {
      margin-bottom: 42px
    }
  }

  @media (max-width:767px) {
    .header-global-menu .header-sub-menu-search {
      margin-bottom: 30px
    }
  }

  .header-global-menu .header-sub-menu-search-wrap {
    margin-left: 0 !important
  }

  @media (max-width:1279px) {
    .header-global-menu .header-sub-menu-search-wrap {
      max-width: 385px
    }
  }

  @media (max-width:1023px) {
    .header-global-menu .header-sub-menu-search-wrap {
      max-width: 290px
    }
  }

  @media (max-width:767px) {
    .header-global-menu .header-sub-menu-search-wrap {
      max-width: 100%
    }
  }

  .header-global-menu .header-sub-menu-search .container {
    padding: 0 !important
  }

  .header-global-sub-menu {
    display: none
  }

  .header-global-sub-menu.active {
    display: block
  }

  .header-global-sub-menu-columns {
    display: flex;
    flex: 1 0 auto
  }

  @media (max-width:1279px) {
    .header-global-sub-menu-columns {
      flex-wrap: wrap
    }
  }

  .header-global-sub-menu-title {
    color: #3b3c3d;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 22px;
    text-transform: capitalize
  }

  .header-global-sub-menu-title:after {
    background-color: #ed8b00;
    content: "";
    display: block;
    height: 1px;
    margin: 2px 0 0;
    width: 36px
  }

  @media (max-width:1023px) {
    .header-global-sub-menu-title {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 14px
    }
  }

  .header-global-sub-menu-title-offset {
    height: 27px;
    margin-bottom: 22px
  }

  @media (max-width:1023px) {
    .header-global-sub-menu-title-offset {
      margin-bottom: 14px
    }
  }

  @media (max-width:767px) {
    .header-global-sub-menu-title-offset {
      display: none
    }
  }

  .header-global-sub-menu-column {
    flex: 1 1 auto;
    padding-right: 32px
  }

  @media (max-width:1279px) {
    .header-global-sub-menu-column {
      flex-basis: 33.33333%;
      flex-grow: 1 !important;
      margin-bottom: 22px;
      max-width: 33.33333%;
      padding-right: 30px
    }
  }

  @media (max-width:1023px) {
    .header-global-sub-menu-column {
      margin-bottom: 24px
    }
  }

  @media (max-width:767px) {
    .header-global-sub-menu-column {
      flex-basis: 100%;
      margin-bottom: 30px;
      max-width: 100%;
      padding-right: 0
    }
  }

  .header-global-sub-menu-column:last-child {
    margin-right: 0
  }

  .columns-2 .header-global-sub-menu-column:last-child {
    flex-grow: 4
  }

  .columns-3 .header-global-sub-menu-column:last-child {
    flex-grow: 3
  }

  .columns-4 .header-global-sub-menu-column:last-child {
    flex-grow: 2
  }

  .header-global-sub-menu-link {
    margin-bottom: 16px
  }

  @media (max-width:1023px) {
    .header-global-sub-menu-link {
      margin-bottom: 12px
    }
  }

  .header-global-sub-menu-link a {
    color: #3b3c3d;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none
  }

  @media (max-width:1023px) {
    .header-global-sub-menu-link a {
      font-size: 14px;
      line-height: 1.43
    }
  }

  .header-global-sub-menu-link a:visited {
    color: #3b3c3d
  }

  .header-global-sub-menu-link a:hover {
    color: #005eb8
  }

  .header-global {
    background-color: #fff;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 24px;
    transition: opacity .2s ease-in-out, visibility 0s .2s;
    visibility: hidden;
    z-index: 1000000
  }

  .header-global.active {
    opacity: 1;
    transition: opacity .2s ease-in-out, visibility 0s 0s;
    visibility: visible
  }

  @media (max-width:767px) {
    .header-global .container {
      padding: 0 16px
    }
  }

  .header-global-line {
    background-color: #bbb8b821;// #f5faff;
    box-shadow: 0 1px 0 0 #0202021f;
    height: 24px
  }

  @media (max-width:1279px) {
    .header-global-line {
      display: none
    }
  }

  .header-global-line .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end
  }

  @media (max-width:767px) {
    .header-global-line .container {
      padding: 0 16px
    }
  }

  .header-global-line-link {
    align-items: center;
    color: #3b3c3d;
    display: flex;
    font-size: 12px;
    height: 100%;
    line-height: normal;
    margin-right: auto;
    text-decoration: none;
    transition: color .16s ease
  }

  .header-global-line-link:hover {
    color: #005eb8;
    text-decoration: underline
  }

  .header-global-line-link:hover img {
    filter: none
  }

  .header-global-line-link img {
    display: inline-block;
    filter: grayscale(100);
    margin-right: 5px
  }

  .header-global-line-contacts-email,
  .header-global-line-contacts-phone {
    align-items: center;
    color: #3b3c3d;
    display: flex;
    font-size: 12px;
    line-height: normal;
    margin-left: 24px;
    transition: all .16s ease
  }

  .header-global-line-contacts-email:hover,
  .header-global-line-contacts-phone:hover {
    color: #005eb8
  }

  @media (max-width:1279px) {

    .header-global-line-contacts-email,
    .header-global-line-contacts-phone {
      display: none
    }
  }

  .header-global-line .icon-s-envelope {
    font-size: 18px;
    margin: 0 8px -2px 0
  }

  .header-global-line-contacts-phone img {
    height: 12px;
    margin-right: 8px;
    max-width: 24px;
    width: auto
  }

  .header-global-logo {
    align-items: center;
    display: flex;
    height: 92px
  }

  @media (max-width:767px) {
    .header-global-logo {
      height: 56px
    }
  }

  .header-global-logo .header-logo-svg {
    fill: #005eb8;
    display: block;
    height: 36px;
    margin-right: 16px;
    width: 180px
  }

  @media (max-width:767px) {
    .header-global-logo .header-logo-svg {
      display: block !important
    }

    .header-global-logo .header-logo-img-small {
      display: none !important
    }
  }

  .header-global-logo-separator {
    background-color: #005eb8;
    height: 36px;
    width: 2px
  }

  @media (max-width:767px) {
    .header-global-logo-separator {
      display: none
    }
  }

  .header-global-logo-text {
    color: #005eb8;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0 16px
  }

  @media (max-width:767px) {
    .header-global-logo-text {
      display: none
    }
  }

  .header-global-close-btn {
    cursor: pointer;
    flex: 0 0 44px;
    height: 44px;
    margin-left: auto;
    position: relative;
    width: 44px
  }

  @media (max-width:767px) {
    .header-global-close-btn {
      flex: 0 0 24px;
      height: 24px;
      width: 24px
    }
  }

  .header-global-close-btn:hover:after,
  .header-global-close-btn:hover:before {
    background-color: #555
  }

  .header-global-close-btn:after,
  .header-global-close-btn:before {
    background-color: #222;
    content: "";
    display: block;
    height: 3px;
    left: 11px;
    position: absolute;
    top: calc(50% - 1.5px);
    transition: background-color .16s ease-in-out;
    width: 26px
  }

  @media (max-width:767px) {

    .header-global-close-btn:after,
    .header-global-close-btn:before {
      height: 2px;
      left: 6px;
      top: calc(50% - 1px);
      width: 14px
    }
  }

  .header-global-close-btn:before {
    transform: rotate(45deg)
  }

  .header-global-close-btn:after {
    transform: rotate(-45deg)
  }

  .header-global-footer-intro-column {
    align-items: flex-start;
    display: flex;
    max-width: 790px;
    width: 100%
  }

  @media (max-width:767px) {
    .header-global-footer-intro-column {
      flex-direction: column;
      margin-bottom: 48px
    }
  }

  .header-global-footer-intro-column-text {
    flex: 1 1 100%;
    margin-right: 16px
  }

  @media (max-width:767px) {
    .header-global-footer-intro-column-text {
      margin-right: 0
    }
  }

  .header-global-footer-intro-column-text-title {
    color: #3b3c3d;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 6px
  }

  @media (max-width:1023px) {
    .header-global-footer-intro-column-text-title {
      font-size: 16px;
      line-height: 1.5
    }
  }

  .header-global-footer-intro-column-text-description {
    color: #54585b;
    font-size: 14px;
    line-height: 1.43;
    margin: 0
  }

  @media (max-width:767px) {
    .header-global-footer-intro-column-text-description {
      font-size: 12px;
      line-height: 1.67
    }
  }

  .header-global-footer-intro-column-badge {
    display: block;
    flex: 0 0 auto;
    height: auto;
    margin: 4px 14px 0 0;
    max-height: 90px;
    max-width: 260px;
    width: auto
  }

  @media (max-width:1279px) {
    .header-global-footer-intro-column-badge {
      max-height: 80px;
      max-width: 240px
    }
  }

  @media (max-width:1023px) {
    .header-global-footer-intro-column-badge {
      max-height: 60px;
      max-width: 180px
    }
  }

  @media (max-width:767px) {
    .header-global-footer-intro-column-badge {
      margin: 6px 14px 0 0;
      max-height: 70px;
      max-width: 200px
    }
  }

  .header-logo {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-right: 16px
  }

  .header-logo-svg {
    display: block;
    height: 60px;
  }

  @media (max-width:1599px) {
    .header-logo-svg {
      // margin-top: 2rem;
      // height: 60px;
    }
  }

  .header-logo-svg-small {
    fill: #005eb8;
    display: none;
    height: 25px;
    width: 125px
  }

  .header-logo-img-small {
    display: none;
    height: 32px;
    width: 32px
  }

  .header-logo-svg-wrapper {
    position: relative
  }

  .header-logo-svg-wrapper:hover .header-logo-tooltip {
    opacity: 1;
    transition: opacity .16s ease .4s, visibility 0s .4s;
    visibility: visible
  }

  .header-logo-tooltip {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .1);
    color: #222;
    display: block;
    font-size: 14px;
    left: 50%;
    line-height: normal;
    margin: 0;
    opacity: 0;
    padding: 8px 16px;
    position: absolute;
    top: calc(100% + 8px);
    transform: translateX(-50%);
    transition: opacity .16s ease, visibility 0s .16s;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1
  }

  @media only screen and (max-width:1279px) {
    .header-logo-tooltip {
      display: none
    }
  }

  .header-logo-separator {
    background-color: #005eb8;
    height: 36px;
    margin-left: 16px;
    width: 2px
  }

  @media (max-width:767px) {
    .header-logo-separator {
      display: none
    }
  }

  .header-logo-site-name {
    align-items: center;
    background-color: #eaf4fc;
    color: #005eb8;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 36px;
    line-height: normal;
    margin: 0;
    padding: 0 16px
  }

  a.header-logo-site-name:hover,
  a.header-logo-site-name:visited {
    color: #005eb8
  }

  @media (max-width:767px) {
    .header-logo-site-name {
      background-color: transparent;
      padding: 0 8px
    }
  }

  .header-menu {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    justify-content: flex-end;
    padding-left: 16px
  }

  @media (max-width:1279px) {
    .header-menu {
      display: none
    }
  }

  .header-menu-item {
    align-items: center;
    display: flex;
    height: 100%
  }

  .header-menu-item.active .header-menu-item-label {
    border-bottom: 3px solid #005eb8 !important
  }

  .header-menu-item-label {
    align-items: center;
    border-bottom: 3px solid transparent;
    color: #222;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    line-height: normal;
    margin: 0;
    padding: 0 16px;
    transition: all .16s ease
  }

  .header-menu-item-label:hover {
    background-color: #eaf4fc
  }

  a.header-menu-item-label:visited {
    color: #222
  }

  .header-menu-page-shadow {
    background: rgba(29, 35, 44, .1);
    height: calc(100vh - 100%);
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: opacity .2s ease-in-out, visibility 0s .2s;
    visibility: hidden;
    width: 100%;
    z-index: 30
  }

  .header-menu-item.accented .header-menu-item-label {
    background-color: #005eb8;
    color: #fff;
    height: auto;
    margin: 0 16px;
    padding: 9px 24px
  }

  .header-menu-item.accented:hover .header-menu-item-label {
    background-color: #004485;
    color: #fff
  }

  .header-menu-item.items-to-left {
    margin-right: auto
  }

  .header-menu-item.hide-intro-column .header-sub-menu-intro-column {
    display: none
  }

  .header-menu-item.hide-intro-column .columns-2 .header-sub-menu-column:last-child {
    flex-grow: 6
  }

  .header-menu-item.hide-intro-column .columns-3 .header-sub-menu-column:last-child {
    flex-grow: 5
  }

  .header-menu-item.hide-intro-column .columns-4 .header-sub-menu-column:last-child {
    flex-grow: 4
  }

  .header-menu-item.hide-intro-column .columns-5 .header-sub-menu-column:last-child {
    flex-grow: 3
  }

  .header-menu-item.hide-intro-column .columns-6 .header-sub-menu-column:last-child {
    flex-grow: 2
  }

  .header-menu-item.has-sub-menu .header-menu-item-label {
    padding-right: 30px;
    position: relative
  }

  .header-menu-item.has-sub-menu .header-menu-item-label:after,
  .header-menu-item.has-sub-menu .header-menu-item-label:before {
    background-color: #222;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: calc(50% - .5px);
    transition: transform .16s ease;
    width: 7px
  }

  .header-menu-item.has-sub-menu .header-menu-item-label:before {
    right: 18.5px;
    transform: rotate(45deg)
  }

  .header-menu-item.has-sub-menu .header-menu-item-label:after {
    right: 14px;
    transform: rotate(-45deg)
  }

  .header-menu-item.has-sub-menu.active-submenu .header-menu-item-label {
    border-bottom: 3px solid #bbd6ee
  }

  .header-menu-item.has-sub-menu.active-submenu .header-menu-item-label:before {
    transform: rotate(-45deg)
  }

  .header-menu-item.has-sub-menu.active-submenu .header-menu-item-label:after {
    transform: rotate(45deg)
  }

  .header-menu-item.has-sub-menu.active-submenu .header-sub-menu {
    z-index: 51
  }

  .header-menu-item.has-sub-menu.active-submenu .header-sub-menu,
  .header-menu-item.has-sub-menu.active-submenu~.header-menu-page-shadow {
    opacity: 1;
    transition: opacity .2s ease-in-out, visibility 0s 0s;
    visibility: visible
  }

  .header-sub-menu {
    background-color: #fff;
    border-top: 1px solid #dce1e5;
    left: 0;
    opacity: 0;
    padding-bottom: 34px;
    padding-top: 24px;
    position: absolute;
    right: 0;
    top: 100%;
    transition: opacity .2s ease-in-out, visibility 0s .2s;
    visibility: hidden;
    z-index: -1
  }

  .header-sub-menu-container {
    display: flex
  }

  .header-sub-menu-columns {
    display: flex;
    flex: 1 0 auto
  }

  .header-sub-menu-intro-column {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    max-width: 30%;
    min-width: 150px;
    padding-left: 0 !important;
    padding-right: 23px !important;
    width: 100%
  }

  .header-sub-menu-intro-column :last-child {
    margin-bottom: 0
  }

  .header-sub-menu-intro-column-title {
    color: #222;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 10px;
    text-transform: capitalize
  }

  .header-sub-menu-intro-column-text {
    color: #464749;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0
  }

  .header-sub-menu-intro-column-badge {
    margin: 20px 0 -15px;
    max-height: 75px;
    max-width: 100%
  }

  .header-sub-menu-intro-column-cta {
    margin-top: auto;
    padding-top: 24px
  }

  .header-sub-menu-intro-column-cta p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0
  }

  .header-sub-menu-intro-column-cta button {
    background-color: #005eb8;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    max-width: 200px;
    min-width: 130px;
    outline: 0;
    padding: 10px;
    transition: background-color .16s ease
  }

  .header-sub-menu-intro-column-cta button:hover {
    background-color: #004485
  }

  .header-sub-menu-title {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
    text-transform: capitalize
  }

  .header-sub-menu-title:after {
    background-color: #ed8b00;
    content: "";
    display: block;
    height: 2px;
    margin: 8px 0 0;
    width: 30px
  }

  .header-sub-menu-title-offset {
    height: 34px;
    margin-bottom: 15px
  }

  .header-sub-menu-column {
    flex: 1 1 auto;
    margin-right: 30px
  }

  .header-sub-menu-column:last-child {
    margin-right: 0
  }

  .columns-2 .header-sub-menu-column:last-child {
    flex-grow: 4
  }

  .columns-3 .header-sub-menu-column:last-child {
    flex-grow: 3
  }

  .columns-4 .header-sub-menu-column:last-child {
    flex-grow: 2
  }

  .header-sub-menu-link {
    margin-bottom: 15px
  }

  .header-sub-menu-link:last-child {
    margin-bottom: 0
  }

  .header-sub-menu-link.active a {
    color: #005eb8;
    text-decoration: underline
  }

  .header-sub-menu-link.active a:visited {
    color: #005eb8
  }

  .header-sub-menu-link a {
    color: #333;
    font-size: 14px;
    text-decoration: none
  }

  .header-sub-menu-link a:visited {
    color: #333
  }

  .header-sub-menu-link a:hover {
    color: #005eb8
  }

  .header-sub-menu-close-btn {
    cursor: pointer;
    flex: 0 0 32px;
    height: 32px;
    position: relative;
    width: 32px
  }

  .header-sub-menu-close-btn:hover:after,
  .header-sub-menu-close-btn:hover:before {
    background-color: #555
  }

  .header-sub-menu-close-btn:after,
  .header-sub-menu-close-btn:before {
    background-color: #222;
    content: "";
    display: block;
    height: 2px;
    left: 6px;
    position: absolute;
    top: calc(50% - 1px);
    transition: background-color .16s ease-in-out;
    width: 20px
  }

  .header-sub-menu-close-btn:before {
    transform: rotate(45deg)
  }

  .header-sub-menu-close-btn:after {
    transform: rotate(-45deg)
  }

  .header-sub-menu-search {
    border-top: 1px solid #e7eaec;
    margin-top: 20px;
    padding-top: 12px
  }

  @media (max-width:1279px) {
    .header-sub-menu-search {
      margin-top: 22px
    }

    .header-sub-menu-search .container {
      padding: 0 24px !important
    }
  }

  .header-sub-menu-search p {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    margin-bottom: 4px
  }

  @media (max-width:1279px) {
    .header-sub-menu-search p {
      font-size: 16px;
      line-height: 1.63
    }
  }

  .header-sub-menu-search-wrap {
    max-width: 553px
  }

  @media (max-width:1599px) {
    .header-sub-menu-search-wrap {
      max-width: 470px
    }
  }

  @media (max-width:1279px) {
    .header-sub-menu-search-wrap {
      margin-left: 0 !important
    }
  }

  .header-sub-menu-search-inner {
    position: relative
  }

  .header-sub-menu-search-inner input {
    background-color: #fff;
    border: 1px solid #cdd3d8;
    border-radius: 3px;
    font-size: 16px;
    height: 40px;
    line-height: 26px;
    padding: 7px 58px 7px 16px
  }

  .header-sub-menu-search-inner input::placeholder {
    color: #717171
  }

  @media (max-width:1279px) {
    .header-sub-menu-search-inner input {
      padding-right: 54px
    }
  }

  .header-sub-menu-search-icon {
    color: #717171;
    font-size: 16px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%)
  }

  @media (max-width:1279px) {
    .header-sub-menu-search-icon {
      right: 20px
    }
  }

  .header-sub-menu-intro-column.has-badge .header-sub-menu-intro-column-title {
    font-size: 18px
  }

  .header-sub-menu-intro-column.has-badge .header-sub-menu-intro-column-text {
    font-size: 14px
  }

  .header-menu-mobile {
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 5px 0 50px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(105%);
    transition: transform .4s ease-in-out, box-shadow .4s ease-in-out, visibility 0s .4s;
    visibility: hidden;
    width: 276px;
    z-index: 110
  }

  @media (min-width:1280px) {
    .header-menu-mobile {
      display: none !important
    }
  }

  .header-menu-mobile-items {
    flex: 1 0 auto;
    margin-bottom: 10px;
    max-height: 100%;
    overflow-y: auto
  }

  .header-menu-mobile-close-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    right: calc(100% + 16px);
    top: 16px;
    z-index: 111
  }

  .header-menu-mobile-close-btn i {
    color: #fff;
    font-size: 16px;
    font-weight: 700
  }

  .header-menu-mobile-item-label {
    color: #222;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 12px 24px;
    position: relative;
    text-transform: uppercase;
    transition: color .4s ease-in-out
  }

  .header-menu-mobile-item-label:visited {
    color: #222
  }

  .header-menu-mobile-item.accented .header-menu-mobile-item-label {
    background-color: #005eb8;
    color: #fff;
    display: inline-block;
    margin: 12px 24px;
    padding: 10px 20px
  }

  .header-menu-mobile-item.accented .header-menu-mobile-item-label:hover {
    background-color: #004485
  }

  .header-menu-mobile-item.has-sub-menu:not(.accented) .header-menu-mobile-item-label:after {
    color: #005eb8;
    content: "\e906";
    font-family: s-icons;
    font-size: 7px;
    position: absolute;
    right: 24px;
    top: calc(50% - 3px);
    transition: transform .4s ease-in-out
  }

  .header-menu-mobile-item.has-sub-menu:not(.accented).active .header-menu-mobile-item-label {
    color: #005eb8
  }

  .header-menu-mobile-item.has-sub-menu:not(.accented).active .header-menu-mobile-item-label:after {
    transform: rotate(180deg)
  }

  .header-menu-mobile-cta {
    flex: 0 0 auto;
    margin-top: auto;
    padding: 12px 24px
  }

  .header-menu-mobile-cta p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 15px
  }

  .header-menu-mobile-cta button {
    background-color: #005eb8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    max-width: 200px;
    min-width: 130px;
    outline: 0;
    padding: 10px;
    text-transform: uppercase
  }

  .header-menu-mobile-cta button:hover {
    background-color: #004485
  }

  .header-menu-mobile-shadow {
    background-color: rgba(29, 35, 44, .8);
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .4s ease-in-out, visibility 0s .4s;
    visibility: hidden;
    width: 100vw;
    z-index: 105
  }

  @media (min-width:1280px) {
    .header-menu-mobile-shadow {
      display: none !important
    }
  }

  .header-menu-mobile-contacts {
    margin: 28px 24px 10px
  }

  .header-menu-mobile-contacts a {
    align-items: center;
    color: #005eb8;
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
    text-decoration: none
  }

  .header-menu-mobile-contacts a i {
    color: #222;
    display: block;
    font-size: 24px;
    margin-right: 10px
  }

  .header-menu-mobile-contacts a .icon-s-phone-bordered {
    margin-bottom: -4px
  }

  .header-menu-mobile-contacts a .icon-s-envelope {
    margin-bottom: -5px
  }

  .header-menu-mobile .header-red-button {
    margin-left: 24px;
    margin-top: 4px;
    width: fit-content
  }

  .header-menu-mobile .header-red-button-label {
    padding: 8px 26px
  }

  .header.menu-mobile-active .header-menu-mobile {
    box-shadow: -4px 0 10px 0 rgba(0, 0, 0, .6);
    transform: translateX(0);
    transition: transform .4s ease-in-out, box-shadow .4s ease-in-out;
    visibility: visible
  }

  .header.menu-mobile-active .header-menu-mobile-shadow {
    opacity: 1;
    transition: opacity .4s ease-in-out;
    visibility: visible
  }

  .header-sub-menu-mobile {
    display: none;
    padding-left: 24px
  }

  .header-sub-menu-mobile-title {
    color: #222;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 8px 12px;
    text-transform: capitalize
  }

  .header-sub-menu-mobile-title:after {
    background-color: #ed8b00;
    content: "";
    display: block;
    height: 2px;
    margin: 8px 0 0;
    width: 30px
  }

  .header-sub-menu-mobile-link {
    color: #2b2826;
    display: block;
    font-size: 14px;
    padding: 8px 12px;
    text-transform: capitalize
  }

  .header-sub-menu-mobile-link:visited {
    color: #2b2826
  }

  .header-search {
    align-items: center;
    display: flex;
    flex: 0 0 auto
  }

  .header-search-open-btn {
    background-color: inherit;
    border-radius: 0;
    color: #222;
    margin-left: 8px;
    padding: 8px;
    transition: color .16s ease-in-out
  }

  .header-search-open-btn:hover {
    color: #424242
  }

  .header-search-open-btn i {
    display: block;
    font-size: 18px
  }

  .header-search form {
    align-items: center;
    display: none;
    flex-wrap: nowrap;
    margin-left: 8px;
    width: 100%
  }

  @media (max-width:767px) {
    .header-search form {
      margin-left: 4px
    }
  }

  .header-search form input[type=text] {
    border: 1px solid #e7e7e7;
    color: #2b2826;
    font-size: 14px;
    line-height: normal;
    margin: 0 10px 0 0;
    padding: 9px 13px
  }

  .header-search-close-btn {
    font-size: 18px;
    line-height: normal;
    padding: 8px
  }

  .header-search-close-btn i {
    vertical-align: middle
  }

  .header.search-active .header-search {
    flex: 1 0 auto
  }

  .header.search-active .header-search form {
    display: flex
  }

  .header.search-active .header-menu,
  .header.search-active .header-phone,
  .header.search-active .header-search-open-btn {
    display: none
  }

  @media (max-width:767px) {
    .header.search-active .header-logo {
      display: none
    }
  }

  .header {
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    height: 92px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out;
    width: 100%;
    z-index: 100
  }

  .two-levels-menu .header {
    height: 122px
  }

  .microsite .header {
    height: 92px
  }

  .microsite.two-levels-menu .header {
    height: 122px
  }

  @media (max-width:1599px) {

    .header,
    .microsite .header,
    .microsite.two-levels-menu .header,
    .two-levels-menu .header {
      height: 84px
    }
  }

  @media (max-width:1279px) {

    .header,
    .two-levels-menu .header {
      height: 60px
    }

    .microsite .header,
    .microsite.two-levels-menu .header {
      height: 84px
    }
  }

  @media (max-width:767px) {

    .header,
    .two-levels-menu .header {
      height: 56px
    }

    .microsite .header,
    .microsite.two-levels-menu .header {
      height: 80px
    }
  }

  @media (min-width:1920px) {
    .header.header-transparent {
      background-color: transparent;
      box-shadow: none
    }
  }

  .header.has-search-bar .header-search {
    display: none
  }

  .header.has-search-bar .header-menu {
    padding-right: 0
  }

  .header-container {
    height: calc(100% - 24px)
  }

  @media (max-width:1279px) {
    .header-container {
      height: 100%
    }
  }

  @media (max-width:767px) {
    .header-container {
      padding: 0 16px
    }
  }

  .header-row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 100%
  }

  @media (max-width:1279px) {
    .header-row {
      justify-content: space-between
    }
  }

  .header-burger-btn {
    background: transparent;
    border: 2px solid #222;
    border-left: none;
    border-right: none;
    cursor: pointer;
    display: none;
    height: 18px;
    margin-left: 16px;
    outline: 0;
    position: relative;
    transition: border-color .16s ease-in-out;
    width: 24px
  }

  @media (max-width:1279px) {
    .header-burger-btn {
      display: block
    }
  }

  .header-burger-btn:hover {
    border-color: #424242
  }

  .header-burger-btn:hover:after {
    background-color: #424242
  }

  .header-burger-btn:after {
    background-color: #222;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 6px;
    transition: background-color .16s ease-in-out;
    width: 100%
  }

  .header-phone {
    color: #3b3c3d;
    display: none;
    font-size: 14px;
    margin-left: auto;
    padding: 8px;
    transition: all .16s ease
  }

  @media (max-width:1279px) {
    .header-phone {
      align-items: center;
      display: flex
    }
  }

  .header-phone:hover {
    color: #005eb8
  }

  .header-phone i {
    display: none;
    font-size: 24px
  }

  @media (max-width:767px) {
    .header-phone i {
      display: block
    }
  }

  .header-phone img {
    margin-right: 6px
  }

  @media (max-width:767px) {

    .header-phone-text,
    .header-phone img {
      display: none
    }
  }

  .header-red-buttons {
    align-items: center;
    display: flex
  } 

  @media (max-width:1279px) {
    .header-red-buttons {
      display: none
    }
  }

  .header-red-button {
    background-image: linear-gradient(90deg, #ED2600, #CBD4DD);
    background-size: 150%;
    box-shadow: 0 7px 25px -17px #222;
    margin-left: 28px;
    padding: 2px;
    position: relative;
    transition: background .25s ease
  }

  .header-red-button:after,
  .header-red-button:before {
    animation: red-button-pulse-1 8s linear 0s infinite normal both;
    border: 3px solid #FF3800;
    border-radius: 3px;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
  }

  .header-red-button:before {
    animation-name: red-button-pulse-2
  }

  .header-red-button:hover {
    background-position: 50%
  }

  .header-red-button-label {
    background: transparent;
    border: 1px solid #fff4cf;
    color: #222;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 7.5px 22px
  }

  @media (max-width:1279px) {
    .header:not(.menu-mobile-active) .header-red-button:before .header:not(.menu-mobile-active) .header-red-button:after {
      animation: none
    }
  }

  .header.sb-header-transparent.header-menu-open {
    background-color: #fff !important
  }

  .two-levels-menu .header.search-active .header-menu {
    display: flex
  }

  @media (max-width:1279px) {
    .two-levels-menu .header.search-active .header-menu {
      display: none
    }
  }

  .two-levels-menu .header-row:first-child {
    height: 36px;
    margin: 8px 0
  }

  @media (max-width:1279px) {
    .two-levels-menu .header-row:first-child {
      height: 100%;
      margin: 0
    }
  }

  .two-levels-menu .header-row:last-child {
    height: 46px
  }

  .two-levels-menu .header-menu {
    padding: 0
  }

  .two-levels-menu .header-menu-item.accented .header-menu-item-label {
    padding: 6px 16px
  }

  .two-levels-menu .header-search {
    margin-left: auto
  }

  @media (max-width:1279px) {
    .two-levels-menu .header-search {
      margin-left: 0
    }
  }

  .two-levels-menu .header-search form input[type=text] {
    padding: 7.5px 13px
  }

  @media (max-width:1279px) {
    .two-levels-menu .header-search form input[type=text] {
      padding: 9px 13px
    }
  }

  @keyframes red-button-pulse-1 {

    0%,
    91.25% {
      opacity: 1;
      transform: scale(1)
    }

    93.12% {
      opacity: .8
    }

    95% {
      opacity: .2;
      transform: scale(1.2, 1.6)
    }

    95.01% {
      opacity: 0
    }

    95.02%,
    96.25% {
      opacity: 1;
      transform: scale(1)
    }

    98.12% {
      opacity: .8
    }

    99.99% {
      opacity: .2
    }

    to {
      opacity: 0;
      transform: scale(1.2, 1.6)
    }
  }

  @keyframes red-button-pulse-2 {

    0%,
    93.75% {
      opacity: 1;
      transform: scale(1)
    }

    95.62% {
      opacity: .8
    }

    97.5% {
      opacity: .2
    }

    97.51%,
    to {
      opacity: 0;
      transform: scale(1.2, 1.6)
    }
  }

  .footer-logo {
    flex: 0 0 auto;
    margin-right: 16px;
  }

  @media (max-width:900px) {
    .footer-logo {
      flex: 1 1 100%
    }
  }

  .footer-logo-img-wrapper {
    display: block;
    margin-top: -27px;
  }

  .footer-logo-img-wrapper img { 
    display: block;
    height: 70px;
    width: 200px;
  }

  .footer-socials {
    margin-top: 0px;
  }

  .footer-socials-links {
    align-items: center;
    display: flex
  }

  .footer-socials-links a {
    display: block;
    transition: opacity .16s ease-in-out
  }

  .footer-socials-links a:not(:last-child) {
    margin-right: 30px
  }

  .footer-socials-links a:hover {
    opacity: .5
  }

  .footer-socials-links-linkedin:before {
    content: "\e907"
  }

  .footer-socials-links-linkedin:before,
  .footer-socials-links-twitter:before {
    color: #fff;
    display: block;
    font-family: s-icons;
    font-size: 22px;
    font-weight: 400;
    height: 24px;
    width: 24px
  }

  .footer-socials-links-twitter:before {
    content: "\e90e"
  }

  .footer-socials-links-facebook:before {
    content: "\e904"
  }

  .footer-socials-links-dribble:before,
  .footer-socials-links-facebook:before {
    color: #fff;
    display: block;
    font-family: s-icons;
    font-size: 22px;
    font-weight: 400;
    height: 24px;
    width: 24px
  }

  .footer-socials-links-dribble:before {
    content: "\e919"
  }

  .footer-contacts-call {
    position: relative;
    width: fit-content
  }

  .footer-contacts-call.active .footer-contacts-call-btn {
    background-color: hsla(0, 0%, 100%, .1)
  }

  .footer-contacts-call-btn {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: normal;
    padding: 10px 56px;
    transition: all .16s ease
  }

  .footer-contacts-call-btn:hover {
    background-color: hsla(0, 0%, 100%, .1)
  }

  .footer-contacts-call-tooltip {
    background: #fff;
    border-radius: 1px;
    bottom: calc(100% + 14px);
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, .1);
    left: 50%;
    opacity: 0;
    padding: 16px 24px;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity .26s ease-in-out, visibility 0s .26s;
    visibility: hidden;
    width: 400px;
    z-index: 10
  }

  @media (max-width:767px) {
    .footer-contacts-call-tooltip {
      width: 330px
    }
  }

  .footer-contacts-call-tooltip-ledge {
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 8px 8px 0;
    bottom: calc(100% + 6.5px);
    height: 0;
    left: calc(50% - 8px);
    opacity: 0;
    position: absolute;
    transition: opacity .26s ease-in-out, visibility 0s .26s;
    visibility: hidden;
    width: 0;
    z-index: 11
  }

  .footer-contacts-call-tooltip-content {
    position: relative
  }

  .footer-contacts-call-tooltip-title {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px
  }

  .footer-contacts-call-tooltip-close {
    background: transparent;
    border: none;
    color: #222;
    cursor: pointer;
    display: block;
    outline: none;
    padding: 3px;
    position: absolute;
    right: 0;
    top: 0
  }

  .footer-contacts-call-tooltip-close i {
    font-size: 14px;
    font-weight: 700
  }

  .footer-contacts-call-tooltip-form-row {
    align-items: flex-start;
    display: flex;
    margin-bottom: 16px
  }

  @media (max-width:767px) {
    .footer-contacts-call-tooltip-form-row {
      display: block
    }
  }

  .footer-contacts-call-tooltip-form-row .form-input-wrapper {
    flex: 1 1 auto
  }

  .footer-contacts-call-tooltip-form-row .form-input-wrapper:not(:last-child) {
    margin-right: 16px
  }

  @media (max-width:767px) {
    .footer-contacts-call-tooltip-form-row .form-input-wrapper:not(:last-child) {
      margin: 0 0 16px
    }
  }

  .footer-contacts-call-tooltip-form-row .form-input {
    padding: 8px 10px
  }

  .footer-contacts-call-tooltip-success-text {
    color: #222;
    font-size: 18px;
    line-height: 1.78
  }

  .footer-contacts-call-tooltip-submit {
    background: #000;
    border: none;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    outline: none;
    padding: 9px 32px;
    transition: all .16s ease
  }

  .footer-contacts-call-tooltip-submit:hover {
    background-color: #CBD4DD
  }

  .footer-contacts-call-tooltip-submit.disabled {
    opacity: .5;
    pointer-events: none;
    background-color: #303131;
  }

  .footer-contacts-call-tooltip-submit-success {
    background-color: #005eb8;
    border-radius: 2px;
    color: #fff;
    display: none;
    font-family: s-icons;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 16px;
    opacity: .5;
    padding: 8px 32px
  }

  .footer-contacts-call-tooltip-submit-success.active {
    align-items: center;
    display: flex;
    justify-content: center
  }

  .footer-contacts-call.active .footer-contacts-call-tooltip,
  .footer-contacts-call.active .footer-contacts-call-tooltip-ledge {
    opacity: 1;
    transition: opacity .26s ease-in-out, visibility 0s 0s;
    visibility: visible
  }

  .footer-contacts {
    flex: 1 1 100%
  }

  @media (max-width:1279px) {
    .footer-contacts {
      flex: 1 1 auto
    }
  }

  @media (max-width:900px) {
    .footer-contacts {
      margin-top: 44px
    }
  }

  .footer-contacts-row {
    align-items: flex-start;
    display: flex;
    flex: 1 1 100%
  }

  .footer-contacts-links {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    margin-bottom: -16px
  }

  @media (max-width:1279px) {
    .footer-contacts-links {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: auto;
      max-width: 644px;
      padding-left: 24px
    }
  }

  @media (max-width:900px) {
    .footer-contacts-links {
      margin-left: 0;
      padding-left: 0
    }
  }

  @media (max-width:680px) {
    .footer-contacts-links {
      display: block
    }
  }

  .footer-contacts-links-item {
    align-items: flex-start;
    color: hsla(0, 0%, 100%, .7);
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
    max-width: 270px;
    min-width: 120px
  }

  @media (max-width:1279px) {
    .footer-contacts-links-item {
      max-width: unset
    }

    .footer-contacts-links-item:nth-child(odd) {
      flex: 0 0 58%
    }
  }

  .footer-contacts-links-item:first-child {
    margin-left: 7%
  }

  @media (max-width:1439px) {
    .footer-contacts-links-item:first-child {
      margin-left: 4%
    }
  }

  @media (max-width:1279px) {
    .footer-contacts-links-item:first-child {
      margin-left: 0
    }
  }

  .footer-contacts-links-item:not(:last-child) {
    margin-right: 16px
  }

  .footer-contacts-links-item>i {
    display: block;
    height: 24px;
    margin-right: 8px;
    width: 24px
  }

  .footer-contacts-links-item>i:before {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 24px;
    height: 24px;
    justify-content: center;
    line-height: normal;
    width: 24px
  }

  .footer-contacts-links-item>i.icon-s-phone:before {
    font-size: 22px
  }

  .footer-contacts-links-item a {
    transition: all .16s ease-in-out
  }

  .footer-contacts-links-item a:hover {
    color: #fff
  }

  .footer-contacts-links-item-email:hover {
    text-decoration: underline
  }

  .footer-contacts-links-item-phones {
    width: 100%
  }

  .footer-contacts-links-item-phone {
    display: block
  }

  @media (max-width:1023px) {
    .footer-contacts.addresses-count-2 {
      flex: 1 1 100%;
      margin-top: 44px
    }
  }

  .footer-contacts.addresses-count-2 .footer-contacts-rows {
    display: flex;
    justify-content: space-evenly
  }

  @media (max-width:1023px) {
    .footer-contacts.addresses-count-2 .footer-contacts-rows {
      justify-content: flex-start
    }
  }

  @media (max-width:680px) {
    .footer-contacts.addresses-count-2 .footer-contacts-rows {
      display: block;
      margin-bottom: -30px
    }
  }

  .footer-contacts.addresses-count-2 .footer-contacts-row {
    display: block;
    flex: 0 0 auto
  }

  @media (max-width:1023px) {
    .footer-contacts.addresses-count-2 .footer-contacts-row {
      margin-right: auto
    }
  }

  @media (max-width:680px) {
    .footer-contacts.addresses-count-2 .footer-contacts-row {
      padding-bottom: 30px
    }
  }

  .footer-contacts.addresses-count-2 .footer-contacts-links {
    display: block;
    flex: 0 0 auto
  }

  @media (max-width:1023px) {
    .footer-contacts.addresses-count-2 .footer-contacts-links {
      margin-left: 0;
      padding-left: 0
    }
  }

  @media (max-width:1279px) {
    .footer-contacts.addresses-count-2 .footer-contacts-links-item {
      max-width: 270px
    }
  }

  .footer-contacts.addresses-count-2 .footer-contacts-links-item:first-child {
    margin-left: 0 !important
  }

  .footer-links {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
    margin-right: 16px
  }

  @media (max-width:680px) {
    .footer-links {
      display: block
    }
  }

  .footer-links-item {
    color: hsla(0, 0%, 100%, .7);
    font-size: 14px;
    margin-bottom: 16px
  }

  .footer-links-item:not(:last-child) {
    margin-right: 32px
  }

  .footer-links-item a {
    color: hsla(0, 0%, 100%, .7);
    transition: all .16s ease-in-out
  }

  .footer-links-item a:hover {
    color: #fff
  }

  .footer-badges {
    display: flex;
    margin-bottom: -16px;
    margin-left: auto
  }

  @media (max-width:1279px) {
    .footer-badges {
      flex: 1 1 100%;
      margin-left: 0;
      margin-top: 44px
    }
  }

  @media (max-width:680px) {
    .footer-badges {
      flex-wrap: wrap
    }
  }

  .footer-badges>* {
    margin-bottom: 16px
  }

  .footer-badges-financial-times {
    display: block;
    margin-right: 32px
  }

  .footer-badges-financial-times img {
    display: block;
    height: 57px;
    width: auto
  }

  .footer-badges-bbb {
    display: block;
    height: 57px;
    margin-right: 32px !important;
    overflow: hidden;
    width: 150px
  }

  .footer-badges-bbb img {
    display: block;
    height: 57px;
    width: 300px
  }

  .footer-badges-bbb img:hover {
    margin-left: -150px
  }

  .footer-badges-clutch {
    align-items: center;
    display: flex;
    height: 58px
  }

  .footer-badges-clutch iframe {
    background-color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 -21px;
    transform: scale(.65);
    width: 120px
  }

  .footer-copyright {
    color: hsla(0, 0%, 100%, .7);
    font-size: 14px;
    margin-right: 16px
  }

  @media (max-width:680px) {
    .footer-copyright {
      width: 100%
    }
  }

  .footer-copyright-text {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px
  }

  .footer-copyright-text-corporation {
    margin-right: 4px
  }

  .footer-copyright-text p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 4px
  }

  .footer-copyright-dmca {
    margin-top: 8px
  }

  .footer-language {
    font-size: 14px;
    margin-left: auto;
    position: relative
  }

  @media (max-width:680px) {
    .footer-language {
      margin-left: 0;
      margin-top: 16px
    }
  }

  .footer-language-current {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 8px 22px 8px 2px;
    position: relative
  }

  .footer-language-current:after {
    content: "\e90d";
    display: block;
    font-family: s-icons;
    font-size: 12px;
    height: 12px;
    position: absolute;
    right: 3px;
    top: calc(50% - 6px);
    transform: rotate(-90deg);
    transition: transform .26s ease-in-out;
    width: 7px
  }

  .footer-language-current img {
    display: block;
    margin-right: 6px
  }

  .footer-language-list {
    background-color: #193d5f;
    bottom: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity .32s ease, visibility 0s .32s;
    visibility: hidden
  }

  .footer-language-list-item {
    align-items: center;
    background-color: inherit;
    color: hsla(0, 0%, 100%, .7);
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    transition: all .16s ease
  }

  .footer-language-list-item:hover {
    background-color: hsla(0, 0%, 100%, .3);
    color: #fff
  }

  .footer-language-list-item img {
    display: block;
    margin-right: 6px
  }

  .footer-language.active .footer-language-current:after {
    transform: rotate(-270deg)
  }

  .footer-language.active .footer-language-list {
    opacity: 1;
    transition: opacity .32s ease-in-out, visibility 0s 0s;
    visibility: visible
  }

  .footer-content {
    background-color: #000;
    padding: 44px 0
  }

  @media (max-width:1279px) {
    .footer-content {
      padding-bottom: 52px
    }
  }

  @media (max-width:680px) {
    .footer-content {
      padding-bottom: 44px
    }
  }

  .footer-row {
    display: flex
  }

  @media (max-width:1279px) {
    .footer-row {
      flex-wrap: wrap
    }
  }

  .footer-row:not(:last-child) {
    margin-bottom: 32px
  }

  @media (max-width:1279px) {
    .footer-row:not(:last-child) {
      margin-bottom: 44px
    }
  }

  .breadcrumbs {
    font-size: 12px;
    margin-bottom: 24px
  }

  @media only screen and (min-width:1200px) {
    .breadcrumbs {
      margin-top: 40px
    }
  }

  .breadcrumbs__list {
    line-height: normal;
    list-style: none;
    margin: 0;
    padding: 16px 0
  }

  @media only screen and (min-width:1200px) {
    .breadcrumbs__list {
      padding: 0 0 16px
    }
  }

  @media only screen and (max-width:767px) {
    .breadcrumbs__list {
      overflow-x: auto;
      padding-bottom: 12px !important;
      white-space: nowrap
    }
  }

  .breadcrumbs__item {
    display: inline-block;
    margin-left: 0
  }

  .breadcrumbs__item-link {
    color: #717171;
    text-decoration: none
  }

  .breadcrumbs__item:last-child .breadcrumbs__item-link {
    pointer-events: none
  }

  .breadcrumbs__item:last-child .breadcrumbs__item-link span {
    cursor: default;
    display: inline-block
  }

  .breadcrumbs__icon {
    color: #717171;
    display: inline-block;
    font-size: 10px;
    padding: 0 8px;
    transform: translateY(1px)
  }

  .breadcrumbs.last-link-active .breadcrumbs__item:last-child .breadcrumbs__item-link {
    pointer-events: inherit
  }

  .breadcrumbs.last-link-active .breadcrumbs__item:last-child .breadcrumbs__item-link span {
    cursor: inherit
  }

  .microsite .header-container {
    height: calc(100% - 24px)
  }

  @media (max-width:767px) {
    .microsite .header-logo-svg {
      display: none
    }

    .microsite .header-logo-img-small {
      display: block
    }
  }

  @media (max-width:1279px) {
    .microsite .header-global-line {
      display: block
    }
  }

  .waypoint .fade-in,
  .waypoint .step-in,
  .waypoint .step-in-left,
  .waypoint .step-in-right,
  .waypoint .step-up {
    opacity: 0
  }

  .waypoint .step-in-left {
    transform: translateX(-100px)
  }

  .waypoint .step-in-right {
    transform: translateX(100px)
  }

  .waypoint .step-out-left,
  .waypoint .step-out-right {
    opacity: 1;
    transform: translateX(0)
  }

  .waypoint .step-up {
    transform: translateY(50px)
  }

  .waypoint .step-up-fast {
    transform: translateY(20px)
  }

  .waypoint .step-down {
    transform: translateY(50px)
  }

  .waypoint .zoom-in {
    transform: scale(.2)
  }

  .waypoint.waypoint-reveal .step-in {
    transition: opacity .2s linear, transform .2s ease
  }

  .waypoint.waypoint-reveal .step-in-left,
  .waypoint.waypoint-reveal .step-in-right,
  .waypoint.waypoint-reveal .step-up {
    opacity: 1;
    transition: opacity .2s linear, transform .2s ease
  }

  .waypoint.waypoint-reveal .fade-in {
    opacity: 1;
    transition: opacity .2s linear, transform .2s ease;
    transition: opacity 1s ease
  }

  .waypoint.waypoint-reveal .step-in {
    opacity: 1;
    transition: opacity .5s ease
  }

  .waypoint.waypoint-reveal .step-in-left,
  .waypoint.waypoint-reveal .step-in-right {
    transform: translate(0);
    transition: opacity 1s cubic-bezier(.23, 1, .32, 1) .5s, transform 2s cubic-bezier(.23, 1, .32, 1)
  }

  .waypoint.waypoint-reveal .step-out-right {
    transform: translate(50px);
    transition: opacity .2s linear, transform .2s ease
  }

  .waypoint.waypoint-reveal .step-out-left {
    opacity: 0;
    transform: translate(-50px);
    transition: opacity .2s linear, transform .2s ease
  }

  .waypoint.waypoint-reveal .step-down,
  .waypoint.waypoint-reveal .step-up,
  .waypoint.waypoint-reveal .step-up-fast {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s, transform 1s
  }

  .waypoint.waypoint-reveal .zoom-in {
    opacity: 1;
    transform: scale(1);
    transition: opacity .2s linear, transform .2s cubic-bezier(.175, .285, .32, 1.275)
  }

  .waypoint .step-in:first-child {
    transition-delay: .1s
  }

  .waypoint .step-in:nth-child(2) {
    transition-delay: .2s
  }

  .waypoint .step-in:nth-child(3) {
    transition-delay: .3s
  }

  .waypoint .step-in:nth-child(4) {
    transition-delay: .4s
  }

  .waypoint .step-in:nth-child(5) {
    transition-delay: .5s
  }

  .waypoint .step-in:nth-child(6) {
    transition-delay: .6s
  }

  .waypoint .step-in:nth-child(7) {
    transition-delay: .7s
  }

  .waypoint .step-in:nth-child(8) {
    transition-delay: .8s
  }

  .waypoint .step-in:nth-child(9) {
    transition-delay: .9s
  }

  .waypoint .step-in:nth-child(10) {
    transition-delay: 1s
  }

  .waypoint .step-in:nth-child(11) {
    transition-delay: 1.1s
  }

  .waypoint .step-in:nth-child(12) {
    transition-delay: 1.2s
  }

  .waypoint .step-in:nth-child(13) {
    transition-delay: 1.3s
  }

  .waypoint .step-in:nth-child(14) {
    transition-delay: 1.4s
  }

  .waypoint .step-in:nth-child(15) {
    transition-delay: 1.5s
  }

  .waypoint .step-in:nth-child(16) {
    transition-delay: 1.6s
  }

  .waypoint .step-in:nth-child(17) {
    transition-delay: 1.7s
  }

  .waypoint .step-in:nth-child(18) {
    transition-delay: 1.8s
  }

  .waypoint .step-in:nth-child(19) {
    transition-delay: 1.9s
  }

  .waypoint .step-in:nth-child(20) {
    transition-delay: 2s
  }

  .waypoint .step-in:nth-child(21) {
    transition-delay: 2.1s
  }

  .waypoint .step-in:nth-child(22) {
    transition-delay: 2.2s
  }

  .waypoint .step-in:nth-child(23) {
    transition-delay: 2.3s
  }

  .waypoint .step-in:nth-child(24) {
    transition-delay: 2.4s
  }

  .waypoint .step-in:nth-child(25) {
    transition-delay: 2.5s
  }

  .waypoint .step-in:nth-child(26) {
    transition-delay: 2.6s
  }

  .waypoint .step-in:nth-child(27) {
    transition-delay: 2.7s
  }

  .waypoint .step-in:nth-child(28) {
    transition-delay: 2.8s
  }

  .waypoint .step-in:nth-child(29) {
    transition-delay: 2.9s
  }

  .waypoint .step-in:nth-child(30) {
    transition-delay: 3s
  }

  .waypoint .step-in:nth-child(31) {
    transition-delay: 3.1s
  }

  .waypoint .step-in-left:first-child {
    transition-delay: .1s
  }

  .waypoint .step-in-left:nth-child(2) {
    transition-delay: .2s
  }

  .waypoint .step-in-left:nth-child(3) {
    transition-delay: .3s
  }

  .waypoint .step-in-left:nth-child(4) {
    transition-delay: .4s
  }

  .waypoint .step-in-left:nth-child(5) {
    transition-delay: .5s
  }

  .waypoint .step-in-left:nth-child(6) {
    transition-delay: .6s
  }

  .waypoint .step-in-left:nth-child(7) {
    transition-delay: .7s
  }

  .waypoint .step-in-left:nth-child(8) {
    transition-delay: .8s
  }

  .waypoint .step-in-left:nth-child(9) {
    transition-delay: .9s
  }

  .waypoint .step-in-left:nth-child(10) {
    transition-delay: 1s
  }

  .waypoint .step-in-left:nth-child(11) {
    transition-delay: 1.1s
  }

  .waypoint .step-in-left:nth-child(12) {
    transition-delay: 1.2s
  }

  .waypoint .step-in-left:nth-child(13) {
    transition-delay: 1.3s
  }

  .waypoint .step-in-left:nth-child(14) {
    transition-delay: 1.4s
  }

  .waypoint .step-in-left:nth-child(15) {
    transition-delay: 1.5s
  }

  .waypoint .step-in-left:nth-child(16) {
    transition-delay: 1.6s
  }

  .waypoint .step-in-left:nth-child(17) {
    transition-delay: 1.7s
  }

  .waypoint .step-in-left:nth-child(18) {
    transition-delay: 1.8s
  }

  .waypoint .step-in-left:nth-child(19) {
    transition-delay: 1.9s
  }

  .waypoint .step-in-left:nth-child(20) {
    transition-delay: 2s
  }

  .waypoint .step-in-left:nth-child(21) {
    transition-delay: 2.1s
  }

  .waypoint .step-in-left:nth-child(22) {
    transition-delay: 2.2s
  }

  .waypoint .step-in-left:nth-child(23) {
    transition-delay: 2.3s
  }

  .waypoint .step-in-left:nth-child(24) {
    transition-delay: 2.4s
  }

  .waypoint .step-in-left:nth-child(25) {
    transition-delay: 2.5s
  }

  .waypoint .step-in-left:nth-child(26) {
    transition-delay: 2.6s
  }

  .waypoint .step-in-left:nth-child(27) {
    transition-delay: 2.7s
  }

  .waypoint .step-in-left:nth-child(28) {
    transition-delay: 2.8s
  }

  .waypoint .step-in-left:nth-child(29) {
    transition-delay: 2.9s
  }

  .waypoint .step-in-left:nth-child(30) {
    transition-delay: 3s
  }

  .waypoint .step-in-left:nth-child(31) {
    transition-delay: 3.1s
  }

  .waypoint .step-in-right:first-child {
    transition-delay: .1s
  }

  .waypoint .step-in-right:nth-child(2) {
    transition-delay: .2s
  }

  .waypoint .step-in-right:nth-child(3) {
    transition-delay: .3s
  }

  .waypoint .step-in-right:nth-child(4) {
    transition-delay: .4s
  }

  .waypoint .step-in-right:nth-child(5) {
    transition-delay: .5s
  }

  .waypoint .step-in-right:nth-child(6) {
    transition-delay: .6s
  }

  .waypoint .step-in-right:nth-child(7) {
    transition-delay: .7s
  }

  .waypoint .step-in-right:nth-child(8) {
    transition-delay: .8s
  }

  .waypoint .step-in-right:nth-child(9) {
    transition-delay: .9s
  }

  .waypoint .step-in-right:nth-child(10) {
    transition-delay: 1s
  }

  .waypoint .step-in-right:nth-child(11) {
    transition-delay: 1.1s
  }

  .waypoint .step-in-right:nth-child(12) {
    transition-delay: 1.2s
  }

  .waypoint .step-in-right:nth-child(13) {
    transition-delay: 1.3s
  }

  .waypoint .step-in-right:nth-child(14) {
    transition-delay: 1.4s
  }

  .waypoint .step-in-right:nth-child(15) {
    transition-delay: 1.5s
  }

  .waypoint .step-in-right:nth-child(16) {
    transition-delay: 1.6s
  }

  .waypoint .step-in-right:nth-child(17) {
    transition-delay: 1.7s
  }

  .waypoint .step-in-right:nth-child(18) {
    transition-delay: 1.8s
  }

  .waypoint .step-in-right:nth-child(19) {
    transition-delay: 1.9s
  }

  .waypoint .step-in-right:nth-child(20) {
    transition-delay: 2s
  }

  .waypoint .step-in-right:nth-child(21) {
    transition-delay: 2.1s
  }

  .waypoint .step-in-right:nth-child(22) {
    transition-delay: 2.2s
  }

  .waypoint .step-in-right:nth-child(23) {
    transition-delay: 2.3s
  }

  .waypoint .step-in-right:nth-child(24) {
    transition-delay: 2.4s
  }

  .waypoint .step-in-right:nth-child(25) {
    transition-delay: 2.5s
  }

  .waypoint .step-in-right:nth-child(26) {
    transition-delay: 2.6s
  }

  .waypoint .step-in-right:nth-child(27) {
    transition-delay: 2.7s
  }

  .waypoint .step-in-right:nth-child(28) {
    transition-delay: 2.8s
  }

  .waypoint .step-in-right:nth-child(29) {
    transition-delay: 2.9s
  }

  .waypoint .step-in-right:nth-child(30) {
    transition-delay: 3s
  }

  .waypoint .step-in-right:nth-child(31) {
    transition-delay: 3.1s
  }

  .waypoint .step-up:first-child {
    transition-delay: .1s
  }

  .waypoint .step-up:nth-child(2) {
    transition-delay: .2s
  }

  .waypoint .step-up:nth-child(3) {
    transition-delay: .3s
  }

  .waypoint .step-up:nth-child(4) {
    transition-delay: .4s
  }

  .waypoint .step-up:nth-child(5) {
    transition-delay: .5s
  }

  .waypoint .step-up:nth-child(6) {
    transition-delay: .6s
  }

  .waypoint .step-up:nth-child(7) {
    transition-delay: .7s
  }

  .waypoint .step-up:nth-child(8) {
    transition-delay: .8s
  }

  .waypoint .step-up:nth-child(9) {
    transition-delay: .9s
  }

  .waypoint .step-up:nth-child(10) {
    transition-delay: 1s
  }

  .waypoint .step-up:nth-child(11) {
    transition-delay: 1.1s
  }

  .waypoint .step-up:nth-child(12) {
    transition-delay: 1.2s
  }

  .waypoint .step-up:nth-child(13) {
    transition-delay: 1.3s
  }

  .waypoint .step-up:nth-child(14) {
    transition-delay: 1.4s
  }

  .waypoint .step-up:nth-child(15) {
    transition-delay: 1.5s
  }

  .waypoint .step-up:nth-child(16) {
    transition-delay: 1.6s
  }

  .waypoint .step-up:nth-child(17) {
    transition-delay: 1.7s
  }

  .waypoint .step-up:nth-child(18) {
    transition-delay: 1.8s
  }

  .waypoint .step-up:nth-child(19) {
    transition-delay: 1.9s
  }

  .waypoint .step-up:nth-child(20) {
    transition-delay: 2s
  }

  .waypoint .step-up:nth-child(21) {
    transition-delay: 2.1s
  }

  .waypoint .step-up:nth-child(22) {
    transition-delay: 2.2s
  }

  .waypoint .step-up:nth-child(23) {
    transition-delay: 2.3s
  }

  .waypoint .step-up:nth-child(24) {
    transition-delay: 2.4s
  }

  .waypoint .step-up:nth-child(25) {
    transition-delay: 2.5s
  }

  .waypoint .step-up:nth-child(26) {
    transition-delay: 2.6s
  }

  .waypoint .step-up:nth-child(27) {
    transition-delay: 2.7s
  }

  .waypoint .step-up:nth-child(28) {
    transition-delay: 2.8s
  }

  .waypoint .step-up:nth-child(29) {
    transition-delay: 2.9s
  }

  .waypoint .step-up:nth-child(30) {
    transition-delay: 3s
  }

  .waypoint .step-up:nth-child(31) {
    transition-delay: 3.1s
  }

  .waypoint .fade-in:first-child {
    transition-delay: .1s
  }

  .waypoint .fade-in:nth-child(2) {
    transition-delay: .2s
  }

  .waypoint .fade-in:nth-child(3) {
    transition-delay: .3s
  }

  .waypoint .fade-in:nth-child(4) {
    transition-delay: .4s
  }

  .waypoint .fade-in:nth-child(5) {
    transition-delay: .5s
  }

  .waypoint .fade-in:nth-child(6) {
    transition-delay: .6s
  }

  .waypoint .fade-in:nth-child(7) {
    transition-delay: .7s
  }

  .waypoint .fade-in:nth-child(8) {
    transition-delay: .8s
  }

  .waypoint .fade-in:nth-child(9) {
    transition-delay: .9s
  }

  .waypoint .fade-in:nth-child(10) {
    transition-delay: 1s
  }

  .waypoint .fade-in:nth-child(11) {
    transition-delay: 1.1s
  }

  .waypoint .fade-in:nth-child(12) {
    transition-delay: 1.2s
  }

  .waypoint .fade-in:nth-child(13) {
    transition-delay: 1.3s
  }

  .waypoint .fade-in:nth-child(14) {
    transition-delay: 1.4s
  }

  .waypoint .fade-in:nth-child(15) {
    transition-delay: 1.5s
  }

  .waypoint .fade-in:nth-child(16) {
    transition-delay: 1.6s
  }

  .waypoint .fade-in:nth-child(17) {
    transition-delay: 1.7s
  }

  .waypoint .fade-in:nth-child(18) {
    transition-delay: 1.8s
  }

  .waypoint .fade-in:nth-child(19) {
    transition-delay: 1.9s
  }

  .waypoint .fade-in:nth-child(20) {
    transition-delay: 2s
  }

  .waypoint .fade-in:nth-child(21) {
    transition-delay: 2.1s
  }

  .waypoint .fade-in:nth-child(22) {
    transition-delay: 2.2s
  }

  .waypoint .fade-in:nth-child(23) {
    transition-delay: 2.3s
  }

  .waypoint .fade-in:nth-child(24) {
    transition-delay: 2.4s
  }

  .waypoint .fade-in:nth-child(25) {
    transition-delay: 2.5s
  }

  .waypoint .fade-in:nth-child(26) {
    transition-delay: 2.6s
  }

  .waypoint .fade-in:nth-child(27) {
    transition-delay: 2.7s
  }

  .waypoint .fade-in:nth-child(28) {
    transition-delay: 2.8s
  }

  .waypoint .fade-in:nth-child(29) {
    transition-delay: 2.9s
  }

  .waypoint .fade-in:nth-child(30) {
    transition-delay: 3s
  }

  .waypoint .fade-in:nth-child(31) {
    transition-delay: 3.1s
  }

  [data-simplebar] {
    position: relative
  }

  .simplebar-wrapper {
    height: inherit;
    max-height: inherit;
    max-width: inherit;
    overflow: hidden;
    width: inherit
  }

  .simplebar-mask {
    direction: inherit;
    height: auto !important;
    overflow: hidden;
    width: auto !important;
    z-index: 0
  }

  .simplebar-mask,
  .simplebar-offset {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0
  }

  .simplebar-offset {
    -webkit-overflow-scrolling: touch;
    box-sizing: inherit !important;
    direction: inherit !important;
    resize: none !important
  }

  .simplebar-content-wrapper {
    box-sizing: border-box !important;
    direction: inherit;
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    position: relative;
    visibility: visible;
    width: auto
  }

  .simplebar-content:after,
  .simplebar-content:before {
    content: " ";
    display: table
  }

  .simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    width: 100%
  }

  .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    flex-basis: 0;
    flex-grow: inherit;
    flex-shrink: 0;
    float: left;
    height: 100%;
    margin: 0;
    max-height: 1px;
    max-width: 1px;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: -1
  }

  .simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    height: 1000%;
    left: 0;
    min-height: 1px;
    min-width: 1px;
    opacity: 0;
    top: 0;
    width: 1000%;
    z-index: -1
  }

  .simplebar-height-auto-observer,
  .simplebar-track {
    overflow: hidden;
    pointer-events: none;
    position: absolute
  }

  .simplebar-track {
    bottom: 0;
    right: 0;
    z-index: 1
  }

  [data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none
  }

  .simplebar-scrollbar {
    min-height: 10px;
    position: absolute;
    right: 2px;
    width: 7px
  }

  .simplebar-scrollbar:before {
    background: #000;
    border-radius: 7px;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity .2s linear
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    transition: opacity 0s linear
  }

  [data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    left: 0;
    right: auto
  }

  [data-simplebar] {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start
  }

  [data-simplebar] .simplebar-wrapper {
    height: inherit;
    max-height: inherit;
    max-width: inherit;
    overflow: hidden;
    width: inherit
  }

  [data-simplebar] .simplebar-mask {
    bottom: 0;
    direction: inherit;
    height: auto !important;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto !important;
    z-index: 0
  }

  [data-simplebar] .simplebar-offset {
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    box-sizing: inherit !important;
    direction: inherit !important;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    resize: none !important;
    right: 0;
    top: 0
  }

  [data-simplebar] .simplebar-content:after,
  [data-simplebar] .simplebar-content:before {
    content: " ";
    display: table
  }

  [data-simplebar] .simplebar-content-wrapper {
    box-sizing: border-box !important;
    direction: inherit;
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    position: relative;
    visibility: visible;
    width: auto
  }

  [data-simplebar] .simplebar-placeholder {
    height: unset !important;
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    width: 100%
  }

  [data-simplebar] .simplebar-height-auto-observer {
    bottom: 0;
    box-sizing: inherit;
    display: block;
    height: 1000%;
    left: 0;
    min-height: 1px;
    min-width: 1px;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 1000%;
    z-index: -1
  }

  [data-simplebar] .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit;
    flex-basis: 0;
    flex-grow: inherit;
    flex-shrink: 0;
    float: left;
    height: 100%;
    margin: 0;
    max-height: 1px;
    max-width: 1px;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: -1
  }

  [data-simplebar] .simplebar-track {
    bottom: 0;
    left: unset;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
  }

  [data-simplebar] .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: .1;
    transition: opacity 0s linear
  }

  [data-simplebar] .simplebar-scrollbar {
    min-height: 10px;
    position: absolute;
    right: 2px;
    width: 7px
  }

  [data-simplebar] .simplebar-scrollbar:before {
    background: #000;
    border-radius: 7px;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    right: -2px;
    top: 0;
    transition: opacity .2s linear
  }

  [data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
  }

  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    bottom: 2px;
    top: 2px
  }

  .simplebar-track.simplebar-horizontal {
    height: 11px;
    left: 0
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 7px;
    left: 0;
    min-height: 0;
    min-width: 10px;
    right: auto;
    top: 2px;
    width: auto
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px
  }

  [data-simplebar-direction=rtl].simplebar-track.simplebar-vertical {
    left: 0;
    right: auto
  }

  .hs-dummy-scrollbar-size {
    direction: rtl;
    height: 500px;
    opacity: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    position: fixed;
    visibility: hidden;
    width: 500px
  }

  .gdpr-wrapper {
    margin: 12px 0
  }

  .gdpr-description {
    color: #717171;
    font-size: 12px;
    line-height: 1.17
  }

  .gdpr-description p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 8px
  }

  .gdpr-description p:last-child {
    margin-bottom: 0
  }

  .gdpr-description a:not(:hover) {
    text-decoration-color: #717171
  }

  .form-element-label {
    background-image: linear-gradient(0deg, transparent 7px, #fff 0);
    color: #717171;
    cursor: text;
    display: block;
    font-size: 12px;
    left: 8px;
    line-height: 1.2;
    margin-bottom: 0;
    opacity: 0;
    padding: 0 4px;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-out, transform .2s ease-out, color .1s ease-in-out
  }

  .form-element-error {
    color: #ff0202;
    display: inline-block;
    font-size: 12px;
    line-height: 1.5;
    margin: 4px 0 0
  }

  .form-input {
    border: 1px solid #cdd3d8;
    border-radius: 3px !important;
    color: #464749;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 12px;
    transition: border-color .1s ease-in-out
  }

  .form-input-wrapper {
    position: relative
  }

  .form-input-wrapper.has-error .form-input {
    border-color: #ff0202
  }

  .form-input-wrapper.has-error .form-element-label {
    color: #ff0202
  }

  .form-input::placeholder {
    color: #717171;
    font-size: 14px
  }

  .form-input:focus {
    border-color: #005eb8 !important
  }

  .form-input:focus~.form-element-label {
    color: #005eb8 !important
  }

  .form-input:not(:placeholder-shown)~.form-element-label,
  .form-input:required:valid~.form-element-label {
    opacity: 1;
    transform: translateY(-6px)
  }

  .form-textarea {
    border: 1px solid #cdd3d8;
    color: #464749;
    font-size: 16px;
    line-height: 1.63;
    padding: 12px;
    transition: border-color .1s ease-in-out
  }

  .form-textarea-wrapper {
    line-height: 0;
    position: relative
  }

  .form-textarea-wrapper.has-error .form-textarea {
    border-color: #ff0202
  }

  .form-textarea-wrapper.has-error .form-element-label {
    color: #ff0202
  }

  .form-textarea::placeholder {
    color: #717171;
    font-size: 14px;
    line-height: 1.43
  }

  .form-textarea:focus {
    border-color: #005eb8 !important
  }

  .form-textarea:focus~.form-element-label,
  .form-textarea:focus~textarea~.form-element-label {
    color: #005eb8 !important
  }

  .form-textarea:not(:placeholder-shown)~.form-element-label,
  .form-textarea:not(:placeholder-shown)~textarea~.form-element-label,
  .form-textarea:required:valid~.form-element-label,
  .form-textarea:required:valid~textarea~.form-element-label {
    opacity: 1;
    transform: translateY(-6px)
  }

  .form-checkbox-wrapper {
    margin-bottom: 4px
  }

  .form-checkbox-wrapper.has-error .form-checkbox-checkmark {
    border-color: #ff0202
  }

  .form-checkbox-label {
    color: #464749;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 1.43;
    position: relative;
    user-select: auto
  }

  .form-checkbox-label:hover .form-checkbox-checkmark {
    border: 1px solid transparent
  }

  .form-checkbox-label:hover .form-checkbox-checkmark:after {
    opacity: .65
  }

  .form-checkbox-label p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0
  }

  .form-checkbox-label input[type=checkbox] {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0
  }

  .form-checkbox-label .form-checkbox-checkmark {
    border: 1px solid #cdd3d8;
    border-radius: 3px;
    display: inline-block;
    flex: 0 0 auto;
    height: 16px;
    margin: 1px 16px 0 0;
    position: relative;
    transition: border .14s ease;
    width: 16px
  }

  .form-checkbox-label .form-checkbox-checkmark:after {
    background-image: url('../../../images/app/checkbox-checkmark.svg');
    border: none;
    border-radius: 3px;
    content: "";
    display: block;
    height: 16px;
    left: -1px;
    opacity: 0;
    position: absolute;
    top: -1px;
    transform: none;
    transition: opacity .14s ease;
    width: 16px
  }

  .form-checkbox-label input:checked~.form-checkbox-checkmark:after {
    background-color: #005eb8;
    opacity: 1
  }

  .form-upload-input-wrapper {
    border: 1px dashed transparent;
    padding: 8px 1px;
    transition: all .45s ease
  }

  .form-upload-input-wrapper.dragover {
    background-color: #f2f9fe;
    border: 1px dashed #115689
  }

  .form-upload-input-wrapper>.form-element-error {
    font-size: 13px
  }

  .form-upload-input-row {
    align-items: center;
    display: flex
  }

  .form-upload-img {
    display: inline-block;
    margin-right: 10px
  }

  .form-upload-text {
    color: #666c73;
    display: inline-block;
    font-size: 14px;
    line-height: 1.43;
    margin: 0 6px 0 0
  }

  .form-upload-text label {
    color: #005eb8;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.43;
    margin: 0
  }

  .form-upload-input {
    display: none
  }

  .form-upload-input-file {
    color: #005eb8;
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 6px
  }

  .form-upload-input-file:last-child {
    margin-bottom: 10px
  }

  .form-upload-input-file.has-error {
    color: #ff0202
  }

  .form-upload-input-file button {
    background-color: #ebf7ff;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin: 0 6px;
    position: relative;
    vertical-align: middle;
    width: 16px
  }

  .form-upload-input-file button:after {
    color: #666c73;
    content: "\00d7";
    display: block;
    font-size: 13px;
    left: calc(50% - 4px);
    line-height: 1;
    position: absolute;
    top: calc(50% - 7px)
  }

  .form-upload-input-file .form-element-error {
    display: inline-block;
    margin: 0
  }

  .tooltip {
    display: inline-block !important;
    height: 16px;
    position: relative;
    width: 16px
  }

  .tooltip:before {
    border-radius: 100%;
    bottom: -3px;
    content: "";
    cursor: pointer;
    display: block;
    left: -3px;
    position: absolute;
    right: -3px;
    top: -3px
  }

  .tooltip:hover>span {
    background-color: #3b3c3d;
    color: #fff
  }

  .tooltip:hover .tooltip-content,
  .tooltip:hover .tooltip-ledge {
    opacity: 1;
    transition: opacity .16s ease-in-out, visibility 0s 0s;
    visibility: visible
  }

  .tooltip>span {
    background-color: #ebf7ff;
    border-radius: 100%;
    color: #666c73;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 16px;
    left: 0;
    line-height: 1;
    padding-top: 2px;
    position: absolute;
    text-align: center;
    top: 0;
    transition: color .16s ease-in-out, background-color .16s ease-in-out;
    width: 16px
  }

  .tooltip-ledge {
    border-color: transparent transparent #3b3c3d;
    border-style: solid;
    border-width: 0 3.5px 3px;
    height: 0;
    top: calc(100% + 2.5px);
    width: 0
  }

  .tooltip-content,
  .tooltip-ledge {
    cursor: default;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity .16s ease-in-out, visibility 0s .16s;
    visibility: hidden;
    z-index: 2
  }

  .tooltip-content {
    background-color: #3b3c3d;
    border-radius: 3px;
    padding: 12px 24px;
    top: calc(100% + 5px);
    width: 500px
  }

  .tooltip-content:hover,
  .tooltip-content:hover~.tooltip-ledge {
    opacity: 0 !important;
    transition: opacity .16s ease-in-out, visibility 0s .16s !important;
    visibility: hidden !important
  }

  .tooltip-content p {
    color: #fff;
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 5px
  }

  .tooltip-content p:last-child {
    margin-bottom: 0
  }

  .tooltip.top .tooltip-content {
    bottom: calc(100% + 5px);
    top: unset
  }

  .tooltip.top .tooltip-ledge {
    border-color: #3b3c3d transparent transparent;
    border-width: 3px 3.5px 0;
    bottom: calc(100% + 2.5px);
    top: unset
  }














  .page-main {
    text-rendering: optimizeSpeed;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    color: #3b3c3d;
    flex: 1;
    padding-top: 0!important;
  }
  .home-page {
    text-rendering: optimizeSpeed;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    color: #3b3c3d;
    box-sizing: border-box;
  }
  .area_content {
    text-rendering: optimizeSpeed;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    color: #3b3c3d;
    box-sizing: border-box;
  }
  .sb-wrapper{
    text-rendering: optimizeSpeed;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    color: #3b3c3d;
    box-sizing: border-box;
    height: 100vh;
    margin-bottom: 68px;
    position: relative;
    width: 100%;
  }

  








  // ========================== tooltip
  .tooltip-wrapper {
    margin-left: auto;
    margin-right: 10px
}

.tooltip-wrapper .tooltip-message {
    align-items: flex-start;
    background: #fff;
    border: 1px solid #ccc;
    bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
    color: #6c6e70;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: auto;
    justify-content: flex-start;
    letter-spacing: -.34px;
    line-height: 1.33;
    margin: 0 0 43px;
    max-width: 220px;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: left;
    transform: scale(0);
    transform-origin: bottom center;
    transition: transform .3s ease;
    visibility: hidden;
    z-index: 15
}

.tooltip-wrapper .tooltip-message p {
    color: #6c6e70;
    font-size: 12px;
    letter-spacing: -.34px;
    line-height: 1.33;
    margin-bottom: 0
}

.tooltip-wrapper .tooltip-message:before {
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 6px;
    bottom: -1px;
    box-shadow: -2px 2px 2px -1px rgba(0,0,0,.2);
    box-sizing: border-box;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: rotate(-45deg) translateX(-50%);
    width: 0
}

//=============blog
.a-section__description {
  font-size: 18px;
  line-height: 2
}

@media only screen and (max-width: 1023px) {
  .a-section__description {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-section__description {
      font-size:14px;
      line-height: 1.71
  }
}

.a-card {
  background-color: #fff;
  cursor: pointer;
  position: relative
}

.a-card>*>* {
  z-index: 1
}

.a-card:after {
  border-bottom: 4px solid #fff;
  bottom: 0;
  box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow .3s ease-in-out,border-color .3s ease-in-out
}

.a-card__title {
  color: #222;
  font-size: 22px;
  font-weight: 700
}

@media only screen and (min-width: 1023px) {
  .a-card__title {
      line-height:1.27
  }
}

@media only screen and (min-width: 1281px) {
  .a-card__title {
      font-size:24px;
      line-height: 1.17
  }
}

@media only screen and (max-width: 768px) {
  .a-card__title {
      line-height:normal
  }
}

.a-card__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-card__link,.a-card__link:active,.a-card__link:visited {
  color: #005eb8
}

.a-card__link i {
  font-size: 12px;
  margin-left: 10px
}

@media only screen and (min-width: 769px) {
  .a-card:hover:after {
      border-bottom-color:#005eb8;
      box-shadow: 0 2px 30px 0 rgba(0,0,0,.2)
  }

  .a-card:hover .btn-link-arrow--right:after {
      animation: slide-left 1s ease infinite
  }

  .a-card:hover .btn-link-arrow--hide-label:after {
      animation: slide-left 1s ease-in-out .5s infinite
  }

  .a-card:hover .btn-link-arrow--hide-label .btn__label {
      transform: translateX(0)
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .a-card--blog.no-image {
      padding-left:20px
  }

  .a-card--blog.no-image .a-blog__list-content {
      max-width: 100%
  }
}

.a-card--blog:before {
  background-color: #005eb8;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out
}

.a-card--blog:after {
  border-bottom: none;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1)
}

.a-card--blog:hover:before {
  right: 0
}

.a-card--blog:hover:after {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,.2)
}

@keyframes slide-left {
  0%,to {
      transform: translate(0)
  }

  50% {
      transform: translate(-5px)
  }
}

.bx-wrapper .bx-pager {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 0
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
  margin-bottom: 20px
}

.bx-wrapper .bx-pager-link {
  background: #bfc3c7;
  cursor: pointer;
  display: block;
  height: 2px;
  margin: 0 5px;
  width: 20px
}

.bx-wrapper .bx-pager-link.active {
  background: #f59000
}

.bx-clone-h3 {
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: normal
}

.page-main .a-blog__list-categories {
  font-size: 12px;
  margin-bottom: 0
}

.page-main .a-blog__list-description {
  font-size: 14px
}

.a-blog {
  color: #222;
  padding: 50px 0
}

.a-blog.colored-background {
  background-color: #fafafa
}

.a-blog__list {
  align-items: stretch;
  list-style: none;
  padding: 25px 0 0;
  position: relative
}

.a-blog__list-item {
  flex-basis: 100%
}

.a-blog__list-content {
  margin-top: 10px
}

.a-blog__list-content .a-card__title {
  font-size: 18px;
  line-height: normal;
  margin: 10px 0
}

.a-blog__list-icon {
  height: 164px;
  justify-content: center;
  overflow: hidden;
  width: auto
}

.a-blog__list-icon img {
  height: 100%;
  margin: 0 auto;
  max-width: 100%
}

@media only screen and (min-width: 769px) {
  .a-blog__list-icon {
      height:158px;
      max-width: 100%
  }
}

@media only screen and (min-width: 1281px) {
  .a-blog__list-icon {
      height:183px
  }
}

@media only screen and (min-width: 1441px) {
  .a-blog__list-icon {
      height:218px
  }
}

.a-blog__list-categories {
  color: #f59000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase
}

.a-blog__list-description {
  color: #333;
  font-size: 14px;
  line-height: 1.71;
  margin-bottom: 10px
}

.a-blog__list-footer {
  margin-top: auto;
  text-align: right;
  width: 100%
}

.a-blog__button {
  height: 100%;
  margin-top: 50px;
  width: 100%
}

@media only screen and (max-width: 767px) {
  .a-blog__button {
      margin-top:30px
  }
}

@media only screen and (min-width: 768px) {
  .a-blog__list-item {
      padding:10px 10px 20px
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .a-blog__list-item {
      margin-bottom:30px;
      padding: 20px 20px 22px 11px
  }

  .a-blog__list-item:last-of-type {
      margin-bottom: 0
  }

  .a-blog__list-item>a {
      display: flex
  }

  .a-blog__list-content {
      max-width: calc(100% - 220px)
  }

  .a-blog__list-icon {
      margin: auto 34px auto 0;
      width: 220px
  }

  .a-blog__list-icon img {
      height: auto;
      width: 100%
  }
}

@media only screen and (min-width: 1024px) {
  .a-blog__list {
      display:flex
  }

  .a-blog__list-item {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      padding: 17px 20px 18px
  }

  .a-blog__list-item:last-of-type {
      margin-right: 0
  }
}

@media only screen and (min-width: 1441px) {
  .a-blog__list-item {
      padding:20px 30px
  }

  .a-blog__list-content {
      margin-top: 20px
  }

  .a-blog__list-content .a-card__title {
      margin: 20px 0
  }
}

@media only screen and (max-width: 767px) {
  .a-blog {
      padding:30px 0 50px
  }

  .a-blog .bx-wrapper {
      max-width: 100%!important
  }

  .a-blog__list {
      height: 100%;
      margin: 30px 0 0;
      padding: 0
  }

  .a-blog__list-item {
      margin-left: 0;
      margin-top: 2px;
      padding: 20px
  }

  .a-blog__list-content {
      height: auto
  }

  .a-blog .bx-viewport {
      overflow: visible!important
  }

  .a-blog .bx-pager {
      align-items: center;
      margin-left: -8px;
      padding: 20px 0 0;
      text-align: center;
      width: 100vw
  }

  .a-blog .bx-pager-item {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      vertical-align: bottom
  }

  .a-blog .bx-pager-link {
      background: #bfc3c7;
      display: block;
      height: 2px;
      margin: 0 5px;
      outline: 0;
      text-indent: -9999px;
      width: 20px
  }

  .a-blog .bx-pager-link.active {
      background: #f59000
  }

  .a-blog [data-slider-item] {
      background: #fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      width: calc(100vw - 43px)!important
  }
}


//===================case studies
.page-main .a-case-studies__card-technologies p {
  align-items: baseline;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
  text-transform: uppercase;
  z-index: 1
}

@media only screen and (min-width: 769px) {
  .page-main .a-case-studies__card-technologies p {
      font-size:14px
  }
}

.page-main .a-case-studies__card-category {
  font-size: 12px;
  margin-bottom: 0
}

@media only screen and (min-width: 769px) {
  .page-main .a-case-studies__card-category {
      font-size:14px
  }
}

.page-main .a-case-studies__card-title {
  font-size: 18px;
  margin-bottom: 10px
}

@media only screen and (min-width: 1025px) {
  .page-main .a-case-studies__card-title {
      font-size:22px
  }
}

.page-main .a-case-studies__card:hover .a-case-studies__card-footer {
  line-height: 1
}

.page-main .a-case-studies__card-footer .btn-link-arrow:after {
  top: 2px
}

.a-case-studies {
  background: #fafafa;
  padding: 50px 0
}

.a-case-studies .hp-admin-link {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px
}

.a-case-studies__list {
  margin-top: 40px
}

@media only screen and (min-width: 1281px) {
  .a-case-studies__list .row {
      margin:0 -16px
  }
}

@media only screen and (max-width: 767px) {
  .a-case-studies__list {
      margin-top:30px
  }
}

.a-case-studies__item {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end
}

.a-case-studies .a-case-studies__card-wrapper {
  padding: 0 15px
}

@media only screen and (min-width: 768px) {
  .a-case-studies .a-case-studies__card-wrapper {
      padding:0 15px 30px;
      transition-delay: .75s!important
  }

  .a-case-studies .a-case-studies__card-wrapper:first-of-type {
      transition-delay: .15s!important
  }

  .a-case-studies .a-case-studies__card-wrapper:nth-of-type(2),.a-case-studies .a-case-studies__card-wrapper:nth-of-type(3) {
      transition-delay: .45s!important
  }
}

@media only screen and (min-width: 1200px) {
  .a-case-studies .a-case-studies__card-wrapper {
      transition-delay:.45s!important
  }

  .a-case-studies .a-case-studies__card-wrapper:nth-of-type(-n+2) {
      transition-delay: .15s!important
  }
}

@media only screen and (min-width: 1441px) {
  .a-case-studies .a-case-studies__card-wrapper {
      padding:0 16px 32px
  }
}

.a-case-studies__card {
  background: 50% no-repeat;
  background-size: cover;
  box-shadow: 0 2px 37px 0 rgba(0,0,0,.2);
  box-sizing: border-box;
  color: #222;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 165px;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
  transition: box-shadow .3s ease-in-out
}

@media only screen and (max-width: 767px) {
  .a-case-studies__card {
      margin-bottom:16px
  }
}

@media only screen and (min-width: 1024px) {
  .a-case-studies__card {
      height:260px
  }
}

@media only screen and (min-width: 1280px) {
  .a-case-studies__card {
      height:325px
  }
}

@media only screen and (min-width: 1440px) {
  .a-case-studies__card {
      height:335px
  }
}

@media only screen and (min-width: 1600px) {
  .a-case-studies__card {
      height:320px
  }
}

.a-case-studies__card:after {
  background-color: rgba(0,74,145,.6);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color .4s ease-in-out;
  width: 100%
}

.a-case-studies__card-title {
  animation: slide-bottom .4s ease-in-out forwards;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px;
  opacity: 1;
  order: 1;
  transform: translateY(20px);
  transition: transform .4s ease-in-out;
  z-index: 1
}

@media only screen and (min-width: 1025px) {
  .a-case-studies__card-title {
      font-size:22px;
      line-height: normal
  }
}

.a-case-studies__card-title:after {
  background-color: #ed8b00;
  content: "";
  display: block;
  height: 2px;
  margin: 10px 0 0;
  width: 40px
}

.a-case-studies__card-category {
  align-items: baseline;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.4;
  position: relative;
  text-transform: uppercase;
  z-index: 1
}

@media only screen and (min-width: 769px) {
  .a-case-studies__card-category {
      font-size:14px
  }
}

.a-case-studies__card-category i:before {
  align-items: baseline;
  display: flex;
  font-size: 11px;
  height: 14px
}

.a-case-studies__card-footer {
  opacity: 0;
  text-align: right
}

.a-case-studies__card-footer a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-case-studies__card-footer a,.a-case-studies__card-footer a:active,.a-case-studies__card-footer a:visited {
  color: #005eb8
}

.a-case-studies__card-footer a i {
  font-size: 12px;
  margin-left: 10px
}

@media only screen and (max-width: 1024px) {
  .a-case-studies__card-footer a {
      font-size:12px
  }
}

.a-case-studies__card-technologies {
  margin-top: 10px;
  opacity: 0;
  overflow: hidden
}

.a-case-studies__card-technologies p {
  align-items: baseline;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
  text-transform: uppercase;
  z-index: 1
}

@media only screen and (min-width: 769px) {
  .a-case-studies__card-technologies p {
      font-size:14px
  }
}

.a-case-studies__card .hover-content {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  position: absolute;
  transform: translateY(10%);
  transition: opacity .4s;
  visibility: hidden;
  z-index: 1
}

@media only screen and (min-width: 1024px) {
  .a-case-studies__card .hover-content {
      display:flex
  }
}

@media only screen and (min-width: 1280px) {
  .a-case-studies__card:hover {
      justify-content:space-between
  }

  .a-case-studies__card:hover:after {
      background-color: #fff
  }

  .a-case-studies__card:hover .hover-content {
      flex: 1;
      opacity: 1;
      order: 2;
      position: relative;
      transform: translateY(0);
      visibility: visible
  }

  .a-case-studies__card:hover .a-case-studies__item {
      justify-content: space-between
  }

  .a-case-studies__card:hover .a-case-studies__card-title {
      animation: slide-top .4s ease-in-out forwards;
      animation-delay: .2s;
      color: #222;
      opacity: 0;
      transform: translateY(20px)
  }

  .a-case-studies__card:hover .a-case-studies__card-category {
      opacity: 0;
      order: 0;
      position: absolute
  }

  .a-case-studies__card:hover .a-case-studies__card-technologies {
      height: auto;
      opacity: 1;
      transition: opacity .4s ease-in-out .2s
  }

  .a-case-studies__card:hover .a-case-studies__card-footer {
      opacity: 1;
      transition: opacity .4s ease-in-out .2s
  }

  .a-case-studies__card:hover .a-case-studies__card-footer .btn-link-arrow:after {
      animation: slide-left 1s ease infinite
  }
}

.a-case-studies .btn-default {
  margin-top: 20px
}

@media only screen and (min-width: 1281px) {
  .a-case-studies .btn-default {
      margin-top:18px
  }
}

@keyframes slide-left {
  0%,to {
      transform: translate(0)
  }

  50% {
      transform: translate(-5px)
  }
}

@keyframes slide-top {
  0% {
      opacity: 0;
      transform: translateY(20px)
  }

  to {
      opacity: 1;
      transform: translateY(0)
  }
}

@keyframes slide-bottom {
  0% {
      opacity: 0;
      transform: translateY(-20px)
  }

  to {
      opacity: 1;
      transform: translateY(0)
  }
}

//============clients
.partners {
  padding: 20px 0;
  text-align: center
}

.partners__text {
  font-size: 18px;
  line-height: 2;
  margin: 0 auto
}

@media only screen and (max-width: 1023px) {
  .partners__text {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .partners__text {
      font-size:14px;
      line-height: 1.71
  }
}

.partners__text p {
  line-height: 2;
  text-align: center
}

.partners__list {
  margin: 59px 0 50px
}

.partners__slider {
  height: 50px
}

.partners__item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto
}

.partners__item img {
  display: block;
  filter: grayscale(100);
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  opacity: .5;
  transform: none;
  transition: filter .3s ease;
  width: auto
}

.partners__item img:hover {
  filter: none;
  opacity: 1
}

@media only screen and (min-width: 768px) {
  .partners .bx-viewport,.partners .bx-wrapper {
      height:100%!important;
      left: 0;
      max-width: 100%!important;
      position: relative;
      top: 0;
      width: 100%
  }

  .partners__slider {
      height: 73px
  }
}

@media only screen and (min-width: 1025px) {
  .partners__list {
      margin-top:62px
  }
}

@media only screen and (max-width: 1024px) {
  .partners {
      padding:30px 0
  }
}

@media only screen and (max-width: 1023px) {
  .partners__list {
      margin-top:79px
  }
}

@media only screen and (max-width: 767px) {
  .partners__list {
      margin-top:50px;
      overflow: hidden
  }
}

//============collapsable
.cl-wrapper {
  margin-bottom: 30px;
  margin-top: 30px
}

.cl-description {
  color: #222;
  font-size: 18px;
  line-height: 1.78;
  margin: 20px 0
}

@media(max-width: 767px) {
  .cl-description {
      font-size:16px
  }
}

.cl-description p {
  color: inherit;
  font-size: inherit;
  margin-bottom: 12px
}

.cl-description p:last-child {
  margin-bottom: 0
}

.cl-item {
  border-bottom: 1px solid #e5f5ff
}

.cl-item.active .cl-item-title {
  background-color: #e5f5ff;
  color: #005eb8;
  font-weight: 600
}

.cl-item.active .cl-item-title:after {
  transform: rotate(-90deg)
}

.cl-item.active .cl-item-text {
  background-color: #f5fbff
}

.cl-item-title {
  background: #fff;
  color: #222;
  cursor: pointer;
  font-size: 18px;
  padding: 16px 64px 16px 24px;
  position: relative;
  transition: all .26s ease
}

@media(max-width: 767px) {
  .cl-item-title {
      font-size:16px
  }
}

.cl-item-title:hover {
  background-color: #e5f5ff
}

.cl-item-title:after {
  align-items: center;
  color: #222;
  content: "\e90d";
  display: flex;
  font-family: s-icons;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: calc(50% - 12px);
  transform: rotate(-270deg);
  transition: transform .6s ease-in-out;
  width: 24px
}

.cl-item-title p {
  color: inherit;
  font-size: inherit;
  line-height: 1.78;
  margin-bottom: 0
}

.cl-item-title img {
  display: inline-block;
  height: 18px!important;
  margin-bottom: -2px;
  width: auto!important
}

.cl-item-content {
  background-color: #f5fbff;
  display: none;
  padding: 12px 24px 32px
}

.cl-item-content-text {
  color: #464749;
  font-size: 18px;
  max-width: 1024px
}

@media(max-width: 767px) {
  .cl-item-content-text {
      font-size:16px
  }
}

.cl-item-content-text * {
  background-color: transparent!important
}

.cl-item-content-text p {
  line-height: 1.78;
  margin-bottom: 12px
}

.cl-item-content-text p,.cl-item-content-text ul li {
  color: inherit;
  font-size: inherit
}

.cl-item-content-text p:last-child,.cl-item-content-text ul:last-child,.cl-item-content-text ul li:last-child {
  margin-bottom: 0
}

.cl-item-content-btn {
  margin-top: 16px;
  min-width: 180px
}


//=============contact information
.contact-information-editable {
  margin: 20px 0
}

.contact-information-editable .contact-information-title {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px
}

.contact-information-editable .contact-information-phone {
  color: #005eb8;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px
}

.contact-information-editable .contact-information-phone i {
  color: #005eb8;
  font-size: 24px;
  margin-right: 10px
}

.contact-information-editable .contact-information-phone a {
  color: #005eb8;
  display: block;
  line-height: 24px;
  text-decoration: none
}

.contact-information-editable .contact-information-email {
  color: #005eb8;
  display: flex;
  font-size: 14px;
  font-weight: 400
}

.contact-information-editable .contact-information-email i {
  color: #005eb8;
  font-size: 24px;
  margin-right: 10px
}

.contact-information-editable .contact-information-email a {
  color: #005eb8;
  line-height: 24px;
  text-decoration: none
}

//==========contact-us template
.error#contact-popup-upload .upload-field {
  border: 1px solid #e4002b;
  border-top: 1px dashed #cdd3d8;
  outline: none
}

.error#contact-popup-upload .upload-field-list {
  border-left: 1px solid #e4002b;
  border-right: 1px solid #e4002b;
  border-top: 0 dashed #cdd3d8
}

.noError#contact-popup-upload .upload-field-list {
  border-left: 1px solid #cdd3d8;
  border-right: 1px solid #cdd3d8;
  border-top: 0 dashed #cdd3d8
}

.noError#contact-popup-upload .upload-field {
  border: 1px solid #cdd3d8;
  border-top-style: dashed;
  outline: none
}

#contact-popup-upload {
  display: flex;
  flex-direction: column;
  margin: -5px 0 0
}

#contact-popup-upload label {
  display: none
}

#contact-popup-upload .upload-field {
  align-items: flex-start;
  display: flex;
  order: 2
}

#contact-popup-upload .upload-field,.error #contact-popup-upload .upload-field {
  border: 1px solid #cdd3d8;
  border-top-style: dashed;
  outline: none
}

.dragover:not(.reachLimit)#contact-popup-upload .upload-field {
  border-color: transparent
}

#contact-popup-upload .upload-field__error {
  align-items: flex-start;
  border-bottom: 1px dashed #e4002b;
  font-size: 14px;
  margin-top: -1px;
  padding: 0 12px
}

#contact-popup-upload .upload-field-item.active {
  align-items: center;
  display: flex;
  margin-bottom: 12px
}

#contact-popup-upload .upload-field-item .icon-s-error {
  margin-right: 8px
}

#contact-popup-upload .upload-field-area {
  background-color: #fff;
  border: 1px dashed transparent;
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  min-height: unset;
  order: 1;
  padding: 1px 0 1px 10px;
  transition: background-color,border .4s ease-in-out
}

.dragover#contact-popup-upload .upload-field-area {
  background-color: #f2f9fe;
  border: 1px dashed #115689
}

@media only screen and (max-width: 1023px) {
  #contact-popup-upload .upload-field-area {
      padding:0
  }
}

#contact-popup-upload .upload-field-area__message {
  align-items: baseline;
  display: flex;
  margin: 14px 0;
  padding-left: 8px
}

@media only screen and (max-width: 1023px) {
  #contact-popup-upload .upload-field-area__message {
      height:100%;
      margin: 0;
      padding: 0;
      width: 100%
  }
}

#contact-popup-upload .upload-field-area__message-btn,#contact-popup-upload .upload-field-area__message-limit,#contact-popup-upload .upload-field-area__message-text {
  color: #6c6e70;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43
}

#contact-popup-upload .upload-field-area__message-btn p,#contact-popup-upload .upload-field-area__message-limit p,#contact-popup-upload .upload-field-area__message-text p {
  color: #6c6e70;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 0
}

#contact-popup-upload .upload-field-area__message-text {
  margin: 0
}

@media only screen and (max-width: 1023px) {
  #contact-popup-upload .upload-field-area__message-text {
      height:100%;
      width: 100%
  }
}

#contact-popup-upload .upload-field-area__message-btn {
  padding-left: 0
}

@media only screen and (max-width: 1023px) {
  #contact-popup-upload .upload-field-area__message-btn {
      height:100%;
      padding: 14px 0 14px 34px;
      text-align: left;
      width: 100%
  }
}

@media only screen and (min-width: 1024px) {
  #contact-popup-upload .upload-field-area__message-btn,#contact-popup-upload .upload-field-area__message-btn p {
      color:#005eb8
  }
}

#contact-popup-upload .upload-field-list {
  background: #fff;
  border: 1px solid #cdd3d8;
  border-bottom: none;
  border-top: none;
  margin-top: 1px;
  transition: border-color .15s ease-in-out
}

#contact-popup-upload .upload-field-list__item {
  border: none;
  justify-content: flex-start
}

#contact-popup-upload .upload-field-list__item:first-of-type {
  border-top: 1px dashed #cdd3d8;
  padding-top: 14px
}

#contact-popup-upload .upload-field-list__item:nth-last-of-type(2) {
  padding-bottom: 14px
}

#contact-popup-upload .upload-field-list__item-name {
  color: #005eb8;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.29
}

#contact-popup-upload .upload-field-list__item .btn-link {
  cursor: pointer;
  font-size: 0;
  margin-left: 10px
}

#contact-popup-upload .upload-field-list__item .btn-link:before {
  background: #dadfe3;
  border-radius: 50%;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 13px;
  padding: 0 5px;
  visibility: visible
}

#contact-popup-upload .upload-field-list__total {
  display: none
}

#contact-popup-upload .upload-field__limit {
  border-bottom: 1px dashed #cdd3d8;
  color: #e4002b;
  display: none;
  justify-content: flex-start;
  position: static
}

#contact-popup-upload .icon-s-upload {
  color: #005eb8;
  font-size: 16px;
  vertical-align: middle
}

@media only screen and (max-width: 1023px) {
  #contact-popup-upload .icon-s-upload {
      left:10px;
      position: absolute;
      z-index: -1
  }
}

#contact-popup-upload .icon-s-upload:before {
  content: "\e916"
}

.p-contact-block {
  flex-direction: column;
  margin-top: 0
}

@media only screen and (min-width: 768px) {
  .p-contact-block {
      margin-bottom:50px;
      margin-top: 30px
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block {
      background-color:#f6f8f8;
      flex-direction: row;
      margin-bottom: 80px;
      margin-top: 50px;
      max-width: calc(100% - 30px)
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .p-contact-block .configural-editable-wrapper .simple-editables {
      box-shadow:none!important
  }

  .p-contact-block .configural-editable-wrapper .simple-editables .p-contact-block__offices {
      margin-top: auto
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block .configural-editable-wrapper {
      transform:translateY(-30px)
  }

  .p-contact-block .configural-editable-wrapper .p-contact-block__offices {
      transform: unset
  }
}

@media only screen and (max-width: 1023px) {
  .p-contact-block .configural-editable-wrapper .p-contact-block__offices {
      margin-top:30px
  }
}

.p-contact-block__form {
  background-color: #f6f8f8;
  flex: 0 0 100%;
  margin: 0 -8px;
  padding: 20px 8px 30px
}

@media only screen and (min-width: 768px) {
  .p-contact-block__form {
      padding:40px 30px 30px
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__form {
      background-color:transparent;
      flex: 0 0 calc(100% - 380px);
      margin: 40px 0 0 30px;
      max-width: calc(100% - 380px);
      padding: 0 30px 0 0
  }

  .p-contact-block__form.hasFiles {
      height: 100%
  }
}

@media only screen and (min-width: 1280px) {
  .p-contact-block__form {
      flex:0 0 calc(100% - 400px);
      max-width: calc(100% - 400px)
  }
}

@media only screen and (min-width: 1440px) {
  .p-contact-block__form {
      flex:0 0 calc(100% - 470px);
      max-width: calc(100% - 470px);
      padding-right: 30px
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .p-contact-block__form {
      padding-top:30px
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__form {
      margin-top:30px
  }
}

.p-contact-block__form-description {
  color: #717171;
  display: flex;
  flex-direction: row;
  line-height: 1.75;
  margin-bottom: 30px
}

@media only screen and (max-width: 767px) {
  .p-contact-block__form-description {
      flex-direction:column-reverse
  }
}

@media only screen and (min-width: 768px) {
  .p-contact-block__form-description {
      margin-bottom:20px
  }
}

.p-contact-block__form-description>p {
  padding-right: 20px;
  width: calc(100% - 131px)
}

@media only screen and (max-width: 767px) {
  .p-contact-block__form-description>p {
      margin-right:0;
      width: auto
  }

  .p-contact-block__form-description>p+#siteseal {
      padding-bottom: 20px
  }
}

.p-contact-block__form-description #siteseal {
  height: 32px;
  margin-left: auto;
  width: 131px
}

.p-contact-block__form-description #siteseal img+img {
  display: none
}

@media only screen and (max-width: 767px) {
  .p-contact-block__form-description #siteseal {
      display:block;
      height: unset;
      line-height: 0;
      margin-left: inherit;
      padding-bottom: 0;
      position: inherit;
      text-align: center;
      width: unset
  }
}

.p-contact-block .md-field {
  margin-right: 30px
}

.p-contact-block .md-field:last-child {
  margin-right: 0
}

.p-contact-block .md-field.textarea {
  margin-bottom: 0
}

@media(max-width: 767px) {
  .p-contact-block .md-field.textarea {
      line-height:1
  }
}

.p-contact-block .md-field .md-input:not(textarea) {
  padding-right: 30px
}

.p-contact-block .modal-form__btn {
  letter-spacing: normal;
  line-height: normal;
  margin: 30px 0;
  max-width: 100%;
  min-width: 170px;
  padding: 15.5px 10px;
  width: auto
}

@media only screen and (min-width: 768px) {
  .p-contact-block .modal-form__btn {
      margin:30px 0
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .p-contact-block__left {
      flex-direction:column
  }
}

@media only screen and (max-width: 1023px) {
  .p-contact-block__left .p-contact-block__form {
      order:1
  }
}

.p-contact-block__left .configural-editable-wrapper {
  height: auto
}

.p-contact-block__left .configural-editable-wrapper>div {
  height: 100%
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__left .configural-editable-wrapper {
      transform:translateY(-30px)
  }
}

@media only screen and (max-width: 767px) {
  .p-contact-block__left .configural-editable-wrapper {
      margin:0 -8px
  }
}

@media only screen and (max-width: 1023px) {
  .p-contact-block__left .configural-editable-wrapper {
      order:2
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__left {
      flex-direction:column-reverse;
      flex-direction: row-reverse;
      margin-left: 30px;
      margin-top: 60px
  }

  .p-contact-block__left .p-contact-block__form {
      padding-right: 30px
  }

  .p-contact-block__left .p-contact-block__form.hasFiles {
      height: 100%
  }
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__left.p-contact-block-configurable {
      margin-bottom:40px
  }
}

@media only screen and (max-width: 767px) {
  .p-contact-block__left.p-contact-block-configurable {
      margin-bottom:40px
  }
}

@media only screen and (min-width: 1280px) and (max-width:1439px) {
  .p-contact-block__left.p-contact-block-configurable.p-contact-block__left {
      max-width:calc(100% - 30px)
  }
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__left .a-testimonials-contact-configurable {
      padding-top:25px
  }
}

@media only screen and (max-width: 767px) {
  .p-contact-block__left .a-testimonials-contact-configurable {
      padding-top:22px
  }
}

.p-contact-block-configurable {
  margin-bottom: 40px
}

@media only screen and (max-width: 1023px) {
  .p-contact-block-configurable {
      margin-bottom:0
  }
}

@media only screen and (min-width: 1280px) and (max-width:1439px) {
  .p-contact-block-configurable.p-contact-block {
      max-width:calc(100% - 30px)
  }

  .p-contact-block-configurable.p-contact-block .simple-editables {
      width: 400px
  }
}

@media only screen and (max-width: 1023px) {
  .p-contact-block-configurable .modal-form__btn {
      margin-bottom:0
  }
}

@media only screen and (max-width: 767px) {
  .p-contact-block-configurable .modal-form__btn {
      margin-bottom:0
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block-configurable form#ContactForm {
      height:100%
  }

  .p-contact-block-configurable .md-field.textarea {
      border-left: 1px solid #cdd3d8;
      border-right: 1px solid #cdd3d8;
      min-height: 80px;
      -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
  }

  .p-contact-block-configurable .md-field.textarea.error {
      border-left: 1px solid #e4002b;
      border-right: 1px solid #e4002b
  }

  .p-contact-block-configurable .md-field.textarea.error.noError {
      border-left: 1px solid #cdd3d8;
      border-right: 1px solid #cdd3d8
  }

  .p-contact-block-configurable .md-field.textarea.error.noError.focused,.p-contact-block-configurable .md-field.textarea.focused {
      border-left: 1px solid #005eb8;
      border-right: 1px solid #005eb8
  }

  .p-contact-block-configurable .md-field.textarea textarea {
      border-left: 0 solid #cdd3d8!important;
      border-right: 0 solid #cdd3d8!important;
      min-height: 100%!important
  }

  .p-contact-block-configurable .md-field.textarea textarea.hasError {
      height: calc(100% - 39px)!important;
      min-height: calc(100% - 39px)!important
  }

  .p-contact-block-configurable .md-error {
      border: 0 solid #fff!important;
      width: 100%
  }
}

.p-contact-block-configurable .text-center {
  position: relative
}

.p-contact-block-configurable .modal-form__btn {
  padding: 10.5px 10px
}

@media only screen and (max-width: 767px) {
  .p-contact-block-configurable .modal-form__btn {
      width:100%
  }
}

.p-contact-block__admin {
  flex-direction: column-reverse
}

.p-contact-block__admin .p-contact-block__offices {
  width: 100%
}

.p-contact-block__admin .configural-editable-wrapper {
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,.1);
  padding: 30px
}

.microsite .p-contact-block-configurable #contact-popup-upload .upload-field-area__message-btn {
  padding: 0 6px 0 0
}

@media only screen and (max-width: 1023px) {
  .microsite .p-contact-block-configurable #contact-popup-upload .upload-field-area__message-btn {
      padding-left:34px
  }
}

.microsite .p-contact-block-configurable #contact-popup-upload .upload-field-area {
  padding: 0 0 0 10px
}

@media only screen and (min-width: 1024px) {
  .microsite .p-contact-block-configurable .p-contact-block__form:not(.gdpr) form#ContactForm,.microsite .p-contact-block__left .p-contact-block__form.hasFiles {
      height:calc(100% - 20px)!important
  }

  .microsite .p-contact-block.p-contact-block-configurable .p-contact-block__form:not(.gdpr) {
      margin-bottom: -20px
  }

  .microsite .configural-editable-wrapper {
      height: auto!important
  }
}

.microsite .p-contact-block-configurable #contact-popup-upload .upload-field-area__message {
  margin: 7px 0
}

.microsite .md-field.textarea textarea~.md-error {
  background: 0 0
}

@media only screen and (min-width: 1280px) and (max-width:1439px) {
  .microsite .p-contact-block-configurable .p-contact-block__form {
      flex:0 0 calc(100% - 470px);
      max-width: calc(100% - 470px)
  }

  .microsite .p-contact-block-configurable .p-contact-block__offices {
      flex: 0 0 470px;
      max-width: 100%;
      padding: 0 10px;
      width: 470px
  }

  .microsite .p-contact-block-configurable.p-contact-block .simple-editables {
      width: 470px
  }
}

@media only screen and (min-width: 1024px) {
  .microsite .p-contact-block__bullet-item.scu {
      padding:5px 30px 5px 34px
  }

  .microsite .p-contact-block__bullet-item.scu:before {
      top: 14px
  }

  .microsite .p-contact-block__bullet-description.scu {
      padding: 11px 0 15px
  }
}

@media only screen and (min-width: 1280px) {
  .microsite .p-contact-block__bullet-item.scu {
      padding:9px 30px 9px 34px
  }

  .microsite .p-contact-block__bullet-item.scu:before {
      top: 17px
  }

  .microsite .p-contact-block__bullet-item {
      line-height: 1.78
  }
}

.page-main .p-contact-block__form-description>p {
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0
}

.contact-page .p-contact-block .modal-form__btn {
  margin-top: 0
}

.contact-page #contact-popup-upload {
  margin-bottom: 30px
}

@media(max-width: 767px) {
  .contact-page #contact-popup-upload {
      margin-bottom:20px
  }
}

.contact-page .author-ask-modal__gdpr {
  margin-bottom: 30px;
  margin-top: 0
}

@media(max-width: 767px) {
  .contact-page .author-ask-modal__gdpr {
      margin-top:30px
  }
}

.page-main p.p-contact-block__bullet-description.scu {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0
}

@media only screen and (max-width: 1024px) {
  .page-main .p-contact-block__offices-title {
      margin-left:16px
  }
}

.p-contact-block__offices {
  cursor: default!important;
  flex: 0 0 100%;
  min-height: 100%;
  transform: translateY(-30px);
  width: 100%
}

@media only screen and (min-width: 768px) {
  .p-contact-block__offices {
      flex:0 0 calc(100% - 60px);
      transform: translateX(30px) translateY(-30px);
      width: calc(100% - 60px)
  }
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__offices {
      flex:0 0 380px;
      transform: translateY(-30px);
      width: 380px
  }
}

@media only screen and (min-width: 1280px) {
  .p-contact-block__offices {
      flex:0 0 400px;
      width: 400px
  }
}

@media only screen and (min-width: 1440px) {
  .p-contact-block__offices {
      flex:0 0 470px;
      width: 470px
  }
}

.p-contact-block__offices:after,.p-contact-block__offices:hover:after {
  border-bottom: none!important;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,.1)!important
}

.p-contact-block__offices-title {
  color: #2b2826;
  font-size: 24px;
  margin: 30px 30px 20px
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__offices-title {
      margin-left:16px
  }
}

.p-contact-block__offices-title:after {
  background-color: #ed8b00;
  content: "";
  display: block;
  height: 2px;
  margin: 10px 0 0;
  width: 40px
}

.p-contact-block__offices-title-no-line:after {
  display: none
}

.p-contact-block__offices-item {
  border-bottom: 2px solid #fafafa;
  border-top: 2px solid #fafafa;
  color: #2b2826;
  padding: 0 30px;
  position: relative
}

.p-contact-block__offices-item:first-of-type {
  border-bottom: none
}

.p-contact-block__offices-item:first-of-type .toggle-content {
  display: block
}

.p-contact-block__offices-item .toggle-title {
  color: #939eab;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 11px 0
}

@media only screen and (min-width: 768px) {
  .p-contact-block__offices-item .toggle-title {
      font-size:18px
  }
}

.p-contact-block__offices-item .toggle-button {
  background: 0 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  height: 42px;
  height: 24px;
  left: calc(100% - 29px);
  left: calc(100% - 41px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-indent: 9999px;
  top: 0;
  white-space: nowrap;
  width: 29px;
  width: 24px
}

.p-contact-block__offices-item .toggle-button:after,.p-contact-block__offices-item .toggle-button:before {
  background: #939eab;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: calc(50% + 3px);
  transition: .4s ease;
  width: 9px
}

.p-contact-block__offices-item .toggle-button:before {
  margin-left: -16px;
  transform: rotate(45deg);
  transform-origin: right
}

.p-contact-block__offices-item .toggle-button:after {
  margin-left: -8px;
  transform: rotate(-45deg);
  transform-origin: left
}

.p-contact-block__offices-item .toggle-button:after,.p-contact-block__offices-item .toggle-button:before {
  left: 75%;
  top: 60%
}

.p-contact-block__offices-item .toggle-button:hover:after,.p-contact-block__offices-item .toggle-button:hover:before {
  background: #939eab
}

@media only screen and (min-width: 1600px) {
  .p-contact-block__offices-item .toggle-button {
      left:calc(100% - 44px)
  }
}

.p-contact-block__offices-item .toggle-input {
  cursor: pointer;
  height: 24px;
  left: calc(100% - 35px);
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 24px;
  width: 100%;
  z-index: 1
}

.p-contact-block__offices-item .toggle-input:checked+.toggle-button:after,.p-contact-block__offices-item .toggle-input:checked+.toggle-button:before {
  left: 50%;
  margin-top: -2px
}

.p-contact-block__offices-item .toggle-input:checked+.toggle-button:before {
  transform: rotate(-45deg)
}

.p-contact-block__offices-item .toggle-input:checked+.toggle-button:after {
  transform: rotate(45deg)
}

.p-contact-block__offices-item .toggle-input:checked+.toggle-button:after,.p-contact-block__offices-item .toggle-input:checked+.toggle-button:before {
  left: 75%;
  top: 40%
}

.p-contact-block__offices-item .toggle-input:checked~.toggle-title {
  color: #2b2826
}

.p-contact-block__offices-item .toggle-button,.p-contact-block__offices-item .toggle-input {
  top: 11px
}

.p-contact-block__offices-item .toggle-content {
  display: none;
  padding: 9px 0 21px;
  width: 100%
}

.p-contact-block__offices-item .toggle-content a,.p-contact-block__offices-item .toggle-content div {
  background-image: inherit;
  color: #2b2826;
  font-size: 16px;
  line-height: 1.88;
  margin-bottom: 10px
}

@media only screen and (max-width: 767px) {
  .p-contact-block__offices-item .toggle-content a,.p-contact-block__offices-item .toggle-content div {
      line-height:2
  }
}

.p-contact-block__offices-bbb {
  margin: auto 30px 30px;
  padding-top: 40px
}

@media only screen and (max-width: 1023px) {
  .p-contact-block__offices.p-contact-block__slider:after,.p-contact-block__offices.p-contact-block__slider:hover:after {
      box-shadow:none
  }
}

.p-contact-block__bullet-list {
  padding: 0 16px 30px
}

@media only screen and (min-width: 1024px) {
  .p-contact-block__bullet-list {
      padding:0 0 20px
  }
}

.p-contact-block__bullet-item {
  line-height: 1.88;
  padding: 10px 0 10px 64px;
  position: relative
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__bullet-item {
      padding:10px 0 10px 34px
  }

  .p-contact-block__bullet-item:before {
      left: 0
  }
}

.p-contact-block__bullet-item p {
  margin-bottom: 0
}

.p-contact-block__bullet-item.scu {
  padding: 10px 0 10px 34px
}

.p-contact-block__bullet-item.scu:before {
  left: 0
}

.p-contact-block__bullet-item.scu p {
  font-size: 16px;
  margin-bottom: 0
}

.p-contact-block__bullet-item:before {
  background-image: url('../../../images/app/check.svg');
  background-size: contain;
  content: "";
  display: inline-block;
  height: 15px;
  left: 34px;
  position: absolute;
  top: 19px;
  width: 20px
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__bullet-item:before {
      left:0
  }
}

.p-contact-block__bullet-description {
  color: #2b2826;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 11px 30px 10px
}

@media only screen and (max-width: 1024px) {
  .p-contact-block__bullet-description {
      padding-left:0
  }
}

.p-contact-block__bullet-description.scu {
  font-size: 24px;
  font-weight: 700;
  padding: 11px 0 10px
}

@media only screen and (min-width: 768px) {
  .p-contact-block__bullet-description {
      font-size:18px
  }
}

.p-contact-block__slider {
  transform: none
}

@media only screen and (max-width: 1023px) {
  .p-contact-block__slider {
      margin-left:-16px;
      width: calc(100% + 32px)
  }
}

@media only screen and (max-width: 768px) {
  .p-contact-block__slider {
      margin-left:-8px;
      width: calc(100% + 16px)
  }
}

.p-contact-hotcall {
  margin-bottom: 30px
}

.p-contact-hotcall .btn-white {
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  max-width: 100%
}

.p-contact-hotcall .icon-s-phone {
  font-size: 20px;
  margin-right: 12px
}

.author-ask-modal__gdpr {
  margin-top: 30px
}

.author-ask-modal__gdpr--desc {
  margin: 25px 0 0
}

.author-ask-modal__gdpr--desc p {
  font-size: 12px!important;
  line-height: 1.78!important;
  margin: 0!important;
  padding: 0
}

@media only screen and (min-width: 1280px) {
  .author-ask-modal__gdpr--desc p {
      font-size:14px!important
  }
}

.author-ask-modal__gdpr--desc p a {
  color: #115689!important;
  text-decoration: underline;
  text-decoration-color: currentColor
}

.author-ask-modal__gdpr label {
  font-size: 14px
}

@media only screen and (min-width: 1280px) {
  .author-ask-modal__gdpr label {
      font-size:16px
  }
}

.contact-us-editable {
  margin-top: 50px
}

.contact-us-editable.empty-title,.contact-us-editable.empty-title .p-contact-block__form {
  margin-top: 20px
}

@media only screen and (max-width: 1023px) {
  .contact-us-editable {
      margin-top:30px
  }
}

.contact-us-editable .p-contact-block__form {
  max-width: calc(100% - 30px);
  padding-top: 30px
}

@media only screen and (max-width: 767px) {
  .contact-us-editable .p-contact-block__form {
      padding-top:20px
  }
}

.contact-us-editable p.cu-title {
  color: #222;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 16px
}

.contact-us-editable p.cu-title:after {
  background-color: #ed8b00;
  content: "";
  display: block;
  height: 2px;
  margin: 10px 0 0;
  width: 40px
}

.contact-us-editable .p-contact {
  margin: 0 -10px;
  padding-bottom: 0;
  padding-top: 0
}

.contact-us-editable .p-contact .btn-default {
  padding: 15.5px 28px
}

@media only screen and (max-width: 767px) {
  .contact-us-editable .p-contact .btn-default {
      display:inline;
      margin: 30px 0
  }
}

.contact-us-editable .p-contact-block {
  margin: 20px 0
}

.contact-us-editable .p-contact-block .md-field {
  margin-right: 0
}

.contact-us-editable .p-contact-block__description {
  color: #717171;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 20px
}

.contact-us-editable .p-contact-block__description p {
  color: #717171;
  font-size: 16px;
  line-height: normal
}

.contact-us-editable .p-contact-block__description p:last-child {
  margin-bottom: 0
}

.contact-us-editable .p-contact-block .contact-form-responsible-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px
}

.contact-us-editable .p-contact-block .contact-form-responsible-wrapper .contact-form-input-wrapper {
  flex: 1 1 auto;
  margin-left: 30px;
  min-width: 1px
}

.contact-us-editable .p-contact-block .contact-form-responsible-wrapper .contact-form-input-wrapper .inputs-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px
}

.contact-us-editable .p-contact-block .contact-form-responsible-wrapper .contact-form-input-wrapper .inputs-row .md-field {
  display: block;
  flex: 1 1 190px;
  margin-left: 30px;
  min-width: 190px;
  width: 190px
}

.contact-us-editable .p-contact-block .contact-form-textarea-wrapper #contact-popup-upload {
  margin-bottom: 0;
  margin-left: 30px
}

@media only screen and (max-width: 767px) {
  .contact-us-editable .p-contact-block .contact-form-textarea-wrapper #contact-popup-upload {
      margin-bottom:0
  }
}

.contact-us-editable .p-contact-block .contact-form-textarea-wrapper.contact-form-input-wrapper,.contact-us-editable .p-contact-block .contact-form-textarea-wrapper.contact-form-input-wrapper .md-field:first-child {
  margin-right: 0
}

.contact-us-editable .p-contact-block .js-prefilling-description {
  display: none
}

.contact-us-editable .p-contact-block__form {
  padding-bottom: 0
}

@media only screen and (min-width: 768px) {
  .contact-us-editable .p-contact {
      margin:0;
      padding-bottom: 0
  }

  .contact-us-editable .p-contact .modal-form__btn {
      width: auto
  }

  .contact-us-editable .p-contact-block {
      margin-bottom: 60px
  }

  .contact-us-editable .p-contact-block .modal-form__btn {
      margin: 30px 0;
      min-width: 228px;
      padding: 15.5px 28px
  }
}
// @media only screen and (min-width: 768px) and only screen and (max-width:767px)
@media only screen and (min-width: 768px) and (max-width:767px) {
  .contact-us-editable .p-contact-block .modal-form__btn {
      margin:20px 0;
      width: 170px
  }
}

@media only screen and (min-width: 1025px) {
  .contact-us-editable .p-contact .container {
      width:100%
  }

  .contact-us-editable .p-contact .p-contact-block__form {
      max-width: calc(100% - 30px)
  }

  .contact-us-editable .p-contact-block {
      max-width: 100%
  }
}

@media only screen and (max-width: 1024px) and (min-width:768px) {
  .contact-us-editable .p-contact .p-contact-block__form {
      padding-left:30px
  }
}

@media only screen and (max-width: 1024px) {
  .contact-us-editable .p-contact .container {
      margin:0 auto;
      padding: 0 16px;
      width: auto
  }

  .contact-us-editable .p-contact .p-contact-block__form {
      margin: 0;
      max-width: 100%
  }

  .contact-us-editable .p-contact-block {
      max-width: 100%
  }
}

@media only screen and (max-width: 1024px) and (min-width:1024px) {
  .contact-us-editable .p-contact .container {
      margin:0 auto;
      padding: 0 27px;
      width: auto
  }
}

@media only screen and (max-width: 768px) {
  .contact-us-editable .p-contact .container {
      margin:0 auto;
      padding: 0 16px;
      width: auto
  }

  .contact-us-editable .p-contact-block,.contact-us-editable .p-contact .p-contact-block__form {
      max-width: 100%
  }
}

.contact-us-editable .p-contact .md-error .hasError {
  color: #e4002b;
  font-size: 12px;
  list-style: none;
  margin-bottom: -4px
}

@media only screen and (max-width: 1024px) {
  .container .contact-us-editable .p-contact .container {
      margin:0 auto;
      padding: 0;
      width: auto
  }
}


//============ grid
.spectrecreation_area_grid:last-child .grid-editable-wrapper {
  margin-bottom: 0
}

.spectrecreation_area_grid:last-child .grid-editable-wrapper .grid-container:not(.colored-background) {
  padding: 30px 0
}

.spectrecreation_area_grid:last-child .grid-editable-wrapper .grid-container:not(.colored-background) .grid-container {
  padding: 0
}

.grid-editable-wrapper {
  margin-bottom: 30px;
  margin-top: 30px
}

.grid-editable-wrapper .grid-editable-wrapper {
  margin: 0
}

.grid-editable-wrapper .grid-editable-wrapper .grid-title {
  display: none
}

.grid-editable-wrapper .grid-container {
  position: relative
}

.grid-editable-wrapper .grid-container.colored-background {
  padding: 30px 0
}

.grid-editable-wrapper .grid-container.colored-background .background-color-block {
  bottom: 0;
  top: 0
}

.grid-editable-wrapper .grid-container.colored-background .grid-container.colored-background {
  padding: 0
}

.grid-editable-wrapper .grid-container.colored-background .grid-container.colored-background .background-color-block {
  bottom: 15px;
  top: 15px
}

.grid-editable-wrapper .grid-container .background-color-block {
  bottom: 15px;
  left: 50%;
  position: absolute;
  top: 15px;
  transform: translateX(-50%)
}

.grid-editable-wrapper .grid-container .grid-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin: -15px
}

@media(max-width: 1023px) {
  .grid-editable-wrapper .grid-container .grid-wrapper {
      margin:-15px -10px
  }
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-column {
  flex-flow: column nowrap
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-column>.grid-item {
  max-width: none!important
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-row {
  flex-flow: row nowrap
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-default {
  flex-flow: row wrap!important
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-default>.grid-item {
  max-width: none!important
}

.grid-editable-wrapper .grid-container .grid-wrapper.grid-wrapper-old-ie.grid-column>.grid-item {
  flex-basis: auto!important
}

.grid-editable-wrapper .grid-container .grid-wrapper .grid-item {
  flex: 1 1;
  z-index: 1
}

.grid-editable-wrapper .grid-container .grid-wrapper .grid-item .grid-item-content {
  margin: 15px
}

@media(max-width: 1023px) {
  .grid-editable-wrapper .grid-container .grid-wrapper .grid-item .grid-item-content {
      margin:15px 10px
  }
}

.grid-editable-wrapper .grid-container .grid-wrapper .grid-item .grid-item-content .spectrecreation_area_content {
  margin-bottom: 30px
}

.grid-editable-wrapper .grid-container .grid-wrapper .grid-item .grid-item-content .spectrecreation_area_content:last-child {
  margin-bottom: 0
}

.grid-editable-wrapper .contact-us-editable {
  margin: 0;
  min-width: 210px
}

.grid-editable-wrapper .contact-us-editable .p-contact {
  margin: 0
}

.grid-editable-wrapper .contact-us-editable .p-contact .p-contact-block {
  background-color: transparent;
  margin: 0
}

.grid-editable-wrapper .contact-us-editable .p-contact .p-contact-block .p-contact-block__form {
  background-color: transparent;
  margin: 0;
  max-width: 100%;
  padding: 0
}

.grid-editable-wrapper .contact-us-editable .p-contact .p-contact-block .modal-form__btn {
  margin-bottom: 0
}

.grid-editable-wrapper .contact-us-editable .p-contact .p-contact-hotcall {
  margin: 30px 0 0
}

.grid-editable-wrapper .a-wysiwyg {
  margin: 0;
  min-width: 200px
}

.grid-editable-wrapper .contact-information-editable,.grid-editable-wrapper .link-editable-wrapper {
  margin: 0 0 -10px;
  min-width: 230px
}

.grid-editable-wrapper .image-cards-wrapper {
  margin: 0;
  min-width: 300px
}

.grid-editable-wrapper .image-cards-wrapper .image-cards-card-item {
  min-width: 260px
}

.grid-editable-wrapper .cb-wrapper {
  margin: 0;
  min-width: 240px;
  padding: 40px 0
}

@media(max-width: 767px) {
  .grid-editable-wrapper .cb-wrapper {
      padding:2px 0
  }

  .grid-editable-wrapper .cb-wrapper .cb-container {
      display: block;
      padding: 0!important
  }
}


//===============home page
.home-page .search-bar .search-editable {
  background-color: transparent;
  background: 0 0;
  margin-top: 115px;
  padding-top: 0;
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 2
}

@media only screen and (max-width: 1920px) {
  .home-page .search-bar .search-editable {
      margin-top:130px
  }
}

@media only screen and (max-width: 1600px) {
  .home-page .search-bar .search-editable {
      margin-top:115px
  }
}

@media only screen and (max-width: 1279px) {
  .home-page .search-bar .search-editable {
      margin-top:90px
  }
}

@media only screen and (max-width: 1024px) {
  .home-page .search-bar .search-editable {
      margin-top:80px
  }
}

.home-page .image-cards-card-item:before {
  background-color: #005eb8;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out
}

.home-page .image-cards-card-item:after {
  border-bottom: none;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1)
}

.home-page .image-cards-card-item:hover:before {
  right: 0
}

.home-page .image-cards-card-item:hover:after {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,.2)
}

.home-page .image-cards-wrapper:not(.one-card) .image-cards-card-wrapper .image-cards-card-item .image-cards-link {
  margin-bottom: 0
}

.home-page .a-tabs-icons .a-tabs-icons__bullet {
  font-size: 16px
}

.home-page .a-tabs-icons .a-tabs-icons__bullet:before {
  transform: translateY(18px)
}

@media(min-width: 768px) {
  .home-page .a-tabs-icons .a-tabs-icons__bullet {
      font-size:18px
  }

  .home-page .a-tabs-icons .a-tabs-icons__bullet:before {
      transform: translateY(21px)
  }
}

.home-page .sit-wrapper .sit-content-wrapper h2.sit-title {
  font-size: 32px;
  line-height: 1.25
}

@media(max-width: 1023px) {
  .home-page .sit-wrapper .sit-content-wrapper h2.sit-title {
      font-size:26px;
      line-height: normal
  }
}

@media(max-width: 767px) {
  .home-page .sit-wrapper .sit-content-wrapper h2.sit-title {
      font-size:22px;
      line-height: normal
  }
}

@media(min-width: 1280px) {
  .home-page .sit-wrapper .sit-image-wrapper {
      height:448px
  }
}

@media(min-width: 1024px) and (max-width:1279px) {
  .home-page .sit-wrapper .sit-image-wrapper {
      height:480px
  }
}

.home-page .h2 {
  margin: 0 0 20px
}

.home-page .h2:before {
  display: none
}

body .home-page.search-bar-enable .a-cover-services,body .home-page.search-bar-enable .hp-title {
  padding-top: 240px
}

@media only screen and (max-width: 1600px) {
  body .home-page.search-bar-enable .a-cover-services,body .home-page.search-bar-enable .hp-title {
      padding-top:210px
  }
}

@media only screen and (max-width: 1279px) {
  body .home-page.search-bar-enable .a-cover-services,body .home-page.search-bar-enable .hp-title {
      padding-top:180px
  }
}

@media only screen and (max-width: 1024px) {
  body .home-page.search-bar-enable .a-cover-services,body .home-page.search-bar-enable .hp-title {
      padding-top:160px
  }
}

.page-main .hp-title {
  color: #222;
  font-family: inherit!important;
  font-weight: 700;
  line-height: 1.25;
  margin: 48px 0 10px;
  padding-top: 50px;
  text-align: center
}

.page-main .hp-title:after {
  background-color: #ed8b00;
  content: "";
  display: block;
  height: 2px;
  margin: 20px 0 0;
  width: 70px
}

@media(max-width: 1599px) {
  .page-main .hp-title {
      font-weight:700
  }
}

@media(max-width: 1279px) {
  .page-main .hp-title {
      font-size:48px;
      margin-top: 16px
  }
}

@media(max-width: 1023px) {
  .page-main .hp-title {
      font-size:40px;
      margin-bottom: 0
  }

  .page-main .hp-title:after {
      background-color: #ed8b00;
      content: "";
      display: block;
      height: 2px;
      margin: 20px 0 0;
      width: 50px
  }
}

@media(max-width: 767px) {
  .page-main .hp-title {
      font-size:28px;
      line-height: 1.43;
      margin-top: 0
  }
}

.page-main .hp-title:after {
  margin-left: auto;
  margin-right: auto
}

.a-wysiwyg p {
  font-size: 18px;
  line-height: 2;
  // text-align: center
}

@media only screen and (max-width: 1023px) {
  .a-wysiwyg p {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-wysiwyg p {
      font-size:14px;
      line-height: 1.71
  }
}

.a-wysiwyg ul:not([class]) li {
  font-size: 18px;
  line-height: 2
}

@media only screen and (max-width: 1023px) {
  .a-wysiwyg ul:not([class]) li {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-wysiwyg ul:not([class]) li {
      font-size:14px;
      line-height: 1.71
  }
}

.a-wysiwyg ul:not([class]) li:before {
  top: 15px
}

@media(max-width: 1023px) {
  .a-wysiwyg ul:not([class]) li:before {
      top:12px
  }
}

@media(max-width: 767px) {
  .a-wysiwyg ul:not([class]) li:before {
      top:8px
  }
}

.home-page h2 {
  color: #222;
  font-family: inherit!important;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px
}

.home-page h2:after {
  background-color: #ED2600;
  content: "";
  display: block;
  height: 2px;
  margin: 10px 0 0;
  width: 40px
}

@media(max-width: 1023px) {
  .home-page h2 {
      font-size:26px
  }
}

@media(max-width: 767px) {
  .home-page h2 {
      font-size:24px
  }
}

.home-page h2:before {
  display: none
}


//===========icon box
.home-page .icon-box-editable {
  margin-top: 0;
  padding: 0 0 50px
}

.home-page .icon-box-editable-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 8px;
  width: auto
}

@media only screen and (min-width: 768px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1001px;
      padding: 0 16px;
      width: auto
  }
}

@media only screen and (min-width: 1024px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1224px;
      padding: 0 27px
  }
}

@media only screen and (min-width: 1280px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1280px;
      padding: 0 55px
  }
}

@media only screen and (min-width: 1440px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1638px;
      padding: 0 135px
  }
}

@media only screen and (min-width: 1600px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1600px;
      padding: 0 116px
  }
}

@media only screen and (min-width: 1920px) {
  .home-page .icon-box-editable-wrapper {
      max-width:1368px;
      padding: 0
  }
}

@media only screen and (max-width: 767px) {
  .home-page .icon-box-editable .icon-box-editable__text,.wide-page .icon-box-editable .icon-box-editable__text {
      margin:0 0 0 30px
  }
}

.icon-box-editable {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.icon-box-editable .icon-box-editable-wrapper,.icon-box-editable .icon-box-editable__item-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%
}

.icon-box-editable .icon-box-editable__item {
  align-items: center;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.1),0 -1px 5px 0 rgba(0,0,0,.05);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin: 0 35px 35px 0;
  min-height: 120px;
  padding: 15px 0;
  position: relative;
  text-align: center;
  transition: all .5s ease
}

.icon-box-editable .icon-box-editable__item i {
  color: #000;
  font-size: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(-50deg);
  transition: color .2s,transform .2s
}

.icon-box-editable .icon-box-editable__item i:before {
  vertical-align: text-bottom
}

.icon-box-editable .icon-box-editable__item:before {
  background-color: #ED2600;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out
}

@media only screen and (min-width: 1024px) {
  .icon-box-editable .icon-box-editable__item {
      padding-left:5px;
      padding-right: 5px
  }
}

@media only screen and (max-width: 1600px) {
  .icon-box-editable .icon-box-editable__item {
      margin:0 30px 30px 0
  }
}

@media only screen and (max-width: 1279px) {
  .icon-box-editable .icon-box-editable__item {
      margin:0 23px 23px 0
  }
}

@media only screen and (max-width: 1023px) {
  .icon-box-editable .icon-box-editable__item {
      margin:0 18px 18px 0
  }
}

.icon-box-editable .icon-box-editable__item.hasLink:hover p {
  color: #ED2600
}

.icon-box-editable .icon-box-editable__item.hasLink:hover:before {
  right: 0
}

.icon-box-editable .icon-box-editable__item.hasLink:hover i {
  transform: none
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__item {
      align-items:flex-start;
      justify-content: flex-start;
      min-height: unset;
      padding: 0
  }
}

.icon-box-editable .icon-box-editable__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__wrapper {
      align-items:center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0
  }
}

.icon-box-editable .icon-box-editable__description {
  color: #222;
  display: block;
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 45px;
  width: 100%
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__description {
      font-size:16px
  }
}

.icon-box-editable .icon-box-editable__text {
  word-wrap: break-word;
  color: #222;
  font-size: 18px;
  font-weight: 700;
  line-break: strict;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-word
}

@media only screen and (max-width: 1023px) {
  .icon-box-editable .icon-box-editable__text {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__text {
      font-size:18px;
      margin: 0 10px;
      text-align: left!important
  }
}

.icon-box-editable .icon-box-editable__image {
  margin-bottom: 0
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__image {
      align-content:center;
      align-items: stretch;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 0;
      padding: 10px 0 10px 10px
  }
}

.icon-box-editable .icon-box-editable__image img {
  height: auto;
  height: 90px;
  width: 100%
}

@media only screen and (max-width: 767px) {
  .icon-box-editable .icon-box-editable__image img {
      height:70px;
      object-fit: contain;
      width: 70px
  }
}

.icon-box-editable.icons-3 .icon-box-editable__item {
  flex-basis: calc(33.33333% - 23.33333px)
}

@media only screen and (max-width: 1600px) {
  .icon-box-editable.icons-3 .icon-box-editable__item {
      flex-basis:calc(33.33333% - 20px)
  }
}

@media only screen and (max-width: 1279px) {
  .icon-box-editable.icons-3 .icon-box-editable__item {
      flex-basis:calc(33.33333% - 15.33333px)
  }
}

@media only screen and (max-width: 1023px) {
  .icon-box-editable.icons-3 .icon-box-editable__item {
      flex-basis:calc(33.33333% - 12px)
  }
}

.icon-box-editable.icons-3 .icon-box-editable__item:nth-child(3n) {
  margin-right: 0
}

@media only screen and (max-width: 767px) {
  .icon-box-editable.icons-3 .icon-box-editable__item {
      flex-basis:100%;
      margin: 0 0 10px
  }
}

.icon-box-editable.icons-4 .icon-box-editable__item {
  flex-basis: calc(25% - 26.25px)
}

@media only screen and (max-width: 1600px) {
  .icon-box-editable.icons-4 .icon-box-editable__item {
      flex-basis:calc(25% - 22.5px)
  }
}

@media only screen and (max-width: 1279px) {
  .icon-box-editable.icons-4 .icon-box-editable__item {
      flex-basis:calc(25% - 17.25px)
  }
}

@media only screen and (max-width: 1023px) {
  .icon-box-editable.icons-4 .icon-box-editable__item {
      flex-basis:calc(25% - 13.5px)
  }
}

.icon-box-editable.icons-4 .icon-box-editable__item:nth-child(4n) {
  margin-right: 0
}

@media only screen and (max-width: 767px) {
  .icon-box-editable.icons-4 .icon-box-editable__item {
      flex-basis:100%;
      margin: 0 0 10px
  }
}

.icon-box-editable.icons-5 .icon-box-editable__item {
  flex-basis: calc(20% - 28px)
}

@media only screen and (max-width: 1600px) {
  .icon-box-editable.icons-5 .icon-box-editable__item {
      flex-basis:calc(20% - 24px)
  }
}

@media only screen and (max-width: 1279px) {
  .icon-box-editable.icons-5 .icon-box-editable__item {
      flex-basis:calc(20% - 18.4px)
  }
}

@media only screen and (max-width: 1023px) {
  .icon-box-editable.icons-5 .icon-box-editable__item {
      flex-basis:calc(20% - 14.4px)
  }
}

.icon-box-editable.icons-5 .icon-box-editable__item:nth-child(5n) {
  margin-right: 0
}

@media only screen and (max-width: 767px) {
  .icon-box-editable.icons-5 .icon-box-editable__item {
      flex-basis:100%;
      margin: 0 0 10px
  }
}


//===========image text
.a-section__description {
  font-size: 18px;
  line-height: 2
}

@media only screen and (max-width: 1023px) {
  .a-section__description {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-section__description {
      font-size:14px;
      line-height: 1.71
  }
}

.page-main .a-image-text {
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
  padding: 130px 0 50px
}

@media(min-width: 768px) {
  .page-main .a-image-text.waypoint .left .step-in-left {
      transform:translateX(-100px) translateY(-20px)
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text.waypoint .left .step-in-left {
      transform:translateX(-100px) translateY(-30px)
  }
}

@media(min-width: 768px) {
  .page-main .a-image-text.waypoint .right .step-in-right {
      transform:translateX(100px) translateY(-20px)
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text.waypoint .right .step-in-right {
      transform:translateX(100px) translateY(-30px)
  }
}

.page-main .a-image-text .l-container {
  display: flex
}

@media(min-width: 768px) {
  .page-main .a-image-text__link {
      margin-top:18px;
      margin-top: 24px
  }
}

.page-main .a-image-text__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.page-main .a-image-text__link a,.page-main .a-image-text__link a:active,.page-main .a-image-text__link a:visited {
  color: #005eb8
}

.page-main .a-image-text__link a i {
  font-size: 12px;
  margin-left: 10px
}

.page-main .a-image-text__link.left {
  text-align: right
}

@media(max-width: 767px) {
  .page-main .a-image-text__link.left {
      text-align:center
  }
}

.page-main .a-image-text .right .a-image-text__image {
  order: 1
}

.page-main .a-image-text .left .a-image-text__image {
  order: 0
}

@media(min-width: 768px) {
  .page-main .a-image-text.waypoint-reveal .left .a-image-text__image.step-in-left {
      transform:translateX(-20px) translateY(-20px)
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text.waypoint-reveal .left .a-image-text__image.step-in-left {
      transform:translateX(-30px) translateY(-30px)
  }
}

@media(min-width: 768px) {
  .page-main .a-image-text.waypoint-reveal .right .a-image-text__image.step-in-right {
      transform:translateX(20px) translateY(-20px)
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text.waypoint-reveal .right .a-image-text__image.step-in-right {
      transform:translateX(35px) translateY(-30px)
  }
}

@media(min-width: 1280px) {
  .page-main .a-image-text.waypoint-reveal .right .a-image-text__image.step-in-right {
      transform:translateX(45px) translateY(-30px)
  }
}

.page-main .a-image-text__image {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
  height: auto;
  line-height: 0;
  padding: 18px 20px;
  position: relative;
  text-align: center;
  z-index: 10
}

@media(min-width: 768px) {
  .page-main .a-image-text__image {
      padding:20px;
      width: 44.884%
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text__image {
      padding:30px;
      width: 44.256%
  }
}

@media(min-width: 1280px) {
  .page-main .a-image-text__image {
      width:43.86%
  }
}

@media(min-width: 1600px) {
  .page-main .a-image-text__image {
      padding:35px;
      width: 43.573%
  }
}

.page-main .a-image-text__image img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%
}

.page-main .a-image-text__image.left {
  order: 0
}

.page-main .a-image-text__image.right {
  order: 1
}

.page-main .a-image-text__content {
  align-self: stretch;
  margin-left: 4px;
  margin-top: 22px
}

@media only screen and (min-width: 768px) and (max-width:1440px) {
  .page-main .a-image-text__content p {
      font-size:18px
  }
}

.page-main .a-image-text__content ul {
  column-count: 2;
  margin-top: 20px;
  padding-left: 0
}

@media(max-width: 767px) {
  .page-main .a-image-text__content ul {
      column-count:1;
      margin-bottom: 20px
  }

  .page-main .a-image-text__content ul li:last-child {
      margin-bottom: 0
  }
}

.page-main .a-image-text__content ul li {
  list-style: none;
  margin-bottom: 20px;
  margin-left: 0;
  position: relative
}

.page-main .a-image-text__content ul li:before {
  background-color: #005eb8;
  content: "";
  display: inline-block;
  height: 8px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 15px;
  vertical-align: middle;
  width: 8px
}

@media only screen and (min-width: 768px) and (max-width:1440px) {
  .page-main .a-image-text__content ul li {
      font-size:18px
  }

  .page-main .a-image-text__content ul li:before {
      top: 15px
  }
}

@media only screen and (max-width: 1024px) {
  .page-main .a-image-text__content ul li:before {
      top:11px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__content ul li:before {
      top:9px
  }
}

.page-main .a-image-text__content a:not(.btn-link-arrow) {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: #005eb8;
  text-underline-offset: 2px
}

.page-main .a-image-text__content a:not(.btn-link-arrow):hover {
  color: #005eb8;
  filter: grayscale(0)
}

@media(min-width: 768px) {
  .page-main .a-image-text__content {
      margin:0;
      width: 55.2%
  }

  .page-main .a-image-text__content.step-in-right {
      padding: 26px 35px 40px 12px
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text__content.step-in-right {
      padding:53px 29px 40px 18px
  }
}

@media(min-width: 1280px) {
  .page-main .a-image-text__content.step-in-right {
      padding:55px 99px 40px 66px
  }
}

@media(min-width: 1600px) {
  .page-main .a-image-text__content.step-in-right {
      padding:50px 101px 40px 87px
  }
}

@media(min-width: 768px) {
  .page-main .a-image-text__content.step-in-left {
      padding:50px 0 40px 40px
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text__content.step-in-left {
      padding:53px 3px 40px 40px
  }
}

@media(min-width: 1280px) {
  .page-main .a-image-text__content.step-in-left {
      padding:55px 51px 40px 40px
  }
}

@media(min-width: 1600px) {
  .page-main .a-image-text__content.step-in-left {
      padding:50px 72px 40px 40px
  }
}

.page-main .a-image-text__content .h3 {
  margin: 0 0 23px
}

.page-main .a-image-text__content .a-section__description {
  word-wrap: break-word;
  line-height: 1.75;
  margin-bottom: auto;
  text-align: left;
  word-break: break-word
}

@media(min-width: 768px) {
  .page-main .a-image-text__content .a-section__description {
      line-height:1.78
  }

  .page-main .a-image-text__wrapper {
      align-items: flex-start;
      display: flex
  }

  .page-main .a-image-text__wrapper.left {
      padding-left: 20px
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text__wrapper.left {
      padding-left:30px
  }
}

@media(min-width: 768px) {
  .page-main .a-image-text__wrapper.right {
      padding-right:20px
  }
}

@media(min-width: 1024px) {
  .page-main .a-image-text__wrapper.right {
      padding-right:30px
  }
}

.page-main .a-image-text__bg {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%
}

@media(min-width: 768px) {
  .page-main .a-image-text__bg {
      background:#fafafa;
      flex-direction: row
  }
}

.page-main .a-image-text .edit-mode .spectrecreation_editable_image {
  height: 100%!important;
  width: 100%!important
}

.page-main .a-image-text__description {
  flex-grow: 1
}

.page-main .a-image-text__description p {
  color: #222;
  font-size: 18px;
  line-height: 1.78
}

@media only screen and (max-width: 1023px) {
  .page-main .a-image-text__description p {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__description p {
      font-size:14px;
      line-height: 1.71
  }
}

.page-main .a-image-text__description ul {
  margin-bottom: 0
}

.page-main .a-image-text__description ul li {
  color: #222;
  font-size: 18px;
  line-height: 1.78
}

@media only screen and (max-width: 1023px) {
  .page-main .a-image-text__description ul li {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__description ul li {
      font-size:14px;
      line-height: 1.71
  }
}

@media(max-width: 767px) {
  .page-main .a-image-text__description {
      margin-bottom:20px
  }
}

@media(min-width: 768px) {
  .page-main .a-image-text .a-section__title.h2 {
      color:#222;
      font-family: inherit!important;
      font-size: 32px;
      font-weight: 700;
      line-height: normal;
      margin: 0 0 20px
  }

  .page-main .a-image-text .a-section__title.h2:after {
      background-color: #ED2600;
      content: "";
      display: block;
      height: 2px;
      margin: 10px 0 0;
      width: 40px
  }

  .page-main .a-image-text .a-section__title.h2:before {
      display: none
  }
}

@media(min-width: 768px) and (max-width:1023px) {
  .page-main .a-image-text .a-section__title.h2 {
      font-size:26px
  }
}

@media(max-width: 767px) {
  .page-main .a-image-text .a-section__title.h2 {
      margin-top:20px
  }

  .a-image-text {
      padding: 80px 0 50px
  }

  .a-image-text__image {
      margin: 0 auto
  }

  .a-image-text__image img {
      height: auto;
      width: unset
  }

  .a-image-text__content {
      background: #fafafa;
      margin: 0 6px;
      padding: 30px 20px
  }

  .a-image-text__link {
      margin-top: 0
  }

  .a-image-text__link a {
      font-size: 14px
  }

  .a-image-text .btn-link-arrow--right {
      padding-right: 23px
  }
}

//=============image video text
@media(max-width: 1199px) {
  .spectrecreation_area_image-video-text .hide-mobile {
      display:none
  }
}

.spectrecreation_area_image-video-text .b-company {
  margin: auto
}

.spectrecreation_area_image-video-text .b-company_admin {
  display: flex
}

.spectrecreation_area_image-video-text .b-company_admin-media {
  flex-basis: 300px
}

.spectrecreation_area_image-video-text .b-company_admin-text {
  flex-basis: 100%
}

.spectrecreation_area_image-video-text .b-company_admin-text .b-company__content.b-company__container-item {
  margin-left: 50px;
  max-width: 100%;
  width: 100%
}

.spectrecreation_area_image-video-text .b-company__content-area h3 {
  margin-top: 0;
  padding-bottom: 10px;
  position: relative
}

.spectrecreation_area_image-video-text .b-company__content-area h3:after {
  background: #ed8b00;
  bottom: 0;
  content: " ";
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 40px
}

.spectrecreation_area_image-video-text .b-company__content-link a {
  text-decoration: none
}

.spectrecreation_area_image-video-text .b-company__container-item:first-child {
  margin-bottom: 25px;
  margin-left: 0;
  margin-right: 0
}

.spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
  margin-bottom: 25px;
  padding: 0
}

.spectrecreation_area_image-video-text .b-company__media {
  line-height: 1;
  overflow: hidden;
  width: auto
}

.spectrecreation_area_image-video-text .b-company__media img,.spectrecreation_area_image-video-text .b-company__media video {
  object-fit: cover
}

.spectrecreation_area_image-video-text .b-company__media img {
  display: block;
  margin: 0 0 0 auto;
  max-width: 100%
}

@media only screen and (min-width: 768px) {
  .spectrecreation_area_image-video-text .b-company__container-item:first-child {
      margin-left:1em;
      margin-right: 1em
  }

  .spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      padding: 0 1em
  }

  .spectrecreation_area_image-video-text .b-company__media {
      height: auto
  }

  .spectrecreation_area_image-video-text .b-company__content {
      text-align: left
  }
}

@media only screen and (min-width: 769px) {
  .spectrecreation_area_image-video-text .b-company__container {
      align-items:flex-start;
      display: flex;
      justify-content: center
  }

  .spectrecreation_area_image-video-text .b-company__container-item:first-child,.spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      width: 50%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:first-child {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding-left: 0;
      padding-right: 1em
  }

  .spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      padding: 0 0 0 1em
  }

  .spectrecreation_area_image-video-text .b-company__media {
      height: auto
  }

  .spectrecreation_area_image-video-text .b-company__content {
      text-align: left
  }
}

@media only screen and (min-width: 1025px) {
  .spectrecreation_area_image-video-text .b-company__container {
      margin:auto;
      max-width: 100%;
      width: 100%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:first-child,.spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      max-width: 50%;
      width: 50%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:first-child {
      padding-left: 0;
      padding-right: 0
  }

  .spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      padding: 0
  }

  .spectrecreation_area_image-video-text .b-company__container-item:not(.item-right):nth-child(2) {
      margin-left: 60px
  }

  .spectrecreation_area_image-video-text .b-company__container-item.item-right:first-child {
      margin-right: 60px
  }

  .spectrecreation_area_image-video-text .b-company__media {
      height: auto
  }

  .spectrecreation_area_image-video-text .b-company__content {
      text-align: left
  }
}

@media only screen and (min-width: 1281px) {
  .spectrecreation_area_image-video-text .b-company__container-item:first-child {
      max-width:40%;
      width: 40%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:first-child.item-right,.spectrecreation_area_image-video-text .b-company__container-item:nth-child(2) {
      max-width: 60%;
      width: 60%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:nth-child(2).item-right {
      max-width: 40%;
      width: 40%
  }
}

@media only screen and (min-width: 1601px) {
  .spectrecreation_area_image-video-text .b-company__container {
      max-width:100%;
      width: 100%
  }

  .spectrecreation_area_image-video-text .b-company__container-item:not(.item-right):nth-child(2) {
      margin-left: 3.6em
  }

  .spectrecreation_area_image-video-text .b-company__container-item.item-right:first-child {
      margin-right: 3.6em
  }

  .spectrecreation_area_image-video-text .b-company__media {
      height: auto
  }
}

.spectrecreation_area_image-video-text .el-headline {
  color: hsla(0,0%,60%,.5)
}


//=========link
.link-editable-wrapper {
  margin: 20px 0
}

.link-editable-wrapper .link-editable-title {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.78;
  margin-bottom: 10px
}

.link-editable-wrapper .link-editable-link {
  color: #005eb8;
  font-size: 14px;
  font-weight: 600
}


//=================recognition
.a-section__description {
  font-size: 18px;
  line-height: 2
}

@media only screen and (max-width: 1023px) {
  .a-section__description {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-section__description {
      font-size:14px;
      line-height: 1.71
  }
}

.tooltip-toggle {
  position: relative
}

.tooltip-toggle:after,.tooltip-toggle:before {
  opacity: 0;
  pointer-events: none;
  text-align: center;
  z-index: 1
}

.tooltip-toggle:after {
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
  color: #222;
  content: attr(data-tooltip);
  min-height: 62px;
  padding: 20px 5px;
  top: 100%;
  transform: translateY(-10px);
  width: 100%
}

.tooltip-toggle:before {
  background-color: #fff;
  box-shadow: 2px 2px 10px -2px rgba(0,0,0,.1);
  content: " ";
  height: 12px;
  position: absolute;
  top: 58px;
  transform: rotate(225deg);
  width: 12px;
  z-index: 2
}

.tooltip-toggle:hover:after,.tooltip-toggle:hover:before {
  opacity: 1
}

.tooltip-toggle:hover[data-tooltip=""]:after,.tooltip-toggle:hover[data-tooltip=""]:before {
  display: none
}

.a-partners {
  padding: 50px 0 14px
}

.a-partners__list-wrapper {
  margin-bottom: 65px;
  margin-top: 43px
}

.a-partners__list-wrapper:not(.enableTolltip) .a-partners__item:after,.a-partners__list-wrapper:not(.enableTolltip) .a-partners__item:before {
  display: none
}

.a-partners__item {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px!important;
  justify-content: center
}

.a-partners__item>.link {
  cursor: pointer
}

.a-partners__item>div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  filter: grayscale(100);
  margin: 10px 20px;
  max-height: 100%;
  transition: filter .3s ease
}

.a-partners__item>div:hover {
  filter: none
}

.a-partners__item.tn-badge_link a img {
  height: 80px;
  margin-top: 20%
}

@media only screen and (max-width: 1440px) {
  .a-partners__item.tn-badge_link a img {
      margin-top:10%
  }
}

@media only screen and (max-width: 1280px) {
  .a-partners__item.tn-badge_link a img {
      height:60px
  }
}

@media only screen and (max-width: 1024px) {
  .a-partners__item.tn-badge_link a img {
      height:50px
  }
}

.a-partners__item #bbblink-partners {
  align-items: baseline;
  color: #005a78;
  display: inline-flex!important;
  height: auto!important;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 108px!important
}

.a-partners__item #bbblink-partners img {
  position: static;
  width: 200%
}

.a-partners__item #bbblink-partners.ruhzbul img {
  margin-right: -108px;
  margin-top: 5%
}

.a-partners__item #bbblink-partners.ruhzbul:hover img {
  margin-left: -108px;
  margin-right: auto
}

@media only screen and (min-width: 1025px) {
  .a-partners__item #bbblink-partners {
      width:144px!important
  }

  .a-partners__item #bbblink-partners.ruhzbul img {
      margin-right: -144px
  }

  .a-partners__item #bbblink-partners.ruhzbul:hover img {
      margin-left: -144px;
      margin-right: auto
  }
}

@media only screen and (min-width: 1279px) {
  .a-partners__item #bbblink-partners {
      height:100px!important;
      width: 159.3px!important
  }

  .a-partners__item #bbblink-partners.ruhzbul img {
      margin-right: -159.3px
  }

  .a-partners__item #bbblink-partners.ruhzbul:hover img {
      margin-left: -159.3px;
      margin-right: auto
  }
}

@media only screen and (min-width: 1281px) {
  .a-partners__item #bbblink-partners {
      width:172.5px!important
  }

  .a-partners__item #bbblink-partners.ruhzbul img {
      margin-right: -173px
  }

  .a-partners__item #bbblink-partners.ruhzbul:hover img {
      margin-left: -173px;
      margin-right: auto
  }
}

@media only screen and (min-width: 1601px) {
  .a-partners__item #bbblink-partners {
      width:200px!important
  }

  .a-partners__item #bbblink-partners.ruhzbul img {
      margin-right: -200px
  }

  .a-partners__item #bbblink-partners.ruhzbul:hover img {
      margin-left: -200px;
      margin-right: auto
  }
}

@media only screen and (min-width: 768px) {
  .a-partners__item>div {
      height:46px;
      width: 80px
  }

  .a-partners__item>div:before {
      top: 47px
  }
}

@media only screen and (min-width: 769px) {
  .a-partners__list-wrapper {
      margin-bottom:0
  }

  .a-partners__item {
      position: relative
  }

  .a-partners__item:after,.a-partners__item:before {
      opacity: 0;
      pointer-events: none;
      text-align: center;
      z-index: 1
  }

  .a-partners__item:after {
      background-color: #fff;
      box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
      color: #222;
      content: attr(data-tooltip);
      min-height: 62px;
      padding: 20px 5px;
      top: 100%;
      transform: translateY(-10px);
      width: 100%
  }

  .a-partners__item:before {
      background-color: #fff;
      box-shadow: 2px 2px 10px -2px rgba(0,0,0,.1);
      content: " ";
      height: 12px;
      position: absolute;
      top: 58px;
      transform: rotate(225deg);
      width: 12px;
      z-index: 2
  }

  .a-partners__item:hover:after,.a-partners__item:hover:before {
      opacity: 1
  }

  .a-partners__item:hover[data-tooltip=""]:after,.a-partners__item:hover[data-tooltip=""]:before {
      display: none
  }
}

@media only screen and (min-width: 1025px) {
  .a-partners__item>div {
      height:54px;
      width: 140px
  }

  .a-partners__item>div:before {
      top: 68px
  }

  .a-partners__item>div:after {
      padding: 20px 10px
  }
}

@media only screen and (min-width: 768px) {
  .a-partners__item:before {
      transform:translateY(-9px) rotate(225deg)
  }

  .a-partners__item.tn-badge_link:before {
      transform: translateY(-13px) rotate(225deg)
  }

  .a-partners__item.bbblink:before {
      transform: translateY(-17px) rotate(225deg)
  }
}

@media only screen and (min-width: 1025px) {
  .a-partners__item:before {
      transform:translateY(-1px) rotate(225deg)
  }

  .a-partners__item.tn-badge_link:before {
      transform: translateY(-3px) rotate(225deg)
  }

  .a-partners__item.bbblink:before {
      transform: translateY(-1px) rotate(225deg)
  }
}

@media only screen and (min-width: 1279px) {
  .a-partners__item.bbblink:before {
      transform:translateY(25px) rotate(225deg)
  }
}

@media only screen and (min-width: 1281px) {
  .a-partners__item:before {
      transform:translateY(10px) rotate(225deg)
  }

  .a-partners__item.tn-badge_link:before {
      transform: translateY(3px) rotate(225deg)
  }

  .a-partners__item.bbblink:before {
      transform: translateY(10px) rotate(225deg)
  }
}

@media only screen and (min-width: 1441px) {
  .a-partners__item.tn-badge_link:before {
      transform:translateY(10px) rotate(225deg)
  }
}

@media only screen and (min-width: 1281px) {
  .a-partners__item:before {
      top:74px
  }

  .a-partners__item>div {
      height: 80px
  }
}

@media only screen and (max-width: 1024px) {
  .a-partners {
      padding:30px 0
  }
}

@media only screen and (max-width: 767px) {
  .a-partners__list-wrapper {
      margin-bottom:39px;
      margin-top: 26px
  }

  .a-partners__item:before {
      top: 44px
  }

  .a-partners__item>div {
      height: 50px;
      width: 80px
  }
}


//============image separator
.sit-wrapper {
  margin: 70px 0;
  padding: 60px 0;
  position: relative
}

@media(max-width: 1279px) {
  .sit-wrapper {
      padding:40px 0
  }
}

@media(max-width: 1023px) {
  .sit-wrapper {
      padding:30px 0
  }
}

@media(max-width: 767px) {
  .sit-wrapper {
      padding:24px 0
  }
}

.sit-background {
  bottom: 0;
  position: absolute;
  top: 0
}

.sit-container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap
}

@media(max-width: 1023px) {
  .sit-container {
      flex-direction:column-reverse
  }
}

.sit-content-wrapper {
  flex: 1 1 50%;
  margin-right: 32px;
  z-index: 1
}

@media(max-width: 1023px) {
  .sit-content-wrapper {
      flex:unset;
      margin-right: 0
  }
}

.sit-image-wrapper {
  flex: 1 1 50%;
  z-index: 1
}

@media(max-width: 1023px) {
  .sit-image-wrapper {
      flex:unset;
      margin-bottom: 30px
  }
}

.sit-title {
  color: #222;
  font-family: inherit!important;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 24px
}

@media(max-width: 767px) {
  .sit-title {
      font-size:22px;
      margin: 0 0 16px
  }
}

.sit-title:after,.sit-title:before {
  display: none!important
}

.sit-description {
  color: #3b3c3d;
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 32px
}

@media(max-width: 767px) {
  .sit-description {
      font-size:16px;
      line-height: 1.75;
      margin-bottom: 24px
  }
}

.sit-description p {
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 10px
}

@media(max-width: 767px) {
  .sit-description p {
      font-size:16px;
      line-height: 1.75
  }
}

.sit-item-link {
  text-align: right
}

.sit-item-link .btn-link-arrow {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.78;
  text-transform: none
}

.sit-item-link .btn-link-arrow:after {
  top: 7px
}

.sit-image-wrapper {
  height: 420px;
  position: relative
}

@media(max-width: 1279px) {
  .sit-image-wrapper {
      height:450px;
      width: 100%
  }
}

@media(max-width: 1023px) {
  .sit-image-wrapper {
      height:250px;
      width: 100%
  }
}

@media(max-width: 767px) {
  .sit-image-wrapper {
      height:200px
  }
}

.sit-image-wrapper .sit-cover-image {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%
}

.sit-image-wrapper .sit-front-image-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%,-50%,0)
}

.sit-image-wrapper .sit-front-image-wrapper .sit-front-image {
  display: block;
  max-height: 330px;
  max-width: 430px;
  object-fit: contain
}

@media(max-width: 1279px) {
  .sit-image-wrapper .sit-front-image-wrapper .sit-front-image {
      max-width:370px
  }
}

@media(max-width: 1023px) {
  .sit-image-wrapper .sit-front-image-wrapper .sit-front-image {
      max-height:200px;
      max-width: 430px
  }
}

@media(max-width: 767px) {
  .sit-image-wrapper .sit-front-image-wrapper .sit-front-image {
      max-height:160px;
      max-width: 270px
  }
}

@media(max-width: 320px) {
  .sit-image-wrapper .sit-front-image-wrapper .sit-front-image {
      max-width:200px
  }
}

.sit-image-wrapper.empty-cover-image {
  align-items: center;
  display: flex;
  height: auto!important;
  justify-content: center
}

.sit-image-wrapper.empty-cover-image .sit-front-image-wrapper {
  position: unset;
  transform: none
}

//==========services
.a-services {
  background-color: #fafafa;
  overflow-x: hidden;
  padding: 0 0 50px 0;
  margin-bottom: 15px;
}

.a-services__tabs {
  margin-top: 40px
}

.a-services__tabs-label {
  padding-left: 50px;
  text-decoration: none
}

.a-services__tabs-container {
  margin: 20px auto 0
}

.a-services__tabs-container>ul {
  list-style: none;
  margin: 16px 0;
  padding: 0
}

.a-services__tabs-container>ul li {
  display: inline-flex
}

.a-services__tabs .bx-viewport,.a-services__tabs .bx-wrapper {
  height: 100%!important
}

.a-services__content,.a-services__content-container {
  height: 100%
}

.a-services__name {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0!important
}

@media only screen and (min-width: 1023px) {
  .a-services__name {
      line-height:1.27
  }
}

@media only screen and (min-width: 1281px) {
  .a-services__name {
      font-size:24px;
      line-height: 1.17
  }
}

@media only screen and (max-width: 768px) {
  .a-services__name {
      line-height:normal
  }
}

.a-services__name:after {
  background-color: #ED2600;
  content: "";
  display: block;
  height: 2px;
  margin: 12px 0 0;
  width: 40px
}

.a-services__description {
  font-size: 16px;
  line-height: 1.78;
  margin-bottom: 19px;
  text-align: left
}

@media only screen and (max-width: 1023px) {
  .a-services__description {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-services__description {
      font-size:14px;
      line-height: 1.71
  }
}

.a-services__bullet {
  flex: 1 100%;
  margin: 0 15px 20px 23px
}

.a-services__bullet:before {
  background-color: #ED2600;
  content: " ";
  display: block;
  height: 8px;
  margin-left: -23px;
  transform: translateY(14px);
  width: 8px
}

@media only screen and (min-width: 1024px) {
  .a-services__bullet {
      font-size:18px;
      font-weight: 600;
      max-width: calc(50% - 38px)
  }
}

@media only screen and (max-width: 1023px) {
  .a-services__bullet {
      font-size:16px;
      font-weight: 400
  }
}

@media only screen and (max-width: 767px) {
  .a-services__bullet {
      font-size:14px;
      font-weight: 600
  }
}

.a-services__bullet:before {
  transform: translateY(17px)
}

.a-services__bullet a {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: #005eb8;
  text-underline-offset: 2px
}

.a-services__bullet a:hover {
  color: #005eb8;
  filter: grayscale(0)
}

.a-services__link {
  margin-top: auto;
  text-align: right
}

.a-services__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-services__link a,.a-services__link a:active,.a-services__link a:visited {
  color: #005eb8
}

.a-services__link a i {
  font-size: 12px;
  margin-left: 10px
}

@media(min-width: 768px) {
  .a-services__tabs-label.active {
      transform:translateX(0);
      transition-delay: unset!important
  }

  .waypoint .a-services__tabs-container.step-in-left {
      transform: translateX(-100px) translateY(-30px)
  }

  .a-services.waypoint-reveal .a-services__tabs-label.active {
      transform: translateX(20px)
  }

  .a-services.waypoint-reveal .a-services__tabs-container {
      transform: translateX(-30px) translateY(-30px)
  }

  .a-services__tabs {
      background: #fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
      margin-left: 30px;
      margin-top: 70px
  }

  .a-services__tabs-container {
      background-color: #000;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.4);
      display: inline-block;
      flex: 0 0 256px;
      margin: 0;
      max-width: 256px;
      padding: 14px 20px 2px 0;
      transform: translateX(-30px) translateY(-30px);
      vertical-align: top
  }

  .a-services__tabs-container>ul {
      list-style: none
  }

  .a-services__tabs-container>ul li {
      display: block;
      margin-left: 0
  }

  .a-services__tabs-label {
      background-color: #000;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 18px;
      font-weight: 600;
      height: 100%;
      padding: 16px 0 16px 34px;
      width: 100%
  }

  .a-services__tabs-label p {
      display: inline
  }

  .a-services__tabs-label:active p,.a-services__tabs-label:focus p,.a-services__tabs-label:hover p {
      border-bottom: 1px solid #fff
  }

  .a-services__tabs-label.first {
      background-color: #0b3155
  }

  .a-services__tabs-label.active {
      background: #fff;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,.3);
      color: #000;
      padding: 16px 40px 16px 16px;
      text-decoration: none;
      transform: translateX(20px);
      width: calc(100% + 20px)
  }

  .a-services__content {
      padding-left: 34px;
      padding-top: 50px
  }

  .a-services__content-block {
      display: inline-block
  }

  .a-services__name {
      font-size: 24px;
      margin-top: 0
  }

  .a-services__link {
      margin-bottom: 25px
  }
}

@media(min-width: 1600px) {
  .a-services__content-block {
      -ms-flex:0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%;
      margin-bottom: 15px;
  }
}

@media(min-width: 1023px) {
  .a-services__tabs-container {
      flex:0 0 303px;
      max-width: 303px
  }

  .a-services__content {
      padding-left: 37px
  }

  .a-services.waypoint-reveal .a-services__tabs-label.active {
      transform: translateX(30px)
  }
}

@media(min-width: 1279px) {
  .a-services.waypoint-reveal .a-services__tabs-label.active {
      transform:translateX(30px)
  }

  .a-services__content {
      padding-left: 70px
  }

  .a-services__tabs-container {
      flex: 0 0 370px;
      max-width: 370px
  }

  .a-services__tabs-label {
      padding: 16px 14px 16px 50px
  }

  .a-services__tabs-label.active {
      padding: 16px 44px 16px 20px;
      transform: translateX(30px)
  }
}

@media(max-width: 1023px) {
  .a-services__tabs-label {
      font-size:16px
  }
}

@media(max-width: 767px) {
  .a-services {
      padding:30px 0 50px
  }

  .a-services .bx-wrapper {
      margin-left: auto;
      margin-right: 40px
  }

  .a-services__tabs-container {
      display: none
  }

  .a-services__tabs .bx-viewport {
      overflow: visible!important
  }

  .a-services__tabs .bx-pager {
      align-items: center;
      margin-left: -8px;
      padding: 20px 0 0;
      text-align: center;
      width: 100vw
  }

  .a-services__tabs .bx-pager-item {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      vertical-align: bottom
  }

  .a-services__tabs .bx-pager-link {
      background: #bfc3c7;
      display: block;
      height: 2px;
      margin: 0 5px;
      outline: 0;
      text-indent: -9999px;
      width: 20px
  }

  .a-services__tabs .bx-pager-link.active {
      background: #f59000
  }

  .a-services__tabs [data-slider-item] {
      background: #fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      width: calc(100vw - 43px)!important
  }

  .a-services__content {
      padding: 20px 26px 31px
  }

  .a-services__link {
      text-align: center
  }
}

@media(min-width: 1023px) and (max-width:1279px) {
  .a-services .a-services__tabs-label:not(.active) {
      padding:16px 0 16px 46px
  }
}

.page-main .a-services .a-services__bullet-a {
  font-size: 18px;
  line-height: normal
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet-a {
      font-size:14px
  }
}

@media(min-width: 768px) and (max-width:1023px) {
  .page-main .a-services .a-services__bullet-a {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__description p,.page-main .a-services .a-services__description ul li {
      font-size:14px
  }
}

@media(min-width: 768px) and (max-width:1023px) {
  .page-main .a-services .a-services__description p,.page-main .a-services .a-services__description ul li {
      font-size:16px
  }
}

.page-main .a-services .a-services__description p:last-child,.page-main .a-services .a-services__description ul li:last-child {
  margin-bottom: 0
}

.page-main .a-services .a-services__bullet {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet {
      font-size:14px
  }
}

@media(min-width: 768px) and (max-width:1023px) {
  .page-main .a-services .a-services__bullet {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet:before {
      transform:translateY(15px)
  }
}

@media(min-width: 768px) and (max-width:1023px) {
  .page-main .a-services .a-services__bullet:before {
      transform:translateY(17px)
  }
}


//=============sliders with banners
body {
  background: 0 0!important
}

body .page-main {
  padding-top: 0!important
}

.sb-header-transparent:not(.header-menu-open),.sb-header-transparent:not(.header-menu-open) .header-global-line {
  background-color: transparent!important;
  box-shadow: none!important
}

.sb-wrapper {
  height: 100vh;
  margin-bottom: 68px;
  position: relative;
  width: 100%
}

@media(max-width: 767px) {
  .sb-wrapper {
      margin-bottom:56px
  }
}

.sb-wrapper h1.sb-heading {
  color: #fff;
  font-family: Sumana,serif!important;
  font-size: 48px;
  font-weight: 400!important;
  line-height: 1.13;
  margin: 0 0 40px;
  text-align: center
}

@media(max-width: 1279px) {
  .sb-wrapper h1.sb-heading {
      font-size:40px;
      line-height: 1.35;
      margin-bottom: 32px
  }
}

.sb-wrapper h1.sb-heading:after {
  display: none
}

.sb-title {
  color: #fff;
  font-size: 32px;
  line-height: normal
}

@media(max-width: 1279px) {
  .sb-title {
      font-size:24px
  }
}

.sb-title p {
  font-size: inherit;
  line-height: inherit;
  margin: 0
}

.sb-title-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 16px;
  position: relative;
  width: 100%;
  z-index: 1
}

@media(max-width: 767px) {
  .sb-title-wrap {
      margin:auto;
      max-width: 320px;
      padding: 0 8px
  }
}

.sb-title-row {
  align-items: center;
  display: flex
}

@media(max-width: 767px) {
  .sb-title-row {
      align-items:flex-start;
      flex-direction: column
  }
}

.sb-list-wrap {
  background: #000;
  height: 100%;
  width: 100%
}

.sb-list-wrap,.sb-slide {
  inset: 0;
  position: absolute
}

.sb-slide {
  opacity: 0;
  transition: opacity .8s ease
}

.sb-slide-image {
  height: 100%;
  width: 100%
}

.sb-slide.sb-active {
  opacity: 1
}

.sb-slide-word {
  bottom: 0;
  display: inline;
  font-size: 40px;
  font-weight: 300;
  left: 0;
  line-height: 40px;
  margin: 0;
  opacity: 0;
  position: absolute
}

@media(max-width: 1023px) {
  .sb-slide-word {
      font-size:36px
  }
}

.sb-slide img,.sb-slide picture {
  height: 100%;
  width: 100%
}

.sb-slide img {
  object-fit: cover
}

.sb-animated-inner {
  align-items: center;
  background-color: hsla(0,0%,100%,.1);
  border: 1px solid #fff;
  border-radius: 5px;
  border-top: 0;
  display: flex;
  flex: none;
  height: 54px;
  margin: 0 17px 0 13px;
  min-width: 190px;
  padding: 7px 14px 4px;
  position: relative
}

@media(max-width: 1023px) {
  .sb-animated-inner {
      height:46px;
      max-width: 100%
  }
}

@media(max-width: 767px) {
  .sb-animated-inner {
      margin:24px 0 16px
  }
}

.sb-animated-inner:after,.sb-animated-inner:before {
  border-top: 1px solid #fff;
  content: "";
  height: 6px;
  position: absolute;
  top: 0;
  width: 10px
}

.sb-animated-inner:before {
  border-left: 1px solid #fff;
  border-radius: 5px 0 0 0;
  left: -1px
}

.sb-animated-inner:after {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #fff;
  right: -1px
}

.sb-animated-label {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  left: 5px;
  line-height: 1.36;
  position: absolute;
  right: 5px;
  top: -9px
}

.sb-animated-label:before {
  background: #fff;
  content: "";
  height: 1px;
  margin-right: 10px;
  width: 10px
}

.sb-animated-label:after {
  background: #fff;
  content: "";
  height: 1px;
  margin-left: 10px;
  width: 100%
}

.sb-animated-word {
  animation: cursor 1s step-end infinite;
  border-right: 1px solid #fff;
  color: #ED2600;
  display: inline-block;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  padding-right: 6px
}

@media(max-width: 1023px) {
  .sb-animated-word {
      font-size:24px
  }
}

.sb-animated-word.filled {
  background: rgba(255,213,70,.5)
}

.sb-animated-word.printed {
  animation: none
}

.sb-header-transparent:not(.header-menu-open) .header-menu-item.has-sub-menu .header-menu-item-label:after,.sb-header-transparent:not(.header-menu-open) .header-menu-item.has-sub-menu .header-menu-item-label:before {
  background-color: #ffd546!important
}

.sb-header-transparent:not(.header-menu-open) .header-search-close-btn,.sb-header-transparent:not(.header-menu-open) .header-search-open-btn,.sb-header-transparent:not(.header-menu-open) .icon-s-phone-bordered {
  color: #fff!important
}

.sb-header-transparent:not(.header-menu-open) .header-burger-btn:after {
  background-color: #fff!important
}

.sb-header-transparent:not(.header-menu-open) .header-menu-item-label {
  color: #fff!important
}

.sb-header-transparent:not(.header-menu-open) .header-menu-item-label:hover {
  background-color: transparent!important
}

.sb-header-transparent:not(.header-menu-open) .header-logo-svg {
  fill: #fff!important
}

.sb-header-transparent:not(.header-menu-open) .header-yellow-button-label {
  border-color: #222!important
}

.sb-header-transparent:not(.header-menu-open) .header-burger-btn {
  border-bottom: 2px solid #fff!important;
  border-top: 2px solid #fff!important
}

.sb-header-transparent:not(.header-menu-open) .header-global-line-contacts-email,.sb-header-transparent:not(.header-menu-open) .header-global-line-contacts-email:hover,.sb-header-transparent:not(.header-menu-open) .header-global-line-contacts-phone,.sb-header-transparent:not(.header-menu-open) .header-global-line-contacts-phone:hover,.sb-header-transparent:not(.header-menu-open) .header-phone-text,.sb-header-transparent:not(.header-menu-open) .header-phone-text:hover {
  color: #fff!important
}

@keyframes cursor {
  0% {
      border-color: #fff
  }

  50% {
      border-color: transparent
  }

  to {
      border-color: #fff
  }
}


//============solution types
.a-card {
  background-color: #fff;
  cursor: pointer;
  position: relative
}

.a-card>*>* {
  z-index: 1
}

.a-card:after {
  border-bottom: 4px solid #fff;
  bottom: 0;
  box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow .3s ease-in-out,border-color .3s ease-in-out
}

.a-card__title {
  color: #222;
  font-size: 22px;
  font-weight: 700
}

@media only screen and (min-width: 1023px) {
  .a-card__title {
      line-height:1.27
  }
}

@media only screen and (min-width: 1281px) {
  .a-card__title {
      font-size:24px;
      line-height: 1.17
  }
}

@media only screen and (max-width: 768px) {
  .a-card__title {
      line-height:normal
  }
}

.a-card__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-card__link,.a-card__link:active,.a-card__link:visited {
  color: #005eb8
}

.a-card__link i {
  font-size: 12px;
  margin-left: 10px
}

@media only screen and (min-width: 769px) {
  .a-card:hover:after {
      border-bottom-color:#005eb8;
      box-shadow: 0 2px 30px 0 rgba(0,0,0,.2)
  }

  .a-card:hover .btn-link-arrow--right:after {
      animation: slide-left 1s ease infinite
  }

  .a-card:hover .btn-link-arrow--hide-label:after {
      animation: slide-left 1s ease-in-out .5s infinite
  }

  .a-card:hover .btn-link-arrow--hide-label .btn__label {
      transform: translateX(0)
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .a-card--blog.no-image {
      padding-left:20px
  }

  .a-card--blog.no-image .a-blog__list-content {
      max-width: 100%
  }
}

.a-card--blog:before {
  background-color: #005eb8;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out
}

.a-card--blog:after {
  border-bottom: none;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1)
}

.a-card--blog:hover:before {
  right: 0
}

.a-card--blog:hover:after {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,.2)
}

@keyframes slide-left {
  0%,to {
      transform: translate(0)
  }

  50% {
      transform: translate(-5px)
  }
}

.dt-solution-card {
  align-items: center;
  border: 1px solid #dadfe3;
  color: #222;
  display: flex;
  flex-basis: calc(33% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  margin: 0 30px 30px 0;
  min-height: 100px;
  padding: 11px 25px;
  position: relative;
  text-align: center;
  text-decoration: none
}

.dt-solution-card:not([href]) {
  cursor: auto
}

.dt-solution-card i {
  bottom: 10px;
  color: #005eb8;
  font-size: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  transform: rotate(50deg);
  transition: color .2s,transform .2s
}

.dt-solution-card i:before {
  vertical-align: text-bottom
}

.dt-solution-card:hover {
  color: #222;
  text-decoration: none
}

.dt-solution-card:hover i {
  transform: none
}

.dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.dt-solution-card:nth-of-type(3n) {
  margin: 0 0 30px
}

@media only screen and (min-width: 1023px) {
  .dt-solution-card {
      flex-basis:calc(25% - 18px);
      padding: 26px 7px
  }

  .dt-solution-card,.dt-solution-card:nth-of-type(3n) {
      margin: 0 23px 30px 0
  }

  .dt-solution-card:nth-of-type(4n) {
      margin: 0 0 30px
  }
}

@media only screen and (max-width: 1023px) {
  .dt-solution-card {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .dt-solution-card {
      flex:1 1 100%;
      font-size: 14px;
      margin: 8px 0 0;
      min-height: 60px
  }

  .dt-solution-card:nth-of-type(3n),.dt-solution-card:nth-of-type(4n) {
      margin: 8px 0 0
  }

  .dt-solution-card:first-of-type {
      margin: 0
  }
}

.home-page .dt-solution-card {
  border: none
}

.home-page .dt-solution-card:hover {
  background-color: #005eb8;
  color: #fff
}

.home-page .dt-solution-card:hover i {
  color: #fff
}

.home-page .dt-solution-card:hover:after {
  box-shadow: none
}

.dt-solution-card:after {
  border-bottom: none;
  box-shadow: none
}

.blue .dt-solution-card {
  border: none
}

.blue .dt-solution-card:hover {
  background-color: #005eb8;
  color: #fff
}

.blue .dt-solution-card:hover i {
  color: #fff
}

.blue .dt-solution-card:hover:after {
  box-shadow: none
}

.white .dt-solution-card {
  border: 1px solid #dadfe3
}

.white .dt-solution-card:hover {
  background-color: #fff;
  color: #222
}

.white .dt-solution-card:hover i {
  color: #005eb8
}

.white .dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.grey .dt-solution-card {
  border: 1px solid #dadfe3
}

.grey .dt-solution-card:hover {
  background-color: #fff;
  color: #222
}

.grey .dt-solution-card:hover i {
  color: #005eb8
}

.grey .dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.a-solution-types.no-padding {
  padding-top: 0!important
}

.home-page .a-solution-types {
  padding: 50px 0 20px;
  margin-bottom: 15px;
}

.a-solution-types .a-section__description {
  color: #222
}

.home-page .a-solution-types {
  background-color: #000
}

.home-page .a-solution-types,.home-page .a-solution-types .a-section__description,.home-page .a-solution-types .h2 {
  color: #fff
}

.a-solution-types.white {
  background-color: #fff
}

.a-solution-types.white,.a-solution-types.white .a-section__description,.a-solution-types.white .h2 {
  color: #222
}

.a-solution-types.blue {
  background-color: #0b3155
}

.a-solution-types.blue,.a-solution-types.blue .a-section__description,.a-solution-types.blue .h2 {
  color: #fff
}

.a-solution-types.grey {
  background-color: #fafafa
}

.a-solution-types.grey,.a-solution-types.grey .a-section__description,.a-solution-types.grey .h2 {
  color: #222
}

.a-solution-types .a-section__description {
  line-height: 1.78;
  padding-top: 24px
}

.a-solution-types .a-section__title+.a-section__description {
  padding-top: 0
}

.a-solution-types__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px
}

.a-solution-types__link {
  align-items: center;
  border: 1px solid #dadfe3;
  color: #222;
  display: flex;
  flex-basis: calc(33% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  margin: 0 30px 30px 0;
  min-height: 100px;
  padding: 11px 25px;
  position: relative;
  text-align: center;
  text-decoration: none
}

.a-solution-types__link:not([href]) {
  cursor: auto
}

.a-solution-types__link i {
  bottom: 10px;
  color: #000;
  font-size: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  transform: rotate(50deg);
  transition: color .2s,transform .2s
}

.a-solution-types__link i:before {
  vertical-align: text-bottom
}

.a-solution-types__link:hover {
  color: #222;
  text-decoration: none
}

.a-solution-types__link:hover i {
  transform: none
}

.a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.a-solution-types__link:nth-of-type(3n) {
  margin: 0 0 30px
}

@media only screen and (min-width: 1023px) {
  .a-solution-types__link {
      flex-basis:calc(25% - 18px);
      margin: 0 23px 30px 0;
      padding: 26px 7px
  }

  .a-solution-types__link:nth-of-type(3n) {
      margin: 0 23px 30px 0
  }

  .a-solution-types__link:nth-of-type(4n) {
      margin: 0 0 30px
  }
}

@media only screen and (max-width: 1023px) {
  .a-solution-types__link {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-solution-types__link {
      flex:1 1 100%;
      font-size: 14px;
      margin: 8px 0 0;
      min-height: 60px
  }

  .a-solution-types__link:nth-of-type(3n),.a-solution-types__link:nth-of-type(4n) {
      margin: 8px 0 0
  }

  .a-solution-types__link:first-of-type {
      margin: 0
  }
}

.home-page .a-solution-types__link {
  border: none
}

.home-page .a-solution-types__link:hover {
  background-color: #ED2600;
  color: #fff
}

.home-page .a-solution-types__link:hover i {
  color: #fff
}

.home-page .a-solution-types__link:hover:after {
  box-shadow: none
}

.a-solution-types__link:after {
  border-bottom: none;
  box-shadow: none
}

.blue .a-solution-types__link {
  border: none
}

.blue .a-solution-types__link:hover {
  background-color: #005eb8;
  color: #fff
}

.blue .a-solution-types__link:hover i {
  color: #fff
}

.blue .a-solution-types__link:hover:after {
  box-shadow: none
}

.white .a-solution-types__link {
  border: 1px solid #dadfe3
}

.white .a-solution-types__link:hover {
  background-color: #fff;
  color: #222
}

.white .a-solution-types__link:hover i {
  color: #005eb8
}

.white .a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.grey .a-solution-types__link {
  border: 1px solid #dadfe3
}

.grey .a-solution-types__link:hover {
  background-color: #fff;
  color: #222
}

.grey .a-solution-types__link:hover i {
  color: #005eb8
}

.grey .a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  z-index: 0
}

.a-solution-types.waypoint.waypoint-reveal .step-up:hover {
  transition: background .6s,color .6s
}

.page-main .a-solution-types .a-solution-types__list a {
  line-height: normal
}

@media only screen and (max-width: 767px) {
  .a-solution-types {
      padding:30px 0 39px
  }

  .a-solution-types__list {
      margin-top: 30px
  }
}

//==============tabs with icon
.page-main p.a-tabs-icons__tabs-title {
  margin-bottom: 0
}

.page-main .a-tabs-icons__bullet:before {
  transform: translateY(21px)
}

@media(max-width: 767px) {
  .page-main .a-tabs-icons__bullet:before {
      transform:translateY(19px)
  }
}

.a-tabs-icons {
  padding: 50px 0
}

.a-tabs-icons div[aria-hidden=true][data-slider-item],.a-tabs-icons li[aria-hidden=true][data-slider-item] {
  visibility: hidden
}

.a-tabs-icons .a-section__description {
  line-height: 1.67;
  text-align: left
}

.a-tabs-icons__tabs {
  margin: 40px 0 50px
}

.a-tabs-icons__tabs-label {
  text-align: center
}

.a-tabs-icons__tabs-image {
  height: 70px;
  width: 70px
}

.a-tabs-icons__tabs-title {
  color: #222;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize
}

@media only screen and (min-width: 1024px) {
  .a-tabs-icons__tabs-title {
      font-size:18px
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__tabs-title {
      font-size:18px
  }
}

.a-tabs-icons__tabs-container {
  margin: 40px auto 0
}

.a-tabs-icons__tabs-container>ul {
  list-style: none;
  margin: 16px 0;
  padding: 0
}

.a-tabs-icons__tabs-container>ul li {
  display: inline-flex
}

.a-tabs-icons__svg {
  pointer-events: none
}

.a-tabs-icons__wrapper {
  align-items: center;
  background-size: cover;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0 auto;
  width: 70px
}

.no-webp .a-tabs-icons__wrapper {
  background-image: url('../../../images/app/tabs_icon-sprites.png')!important
}

.a-tabs-icons__wrapper.custom-icon {
  background-position: 50%!important;
  background-size: cover
}

.a-tabs-icons__wrapper--2 {
  background-position: 0 -140px
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--2,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--2,.a-tabs-icons__wrapper--2-mob {
  background-position: 0 -210px
}

.a-tabs-icons__wrapper--3 {
  background-position: 0 -280px
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--3,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--3,.a-tabs-icons__wrapper--3-mob {
  background-position: 0 -350px
}

.a-tabs-icons__wrapper--1 {
  background-position: 0 0
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--1,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--1,.a-tabs-icons__wrapper--1-mob {
  background-position: 0 -70px
}

.a-tabs-icons__wrapper--4 {
  background-position: 0 -420px
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--4,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--4,.a-tabs-icons__wrapper--4-mob {
  background-position: 0 -490px
}

.a-tabs-icons__wrapper--5 {
  background-position: 0 -560px
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--5,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--5,.a-tabs-icons__wrapper--5-mob {
  background-position: 0 -630px
}

.a-tabs-icons__wrapper--6 {
  background-position: 0 -700px
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--6,.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--6,.a-tabs-icons__wrapper--6-mob {
  background-position: 0 -770px
}

.a-tabs-icons__wrapper img {
  height: auto;
  max-width: 100%
}

.a-tabs-icons__content[aria-hidden=false] {
  display: flex;
  flex-direction: column
}

.a-tabs-icons__content-container {
  display: flex
}

.a-tabs-icons__description {
  font-size: 18px;
  line-height: 1.78
}

@media only screen and (max-width: 1023px) {
  .a-tabs-icons__description {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__description {
      font-size:14px;
      line-height: 1.71
  }
}

.a-tabs-icons__bullets {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between
}

.a-tabs-icons__bullets-title {
  color: #222;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.78;
  margin: 2.33em 0 18px;
  text-transform: uppercase
}

.a-tabs-icons__bullets-title:after {
  background-color: #ed8b00;
  content: "";
  display: block;
  height: 2px;
  margin: 6px 0 0;
  width: 40px
}

.a-tabs-icons__bullet {
  flex: 1 100%;
  margin: 0 15px 20px 23px
}

.a-tabs-icons__bullet:before {
  background-color: #005eb8;
  content: " ";
  display: block;
  height: 8px;
  margin-left: -23px;
  transform: translateY(14px);
  width: 8px
}

@media only screen and (min-width: 1024px) {
  .a-tabs-icons__bullet {
      font-size:18px;
      font-weight: 600;
      max-width: calc(50% - 38px)
  }
}

@media only screen and (max-width: 1023px) {
  .a-tabs-icons__bullet {
      font-size:16px;
      font-weight: 400
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__bullet {
      font-size:14px;
      font-weight: 600
  }
}

.a-tabs-icons__bullet:before {
  transform: translateY(17px)
}

@media(max-width: 767px) {
  .a-tabs-icons__bullet:before {
      transform:translateY(15px)
  }
}

.a-tabs-icons__bullet a {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: #005eb8;
  text-underline-offset: 2px
}

.a-tabs-icons__bullet a:hover {
  color: #005eb8;
  filter: grayscale(0)
}

.a-tabs-icons__link {
  margin-top: auto;
  text-align: right
}

.a-tabs-icons__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-tabs-icons__link a,.a-tabs-icons__link a:active,.a-tabs-icons__link a:visited {
  color: #005eb8
}

.a-tabs-icons__link a i {
  font-size: 12px;
  margin-left: 10px
}

@media(min-width: 768px) {
  .a-tabs-icons__tabs {
      background:#fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1)
  }

  .a-tabs-icons__tabs-container {
      z-index: 100
  }

  .a-tabs-icons__tabs-container>ul {
      display: flex;
      flex: 1 1 100%;
      justify-content: flex-start;
      list-style: none
  }

  .a-tabs-icons__tabs-container>ul li {
      flex: 0 123px;
      margin-left: 0
  }

  .a-tabs-icons__tabs-title {
      min-height: 42px
  }

  .a-tabs-icons__tabs-label {
      border-bottom: 4px solid transparent;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      margin: 0 auto;
      padding: 20px 0;
      text-decoration: none;
      width: 100px
  }

  .a-tabs-icons__tabs-label:hover .a-tabs-icons__tabs-title {
      color: #005eb8
  }

  .a-tabs-icons__tabs-label.active {
      border-bottom: 4px solid #005eb8;
      position: relative
  }

  .a-tabs-icons__tabs-label.active .a-tabs-icons__tabs-title {
      color: #005eb8
  }

  .a-tabs-icons__tabs-label.active:after {
      border-bottom: 22px solid transparent;
      border-left: 22px solid #005eb8;
      border-right-color: transparent;
      border-top-color: transparent;
      bottom: -11px;
      content: "";
      display: block;
      position: absolute;
      right: 41%;
      transform: rotate(-135deg)
  }

  .a-tabs-icons__content {
      padding: 33px 0 30px
  }

  .a-tabs-icons__content-container {
      height: 100%
  }

  .a-tabs-icons .bx-controls {
      display: none
  }
}

@media(min-width: 769px) {
  .a-tabs-icons__tabs-label {
      width:120px
  }

  .a-tabs-icons__tabs-container>ul li {
      flex: 0 160px;
      margin-right: 2px
  }
}

@media(min-width: 1025px) {
  .a-tabs-icons__tabs-container {
      -ms-flex:0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%
  }

  .a-tabs-icons__tabs-container>ul li {
      flex: 0 170px;
      margin-right: 30px
  }
}

@media(min-width: 1441px) {
  .a-tabs-icons__tabs-container {
      -ms-flex:0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%
  }

  .a-tabs-icons__tabs-container>ul li {
      margin-right: 23px
  }
}

@media(max-width: 1024px) {
  .a-tabs-icons__tabs-label {
      padding:26px 20px
  }

  .a-tabs-icons__content {
      padding: 40px 0 30px
  }
}

@media(max-width: 768px) {
  .a-tabs-icons__tabs-label {
      padding:16px 0 14px
  }

  .a-tabs-icons .bx-wrapper {
      margin-left: auto;
      margin-right: 40px
  }

  .a-tabs-icons__content {
      padding: 40px 0 30px
  }
}

@media(max-width: 767px) {
  .a-tabs-icons {
      padding-bottom:20px
  }

  .a-tabs-icons__icon {
      flex: 0 0 70px!important;
      margin-right: 16px;
      max-width: 70px!important
  }

  .a-tabs-icons__icon+.col-8 {
      flex: 0 0 calc(100% - 86px);
      max-width: calc(100% - 86px)
  }

  .a-tabs-icons__tabs {
      margin: 40px -8px
  }

  .a-tabs-icons__tabs .bx-wrapper {
      max-width: 100%!important
  }

  .a-tabs-icons__tabs-container {
      display: none
  }

  .a-tabs-icons__tabs-title {
      display: inline-block;
      height: 100%;
      vertical-align: middle
  }

  .a-tabs-icons__tabs-description {
      margin-top: 16px
  }

  .a-tabs-icons__tabs .bx-viewport {
      overflow: visible!important
  }

  .a-tabs-icons__tabs .bx-pager {
      align-items: center;
      margin-left: -8px;
      padding: 20px 0 0;
      text-align: center;
      width: 100vw
  }

  .a-tabs-icons__tabs .bx-pager-item {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      vertical-align: bottom
  }

  .a-tabs-icons__tabs .bx-pager-link {
      background: #bfc3c7;
      display: block;
      height: 2px;
      margin: 0 5px;
      outline: 0;
      text-indent: -9999px;
      width: 20px
  }

  .a-tabs-icons__tabs .bx-pager-link.active {
      background: #f59000
  }

  .a-tabs-icons__tabs [data-slider-item] {
      background: #fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      width: calc(100vw - 43px)!important
  }

  .a-tabs-icons__bullets-title {
      margin: 30px 0
  }

  .a-tabs-icons__content {
      margin-left: 8px;
      padding: 20px 26px 31px
  }

  .a-tabs-icons__content-container {
      height: 100%
  }

  .a-tabs-icons__link {
      text-align: center
  }
}


//=======technologies
.t-wrapper {
  margin-bottom: 50px;
  margin-top: 50px
}

.t-description {
  margin-bottom: 24px
}

.t-items-wrapper {
  background-repeat: no-repeat;
  background-size: contain;
  padding: 44px 32px;
  position: relative
}

@media(max-width: 1599px) {
  .t-items-wrapper {
      padding:32px
  }
}

@media(max-width: 1023px) {
  .t-items-wrapper {
      padding:24px
  }
}

@media(max-width: 767px) {
  .t-items-wrapper {
      background-size:768px
  }
}

.t-items-shadow {
  background-color: rgba(10,132,242,.1);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .23s ease-in-out,visibility 0s .23s;
  visibility: hidden;
  z-index: 1
}

.t-items-shadow.active,.t-items-shadow.hovered {
  opacity: 1;
  transition: opacity .23s ease-in-out,visibility 0s 0s;
  visibility: visible
}

.t-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px -32px 0
}

.t-item {
  margin: 0 40px 32px 0
}

.t-item:last-child .t-popup {
  box-shadow: 0 2px 8px 3px rgba(0,0,0,.1)
}

h3.t-item-title {
  color: #004775;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.67;
  margin: 0 0 4px
}

.t-item-groups {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px -8px 0
}

.t-item-group {
  margin: 0 24px 8px 0
}

.t-item-group-title {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  margin: 0 0 4px;
  text-transform: uppercase
}

.t-item-group-technologies {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px -1px 0
}

.t-item-group-technologies.t-show-2 {
  max-width: 234px
}

.t-item-group-technologies.t-show-3 {
  max-width: 331px
}

.t-item-group-technologies.t-show-4 {
  max-width: 428px
}

.t-item-group-technologies.t-show-5 {
  max-width: 525px
}

.t-item-group-technologies.t-show-6 {
  max-width: 622px
}

.t-item-group-technologies.t-show-7 {
  max-width: 719px
}

.t-item-group-technologies.t-show-8 {
  max-width: 816px
}

.t-item-group-technologies.t-show-9 {
  max-width: 913px
}

.t-item-group-technologies.t-show-10 {
  max-width: 1010px
}

.t-item-group-technologies.t-show-11 {
  max-width: 1107px
}

.t-item-group-technologies.t-show-12 {
  max-width: 1204px
}

.t-item-group-technology {
  height: 64px;
  margin: 0 1px 1px 0;
  position: relative;
  width: 96px
}

.t-limited-items .t-item-group-technology.t-extra-item {
  display: none
}

.t-item-group-technology-content {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 8px 12px;
  position: relative;
  width: 100%
}

.t-item-group-technology-content:after {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.t-item-group-technology-content[href="#"]:not(.has-popup) {
  cursor: default
}

.t-item-group-technology-content.active,.t-item-group-technology-content.hovered {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.t-item-group-technology-content>img {
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%
}

.t-item-group-show-items {
  background-color: #8fd2ff;
  border: none;
  cursor: pointer;
  display: block;
  height: 64px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 40px
}

.t-item-group-show-items:after {
  color: #040404;
  content: "\e90d";
  display: block;
  font-family: s-icons;
  font-size: 14px;
  left: 15px;
  line-height: 1;
  position: absolute;
  top: 26px;
  transform: rotate(-90deg)
}

.t-limited-items .t-item-group-show-items:after {
  transform: rotate(90deg)
}

.t-popup {
  background-color: #fff;
  left: 0;
  opacity: 0;
  padding: 24px;
  position: absolute;
  top: calc(100% + 1px);
  transition: opacity .16s ease-in-out,visibility 0s .16s;
  visibility: hidden;
  width: 550px;
  z-index: 3
}

.t-popup.active {
  opacity: 1;
  transition: opacity .16s ease-in-out,visibility 0s 0s;
  visibility: visible
}

.t-popup-columns {
  align-items: stretch;
  display: flex;
  justify-content: space-between
}

.t-popup-column {
  flex: 1 1 auto
}

.t-popup-column-title {
  color: #000;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 0
}

.t-popup-column-value {
  background-position: 0;
  background-repeat: no-repeat;
  color: #000;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.15;
  margin: 0;
  padding: 0 0 0 44px
}

.t-popup-column-value.t-practice {
  background-image: url('../../../images/app/t-practice.svg')
}

.t-popup-column-value.t-projects {
  background-image: url('../../../images/app/t-projects.svg')
}

.t-popup-column-value.t-workforce {
  background-image: url('../../../images/app/t-workforce.svg')
}

@media(max-width: 630px) {
  .t-popup-column-value {
      background-image:none!important;
      font-size: 20px;
      padding: 4px 0 0
  }
}

.t-popup-column-separator {
  border-left: 1px solid #d7d3d8;
  flex: 1 1 1px;
  margin: 0 4px
}

.t-popup-column-separator:first-child,.t-popup-column-separator:last-child {
  display: none
}

.t-popup-description {
  margin-top: 16px
}

.t-popup-description,.t-popup-description p {
  color: #000;
  font-size: 14px;
  line-height: normal
}

.t-popup-description p {
  margin-bottom: 10px
}

.t-popup-description ul li {
  font-size: 14px;
  line-height: normal
}

.t-popup-link {
  border: 1px solid #005eb8;
  color: #005eb8;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  margin: 16px 0 0 auto;
  padding: 4px 40px 4px 16px;
  position: relative;
  width: fit-content
}

.t-popup-link:after {
  content: "\e926";
  display: block;
  font-family: s-icons;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  right: 17px;
  top: calc(50% - 5px)
}

.t-simple-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px -32px 0;
  max-width: 100%
}

.t-simple-item {
  margin: 0 40px 32px 0;
  max-width: 100%
}

.t-simple-item-title {
  background-color: #f2f2f2;
  height: 40px;
  margin-bottom: 4px;
  width: 150px
}

.t-simple-item-groups {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px -8px 0;
  max-width: 100%
}

.t-simple-item-group {
  margin: 0 24px 8px 0;
  max-width: 100%
}

.t-simple-item-group-title {
  background-color: #f2f2f2;
  height: 24px;
  margin-bottom: 4px;
  width: 135px
}

.t-simple-item-group-technologies {
  background-color: #f2f2f2;
  height: 65px;
  max-width: 100%
}


//=========testimonials
.bx-wrapper .bx-pager {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 0
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
  margin-bottom: 20px
}

.bx-wrapper .bx-pager-link {
  background: #bfc3c7;
  cursor: pointer;
  display: block;
  height: 2px;
  margin: 0 5px;
  width: 20px
}

.bx-wrapper .bx-pager-link.active {
  background: #f59000
}

.bx-clone-h3 {
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: normal
}

.a-card {
  background-color: #fff;
  cursor: pointer;
  position: relative
}

.a-card>*>* {
  z-index: 1
}

.a-card:after {
  border-bottom: 4px solid #fff;
  bottom: 0;
  box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow .3s ease-in-out,border-color .3s ease-in-out
}

.a-card__title {
  color: #222;
  font-size: 22px;
  font-weight: 700
}

@media only screen and (min-width: 1023px) {
  .a-card__title {
      line-height:1.27
  }
}

@media only screen and (min-width: 1281px) {
  .a-card__title {
      font-size:24px;
      line-height: 1.17
  }
}

@media only screen and (max-width: 768px) {
  .a-card__title {
      line-height:normal
  }
}

.a-card__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase
}

.a-card__link,.a-card__link:active,.a-card__link:visited {
  color: #005eb8
}

.a-card__link i {
  font-size: 12px;
  margin-left: 10px
}

@media only screen and (min-width: 769px) {
  .a-card:hover:after {
      border-bottom-color:#fff;
      box-shadow: 0 2px 30px 0 rgba(0,0,0,.2)
  }

  .a-card:hover .btn-link-arrow--right:after {
      animation: slide-left 1s ease infinite
  }

  .a-card:hover .btn-link-arrow--hide-label:after {
      animation: slide-left 1s ease-in-out .5s infinite
  }

  .a-card:hover .btn-link-arrow--hide-label .btn__label {
      transform: translateX(0)
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .a-card--blog.no-image {
      padding-left:20px
  }

  .a-card--blog.no-image .a-blog__list-content {
      max-width: 100%
  }
}

.a-card--blog:before {
  background-color: #005eb8;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out
}

.a-card--blog:after {
  border-bottom: none;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1)
}

.a-card--blog:hover:before {
  right: 0
}

.a-card--blog:hover:after {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,.2)
}

@keyframes slide-left {
  0%,to {
      transform: translate(0)
  }

  50% {
      transform: translate(-5px)
  }
}

.a-testimonials-contact {
  margin: 0;
  overflow: hidden
}

.a-testimonials-contact.no-title .a-testimonials-contact__slider-wrapper {
  margin-top: 40px
}

.a-testimonials-contact .wide-wrapper .a-testimonials-contact__slide {
  opacity: 1
}

.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after,.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
  top: -5px;
  z-index: 2
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after,.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
      width:calc(50% - 431.5px)
  }
}

@media only screen and (min-width: 1280px) {
  .a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after,.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
      width:calc(50% - 515px)
  }
}

@media only screen and (min-width: 1600px) {
  .a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after,.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
      width:calc(50% - 597.5px)
  }
}

.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
  background: linear-gradient(90deg,#fff calc(100% - 70px),#fff 70px,hsla(0,0%,100%,0))
}

@media only screen and (max-width: 1279px) {
  .a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:before {
      background:linear-gradient(90deg,#fff calc(100% - 50px),#fff 50px,hsla(0,0%,100%,0))
  }
}

.a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after {
  background: linear-gradient(270deg,#fff calc(100% - 70px),#fff 70px,hsla(0,0%,100%,0))
}

@media only screen and (max-width: 1279px) {
  .a-testimonials-contact .wide-wrapper.a-testimonials-contact__slider-wrapper:after {
      background:linear-gradient(270deg,#fff calc(100% - 50px),#fff 50px,hsla(0,0%,100%,0))
  }
}

.a-testimonials-contact .wide-wrapper .bx-prev {
  transform: scaleX(-1)
}

.a-testimonials-contact .wide-wrapper .bx-prev i:before {
  display: block;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(90px,-50%)
}

@media only screen and (max-width: 1279px) {
  .a-testimonials-contact .wide-wrapper .bx-prev i:before {
      transform:translate(50px,-50%)
  }
}

.a-testimonials-contact .wide-wrapper .bx-next i:before {
  display: block;
  left: 90px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

@media only screen and (max-width: 1279px) {
  .a-testimonials-contact .wide-wrapper .bx-next i:before {
      left:50px
  }
}

.a-testimonials-contact .wide-wrapper .bx-next,.a-testimonials-contact .wide-wrapper .bx-prev {
  position: relative;
  z-index: 3!important
}

.a-testimonials-contact .wide-wrapper .bx-next i,.a-testimonials-contact .wide-wrapper .bx-prev i {
  color: #bababa;
  font-size: 30px
}

.a-testimonials-contact .wide-wrapper .a-testimonials-contact-text {
  margin-top: 0
}

@media only screen and (min-width: 767px) and (max-width:1023px) {
  .a-testimonials-contact .wide-wrapper .a-testimonials-contact-text {
      flex-grow:1
  }

  .a-testimonials-contact .wide-wrapper .a-testimonials-contact__image {
      text-align: center
  }
}

.a-testimonials-contact .wide-wrapper .a-testimonials-contact__image img {
  margin-top: 0
}

.a-testimonials-contact .a-testimonials-contact-text {
  margin-top: 20px
}

.a-testimonials-contact .a-testimonials-contact-text .a-testimonials-contact__name {
  line-height: 1.63
}

.a-testimonials-contact .testimonial-document-link {
  margin-top: 20px;
  z-index: 10
}

.a-testimonials-contact .a-testimonials-contact__image img {
  margin-top: 5px
}

.a-testimonials-contact .bx-viewport {
  overflow: visible!important;
  z-index: 1
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact .bx-viewport {
      padding-left:8px
  }
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact .bx-wrapper {
      margin-left:auto;
      margin-right: auto
  }
}

.a-testimonials-contact .bx-wrapper .bx-pager {
  padding-top: 25px;
  width: 100%
}

.a-testimonials-contact .bx-wrapper .bx-pager-link[data-slide-index] {
  background-image: none;
  height: 8px;
  margin: 0 4px;
  width: 8px
}

.a-testimonials-contact .bx-wrapper .bx-pager-link[data-slide-index]>span {
  display: inline-block;
  height: 100%;
  width: 100%
}

.a-testimonials-contact .bx-controls-direction {
  display: flex;
  height: calc(100% - 53px);
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.a-testimonials-contact .bx-controls-direction .bx-next,.a-testimonials-contact .bx-controls-direction .bx-prev {
  height: 100%;
  z-index: 1
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact .bx-controls-direction .bx-next,.a-testimonials-contact .bx-controls-direction .bx-prev {
      width:calc(50% - 335px)
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact .bx-controls-direction .bx-next,.a-testimonials-contact .bx-controls-direction .bx-prev {
      width:calc(50% - 431.5px)
  }
}

@media only screen and (min-width: 1280px) {
  .a-testimonials-contact .bx-controls-direction .bx-next,.a-testimonials-contact .bx-controls-direction .bx-prev {
      width:calc(50% - 515px)
  }
}

@media only screen and (min-width: 1600px) {
  .a-testimonials-contact .bx-controls-direction .bx-next,.a-testimonials-contact .bx-controls-direction .bx-prev {
      width:calc(50% - 597.5px)
  }
}

.a-testimonials-contact__slider {
  display: flex;
  height: 100%
}

.a-testimonials-contact__slider-wrapper {
  background-color: #fff;
  position: relative
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__slider-wrapper:before {
      background:linear-gradient(270deg,hsla(0,0%,100%,.4),#fff);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
      z-index: 2
  }

  .a-testimonials-contact__slider-wrapper:after {
      background: linear-gradient(90deg,hsla(0,0%,100%,.4),#fff);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      z-index: 2
  }
}

.a-testimonials-contact__slider-wrapper-configurable {
  padding: 30px;
  position: relative
}

@media only screen and (max-width: 1023px) {
  .a-testimonials-contact__slider-wrapper-configurable {
      padding:0 30px 21px
  }
}

@media only screen and (max-width: 768px) {
  .a-testimonials-contact__slider-wrapper-configurable {
      padding:0 0 21px 8px
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__slider-wrapper-configurable.one-slide:after,.a-testimonials-contact__slider-wrapper-configurable.one-slide:hover:after,.a-testimonials-contact__slider-wrapper-configurable.one-slide:not([onclick]):hover:after {
      box-shadow:none
  }
}

@media only screen and (max-width: 768px) {
  .a-testimonials-contact__slider-wrapper-configurable.one-slide {
      padding:0 30px 21px
  }
}

.a-testimonials-contact__slide {
  color: #333;
  cursor: default;
  display: block;
  font-size: 14px;
  line-height: 2;
  opacity: .6;
  padding: 30px 10px;
  pointer-events: none
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slide {
      opacity:1;
      width: calc(100vw - 43px)!important
  }

  .a-testimonials-contact__slide+.a-testimonials-contact__slide {
      padding-left: 20px
  }
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__slide {
      font-size:16px;
      line-height: 1.88;
      padding: 40px 30px
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__slide {
      padding:40px
  }
}

.a-testimonials-contact__slide:not(.bx-clone)[aria-hidden=false] {
  opacity: 1;
  pointer-events: auto
}

.a-testimonials-contact__slide:after,.a-testimonials-contact__slide:not([onclick]):hover:after {
  border: none;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
  z-index: 0
}

.a-testimonials-contact__slide[onclick] {
  cursor: pointer
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__slide-configurable {
      padding:0
  }

  .a-testimonials-contact__slide-configurable:after,.a-testimonials-contact__slide-configurable:hover:after,.a-testimonials-contact__slide-configurable:not([onclick]):hover:after {
      box-shadow: none
  }
}

@media only screen and (max-width: 1023px) {
  .a-testimonials-contact__slide-configurable {
      padding:30px 52px 20px 55px
  }
}

@media only screen and (max-width: 768px) {
  .a-testimonials-contact__slide-configurable {
      padding:30px 25px 20px
  }
}

.a-testimonials-contact__slide-wrapper {
  flex-direction: column
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__block:first-of-type {
      max-width:324px
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__block:first-of-type {
      max-width:393px
  }
}

@media only screen and (min-width: 1280px) {
  .a-testimonials-contact__block:first-of-type {
      max-width:560px
  }
}

@media only screen and (min-width: 1600px) {
  .a-testimonials-contact__block:first-of-type {
      max-width:725px
  }
}

.a-testimonials-contact__block:last-of-type {
  padding-left: 30px
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__block:last-of-type {
      max-width:calc(100% - 324px)
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__block:last-of-type {
      max-width:calc(100% - 393px)
  }
}

@media only screen and (min-width: 1280px) {
  .a-testimonials-contact__block:last-of-type {
      max-width:calc(100% - 560px)
  }
}

@media only screen and (min-width: 1600px) {
  .a-testimonials-contact__block:last-of-type {
      max-width:calc(100% - 725px)
  }
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__block:last-of-type {
      margin-left:0;
      padding-left: 0;
      width: 100%
  }
}

.a-testimonials-contact__block-logo {
  display: none;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  width: 100%
}

.a-testimonials-contact__block-logo img {
  height: 50px;
  width: auto
}

.a-testimonials-contact__block-quote {
  display: none;
  height: 27px;
  margin-bottom: 10px;
  position: relative;
  width: 100%
}

.a-testimonials-contact__block-quote i {
  color: #dadfe3;
  font-size: 30px;
  height: 27px;
  margin-right: -19px;
  opacity: .3;
  position: absolute;
  transform: scaleY(-1)
}

.a-testimonials-contact__block-quote i:before {
  vertical-align: text-bottom
}

.a-testimonials-contact__block-quote i:last-of-type {
  left: 24px
}

.a-testimonials-contact__contacts-configurable {
  display: flex;
  margin-top: 33px;
  max-width: 100%;
  width: 100%
}

.a-testimonials-contact__quote {
  font-style: italic;
  padding-top: 40px;
  position: relative
}

.a-testimonials-contact__quote-configurable {
  font-size: 15px;
  font-style: italic;
  line-height: 1.87;
  margin-top: 49px;
  position: relative
}

.a-testimonials-contact__quote-configurable:before {
  left: 19px
}

.a-testimonials-contact__quote-configurable:after,.a-testimonials-contact__quote-configurable:before {
  color: #dadfe3;
  content: "\e905";
  display: block;
  font-family: s-icons;
  font-size: 25px;
  font-style: normal;
  line-height: 1;
  position: absolute;
  top: -37px;
  transform: scaleY(-1)
}

.a-testimonials-contact__quote-configurable:after {
  left: 0
}

.a-testimonials-contact__quote:after {
  border-top: 1px solid #dadfe3;
  content: "";
  display: block;
  position: absolute;
  right: calc(50% - 60px);
  top: 20px;
  width: 120px
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__quote {
      padding-right:30px;
      padding-top: 0
  }

  .a-testimonials-contact__quote:after {
      border-right: 1px solid #dadfe3;
      border-top: none;
      height: 120px;
      right: 0;
      top: calc(50% - 60px);
      width: 1px
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__quote {
      padding-right:40px
  }
}

.a-testimonials-contact__name {
  text-align: center
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__name {
      text-align:left
  }
}

.a-testimonials-contact__name-configurable {
  line-height: 1.63
}

.a-testimonials-contact__company-name-configurable {
  display: none
}

.a-testimonials-contact__image {
  height: 125px;
  margin: 0 auto 20px
}

@media only screen and (min-width: 768px) {
  .a-testimonials-contact__image {
      margin:0 0 20px
  }
}

@media only screen and (min-width: 1024px) {
  .a-testimonials-contact__image {
      margin:0 30px 0 0
  }
}

.a-testimonials-contact__image img {
  border-radius: 50%;
  min-width: 125px
}

.a-testimonials-contact__image-configurable {
  height: 54px;
  margin: 0 24px 0 0
}

.a-testimonials-contact__image-configurable img {
  border-radius: 50%;
  height: 54px;
  width: 54px
}

@media only screen and (max-width: 1023px) {
  .a-testimonials-contact-configurable {
      padding-top:40px
  }
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact-configurable {
      padding-top:22px
  }
}

.a-testimonials-contact-configurable .bx-wrapper .bx-pager {
  padding: 4px 0 0
}

.a-testimonials-contact-configurable .bx-controls {
  margin-top: 32px
}

@media only screen and (max-width: 1024px) {
  .a-testimonials-contact-configurable .bx-controls {
      margin-top:21px
  }
}

.a-testimonials-contact-configurable .bx-controls-direction {
  position: relative;
  width: 100%
}

.a-testimonials-contact-configurable .bx-controls-direction .bx-next,.a-testimonials-contact-configurable .bx-controls-direction .bx-prev {
  color: #bfc3c7;
  display: inline-flex;
  font-size: 14px;
  position: absolute;
  z-index: 1
}

.a-testimonials-contact-configurable .bx-controls-direction .bx-next {
  left: unset;
  right: 8px
}

.a-testimonials-contact-configurable .bx-controls-direction .bx-prev:before {
  content: "\e90d";
  font-family: s-icons;
  transform: scaleX(-1)
}

.a-testimonials-contact-configurable .bx-controls-direction .bx-next:before {
  content: "\e90d";
  font-family: s-icons
}

.a-testimonials-contact-configurable .bx-controls .bx-pager-item {
  margin-bottom: 4px
}

.a-testimonials-contact-configurable .bx-wrapper .bx-pager-link {
  height: 8px;
  width: 8px
}

@media only screen and (min-width: 1025px) {
  .a-testimonials-contact-configurable .a-card:after {
      box-shadow:none
  }
}

.configural-editable-wrapper .a-testimonials-contact__block-logo {
  display: flex;
  flex-basis: auto!important
}

.configural-editable-wrapper .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide {
  box-shadow: none;
  margin: 0
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .configural-editable-wrapper .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide {
      box-shadow:0 2px 20px 0 rgba(0,0,0,.1);
      margin: 30px
  }
}

.p-simple.wide .a-testimonials-contact .container {
  padding: 0 8px
}

@media only screen and (max-width: 767px) {
  .p-simple.wide .a-testimonials-contact__block:last-of-type {
      padding-left:0
  }

  .p-simple.wide .spectrecreation_area_testimonials-contact-page .a-testimonials-contact__slide {
      margin-right: 16px
  }

  .p-simple.wide .spectrecreation_area_testimonials-contact-page {
      margin: 0 -16px
  }
}

.p-simple.wide .a-testimonials-contact__name {
  margin-bottom: 0
}

@media only screen and (min-width: 768px) {
  .p-simple.wide .a-testimonials-contact__block p {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .p-simple.wide .a-testimonials-contact__block p {
      font-size:14px
  }

  .page-main .a-testimonials-contact .container {
      padding: 0 8px
  }
}

.page-main .a-testimonials-contact p.a-testimonials-contact__quote {
  margin-bottom: 0
}

@media only screen and (max-width: 767px) {
  .page-main .a-testimonials-contact__block:last-of-type {
      padding-left:0
  }
}

.page-main .a-testimonials-contact__name {
  margin-bottom: 0
}

@media only screen and (min-width: 768px) {
  .page-main .a-testimonials-contact__block p {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-testimonials-contact__block p {
      font-size:14px
  }
}

.page-main .a-testimonials-contact-configurable .a-testimonials-contact__block p.a-testimonials-contact__quote-configurable {
  color: #333;
  font-size: 15px;
  font-stretch: normal;
  font-weight: 400;
  line-height: 1.87
}

.page-main .a-testimonials-contact-configurable .a-testimonials-contact__block p.a-testimonials-contact__quote-configurable:last-child {
  margin-bottom: 0
}

.page-main .a-testimonials-contact-configurable .a-testimonials-contact__contacts-configurable {
  margin-top: 38px
}

.page-main .a-testimonials-contact-configurable .a-testimonials-contact__contacts-configurable p.a-testimonials-contact__name-configurable {
  color: #222;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.63;
  margin-bottom: 0
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,.1);
  margin: 50px 0;
  padding: 30px 40px
}

@media only screen and (max-width: 1023px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide {
      padding:30px
  }
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide {
      padding:20px 10px 30px 20px
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide {
  opacity: 1
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide {
      width:100%!important
  }
}

@media only screen and (max-width: 1023px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-configurable {
      box-shadow:none!important;
      padding: 0
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-configurable:after {
  box-shadow: none!important
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__block-logo {
  display: block
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper {
      flex-direction:column
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact__block-logo {
  align-self: center;
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 30px
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact__block-logo {
      flex-basis:100%
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact__block-logo img {
  height: auto;
  max-width: 220px;
  width: auto
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact__block-logo img {
      max-width:200px
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper {
  flex-basis: calc(100% - 250px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: nowrap
}

@media only screen and (max-width: 767px) {
  .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper {
      flex-basis:100%
  }
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__block {
  max-width: 100%;
  width: 100%
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__block:first-of-type {
  max-width: 100%
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__quote-configurable {
  margin-bottom: 0;
  margin-top: 39px
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__contacts-configurable p.a-testimonials-contact__name-configurable {
  margin-bottom: 0
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__image-configurable {
  margin: 0 10px 0 0
}

.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__quote-configurable:after,.a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__quote-configurable:before {
  box-shadow: inset -1px -1px 2px 0 rgba(0,0,0,.1);
  opacity: .3;
  transform: scaleX(-1)
}

.page-main .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__quote-configurable {
  color: #333;
  font-size: 15px;
  font-stretch: normal;
  font-weight: 400;
  line-height: 1.87
}

.page-main .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__quote-configurable:last-child {
  margin-bottom: 0
}

.page-main .a-testimonials-contact__slider-wrapper.a-testimonials-contact__slider-wrapper-configurable.one-slide p.a-testimonials-contact__name-configurable {
  color: #222;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.63;
  margin-bottom: 0
}

.page-main .p-contact .testimonial-document-link {
  display: none
}

.a-home-testimonial {
  box-sizing: border-box;
  padding: 100px 0 52px
}

@media(min-width: 768px) {
  .a-home-testimonial {
      overflow-x:hidden
  }

  .a-home-testimonial .step-in-right {
      align-content: stretch;
      align-items: stretch;
      align-self: stretch;
      background: #fff;
      box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
      height: 100%;
      transform: translateX(30px) translateY(-30px)
  }

  .a-home-testimonial.waypoint-reveal .step-in-right {
      transform: translateX(-15px) translateY(-30px)!important
  }
}

@media(max-width: 767px) {
  .a-home-testimonial {
      padding:79px 0 27px
  }

  .a-home-testimonial .step-in-right {
      transform: translateX(0) translateY(15px)
  }

  .a-home-testimonial.waypoint-reveal .step-in-right {
      transform: translate(0)!important
  }
}

.a-home-testimonial__tabs {
  align-items: flex-start;
  display: flex;
  margin-top: 30px;
  position: relative
}

@media(max-width: 767px) {
  .a-home-testimonial__tabs {
      background:#fafafa
  }

  .a-home-testimonial__tabs .bx-controls {
      position: absolute;
      top: -70px;
      width: 100%
  }

  .a-home-testimonial__tabs .bx-pager {
      display: none!important
  }
}

@media(min-width: 768px) {
  .a-home-testimonial__tabs {
      margin-bottom:62px;
      margin-top: 70px
  }
}

.a-home-testimonial__tabs>.row {
  width: 100vw
}

@media(min-width: 768px) {
  .a-home-testimonial__tabs>.row>:first-child {
      margin-bottom:-30px;
      margin-left: -7vw;
      max-width: 225px;
      min-width: 226px;
      padding: 0;
      z-index: 1
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__tabs>.row>:first-child {
      margin-left:-5vw;
      min-width: 250px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__tabs>.row>:first-child {
      min-width:300px
  }
}

@media(min-width: 1600px) {
  .a-home-testimonial__tabs>.row>:first-child {
      min-width:320px
  }
}

@media(min-width: 768px) {
  .a-home-testimonial__tabs>.row>:last-child {
      background:#fafafa;
      margin-left: 15px;
      padding: 0
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__tabs>.row>:last-child {
      flex:0 0 78%;
      max-width: 78%
  }
}

.a-home-testimonial__tabs-container {
  display: flex;
  flex-direction: column;
  position: relative
}

@media(min-width: 768px) {
  .a-home-testimonial__tabs-container {
      padding-top:60px
  }
}

@media(max-width: 767px) {
  .a-home-testimonial__tabs-container {
      background:#fff;
      box-shadow: 0 2px 37px 0 rgba(0,0,0,.1);
      padding-bottom: 20px;
      padding-top: 20px;
      position: relative
  }

  .a-home-testimonial__tabs-container .bx-viewport {
      margin: 0 auto;
      width: calc(100% - 140px)!important
  }
}

@media(min-width: 768px) {
  .a-home-testimonial__tabs-container.link {
      padding-top:0;
      transform: translateY(60px)
  }
}

.a-home-testimonial__logos {
  background-color: #fff
}

@media(min-width: 768px) {
  .a-home-testimonial__logos {
      padding:0
  }
}

.a-home-testimonial__logo {
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 50px;
  text-transform: uppercase;
  vertical-align: middle
}

@media(max-width: 767px) {
  .a-home-testimonial__logo {
      display:none
  }

  .a-home-testimonial__logo.active {
      display: block
  }
}

@media(min-width: 768px) {
  .a-home-testimonial__logo.all {
      display:none
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__logo {
      height:34px
  }
}

.a-home-testimonial__logo a {
  color: #005eb8;
  display: block;
  font-weight: 600;
  height: 100%;
  width: 100%
}

@media(min-width: 768px) {
  .a-home-testimonial__logo a {
      border-left:4px solid transparent;
      padding: 0 25px 0 30px
  }

  .a-home-testimonial__logo a.active {
      border-left: 4px solid #005eb8
  }

  .a-home-testimonial__logo a.active img,.a-home-testimonial__logo a:hover img {
      filter: grayscale(0);
      opacity: 1
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__logo a {
      padding:0 40px 0 50px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__logo a {
      padding:0 75px 0 38px
  }
}

@media(min-width: 1440px) {
  .a-home-testimonial__logo a {
      padding:0 75px 0 45px
  }
}

@media(min-width: 1600px) {
  .a-home-testimonial__logo a {
      padding:0 85px 0 50px
  }
}

.a-home-testimonial__logo img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto
}

@media(min-width: 767px) {
  .a-home-testimonial__logo img {
      filter:grayscale(100%);
      opacity: .5;
      transition: all .5s ease
  }
}

.a-home-testimonial__logo.all {
  line-height: 50px;
  text-align: center
}

@media(min-width: 768px) {
  .a-home-testimonial__logo {
      display:block;
      margin-bottom: 30px
  }
}

@media(max-width: 767px) {
  .a-home-testimonial__logo.active {
      border-left:none
  }

  .a-home-testimonial__logo img {
      display: block;
      margin: 0 auto
  }
}

.a-home-testimonial__img-wrapper {
  height: 100%;
  width: 100%
}

.a-home-testimonial__all {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
  display: none;
  height: auto
}

@media(min-width: 768px) {
  .a-home-testimonial__all {
      display:block;
      padding: 20px 10px;
      text-align: center
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__all {
      padding:20px 45px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__all {
      padding:20px 70px
  }
}

@media(min-width: 1600px) {
  .a-home-testimonial__all {
      padding-left:80px;
      padding-right: 80px
  }
}

.a-home-testimonial__all a {
  border: none;
  display: inline;
  font-size: 14px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: auto
}

.a-home-testimonial__all a:after {
  margin-left: 15px;
  position: relative;
  top: .5px
}

.a-home-testimonial__card {
  flex-direction: column;
  padding: 42px 27px 50px;
  width: 100%
}

@media(min-width: 768px) {
  .a-home-testimonial__card {
      max-width:89%;
      padding: 30px 100px 30px 63px
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__card {
      padding-bottom:50px;
      padding-left: 45px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__card {
      max-width:96%;
      padding-left: 30px;
      padding-right: 130px
  }
}

@media(min-width: 1440px) {
  .a-home-testimonial__card {
      padding-right:160px
  }
}

@media(min-width: 1600px) {
  .a-home-testimonial__card {
      padding-right:175px
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__card {
      padding-left:38px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__card {
      padding-left:70px
  }
}

.a-home-testimonial__card .icon-quote-wrapper {
  display: flex;
  height: 27px;
  margin-bottom: 20px;
  position: relative
}

@media(max-width: 767px) {
  .a-home-testimonial__card .icon-quote-wrapper {
      height:48px
  }
}

.a-home-testimonial__card .a-home-testimonial__representative-wrapper .a-home-testimonial__name-wrapper {
  flex-direction: row-reverse
}

.a-home-testimonial__card i {
  color: #dadfe3;
  font-size: 27px;
  height: 27px;
  left: 0;
  margin-right: 0;
  position: absolute;
  transform: scaleX(-1)
}

.a-home-testimonial__card i:before {
  vertical-align: text-bottom
}

.a-home-testimonial__card i:last-of-type {
  left: 20px
}

.a-home-testimonial__card blockquote {
  color: #222;
  font-size: 18px;
  font-style: italic;
  line-height: 1.94;
  line-height: 1.75;
  margin: auto 0 0
}

@media only screen and (max-width: 1023px) {
  .a-home-testimonial__card blockquote {
      font-size:16px
  }
}

@media only screen and (max-width: 767px) {
  .a-home-testimonial__card blockquote {
      font-size:14px;
      line-height: 1.75
  }
}

@media(min-width: 768px) {
  .a-home-testimonial__card blockquote {
      line-height:1.8
  }

  .a-home-testimonial__card blockquote.all {
      display: none
  }
}

.a-home-testimonial__card blockquote p.a-testimonials-contact-title {
  color: #222;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 10px
}

.a-home-testimonial__card blockquote p:not([class]) {
  margin-bottom: 0
}

@media(max-width: 767px) {
  .a-home-testimonial__card i {
      font-size:48px;
      height: 48px
  }

  .a-home-testimonial__card i:last-of-type {
      left: 42px
  }
}

.a-home-testimonial__blockquote-wrapper {
  flex: 1
}

.a-home-testimonial__name,.a-home-testimonial__quotes {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33
}

.a-home-testimonial__name {
  color: #222
}

@media(min-width: 768px) {
  .a-home-testimonial__name {
      padding-top:6px
  }
}

.a-home-testimonial__name-wrapper {
  align-content: center;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: flex-end
}

.a-home-testimonial__photo {
  margin-left: 10px;
  padding-top: 6px
}

@media(min-width: 1280px) {
  .a-home-testimonial__photo {
      margin-left:22px
  }
}

.a-home-testimonial .a-home-testimonial__representative-wrapper {
  margin-top: 30px
}

.a-home-testimonial .a-home-testimonial__representative-wrapper .a-home-testimonial__photo {
  margin-left: 0;
  margin-right: 10px
}

.a-home-testimonial .a-testimonials-contact__quote-configurable p.a-testimonials-contact-title {
  color: #222;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 10px
}

.a-home-testimonial .a-testimonials-contact__quote-configurable p:not([class]) {
  margin-bottom: 0
}

.a-home-testimonial .a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__quote-configurable:after,.a-home-testimonial .a-testimonials-contact__slider-wrapper-configurable.one-slide .a-testimonials-contact__slide-wrapper .a-testimonials-contact-right-wrapper .a-testimonials-contact__quote-configurable:before {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  background-color: #e7e7e7;
  box-shadow: inset -1px -1px 2px 0 transparent;
  color: #f4f6f7;
  color: transparent;
  opacity: 1;
  text-shadow: hsla(0,0%,100%,.5) 0 1px 2px
}

.a-home-testimonial__button {
  margin-top: 51px;
  text-align: center
}

@media(min-width: 768px) {
  .a-home-testimonial__button {
      margin-top:80px;
      text-align: left
  }
}

@media(min-width: 1024px) {
  .a-home-testimonial__button {
      margin-top:33px
  }
}

@media(min-width: 1280px) {
  .a-home-testimonial__button {
      margin-top:64px
  }
}

.a-home-testimonial .bx-wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%
}

.a-home-testimonial .bx-next,.a-home-testimonial .bx-prev {
  color: #bababa;
  height: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  transition: none;
  width: 50px
}

.a-home-testimonial .bx-next i,.a-home-testimonial .bx-prev i {
  display: inline-block;
  font-size: 20px;
  line-height: unset;
  margin-top: 15px;
  max-height: 30px;
  vertical-align: middle;
  width: 20px
}

.a-home-testimonial .bx-prev {
  left: 7px
}

.a-home-testimonial .bx-prev i {
  transform: rotate(180deg)
}

.a-home-testimonial .bx-next {
  right: 7px
}

//=============wysiwyg
.a-wysiwyg img,.spectrecreation_wysiwyg img {
  height: auto!important;
  max-width: 100%!important;
  width: 100%
}

.p-simple.wide .a-wysiwyg blockquote p {
  line-height: 1.46
}


// .lowercase-headers {
//   margin-top: 120px;
// }